import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { NotificationService } from "../../services/notification.service";
import { ProjectNotificationService } from "../../services/project.notification.service";

@Component({
  selector: "app-cala-fund-management",
  templateUrl: "./cala-fund-management.component.html",
  styleUrls: ["./cala-fund-management.component.css"],
})
export class CalaFundManagementComponent implements OnInit, OnDestroy {
  fundDetails = [];
  projectsUnderCala = [];
  calaName: string;
  calaId: string;

  totalAmountAssigned: number;
  individualAmountArray = [];
  fundsExhausted: number[] = [];
  remainingFunds = [];

  calaIDSubs: Subscription;
  calaFundDetailsSubs: Subscription;
  calaNameSubs: Subscription;
  calaProjectsSubs: Subscription;

  fundsForm: FormGroup;
  constructor(
    private projectNotificationService: ProjectNotificationService,
    private fb: FormBuilder,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.calaIDSubs =
      this.projectNotificationService.calaDetailForFundsManagement.subscribe(
        (res) => {
          this.calaId = localStorage.getItem("calaID");
          this.calaFundDetailsSubs = this.projectNotificationService
            .getCalaFundDetails(this.calaId)
            .subscribe((res) => {
              this.fundDetails.push(...res);
              this.individualTotal(this.fundDetails);
              this.fundsExhaustedCalculations(res);
              this.totalAmount(this.fundDetails);
            });
          this.calaProjectsSubs = this.projectNotificationService
            .getProjectsUnderCala(this.calaId)
            .subscribe((res) => {
              this.projectsUnderCala.push(...res);
            });
        }
      );
    // this.calaNameSubs = this.projectNotificationService.calaName.subscribe(
    //   (res) => {
    //     this.calaName = res;
    //   }
    // );

    this.calaName = localStorage.getItem("calaName");

    this.fundsForm = this.fb.group({
      project: [null, [Validators.required]],
      amount: [null, [Validators.required]],
      date: [null, [Validators.required]],
    });
  }

  ngAfterViewInit() {
    // this.individualTotal(this.fundDetails);
  }

  totalAmount(arr) {
    const amountRaw = arr.map((val) => {
      return val.details;
    });
    const amountArray = amountRaw.flat().map((val) => {
      return +val.amount;
    });
    this.totalAmountAssigned = amountArray.reduce((acc, curr) => {
      return acc + curr;
    }, 0);
  }

  individualTotal(arr) {
    let amountArr = [[], [], [], [], [], [], [], [], [], []];
    const detailsArray = arr.map((val) => {
      return val.details;
    });
    detailsArray.forEach((el, i) => {
      el.forEach((obj) => {
        amountArr[i].push(+obj.amount);
      });
    });
    // console.log(amountArr);
    amountArr.forEach((el) => {
      const sum = el.reduce((acc, curr) => {
        return acc + curr;
      }, 0);
      this.individualAmountArray.push(+sum);
    });
    // console.log(this.individualAmountArray);
  }

  fundsExhaustedCalculations(arr) {
    let amountArray = [];
    const detailsArray = arr.forEach((el, i) => {
      const villageFunds = el.village_details.map((val) => {
        let details = val.details.map((value) => {
          return value.h_3H_fund_distributed_in_crore;
        });
        return details;
      });
      amountArray.push(villageFunds);
    });
    const flatArray = amountArray.map((el) => {
      return el.flat();
    });
    this.fundsExhausted = flatArray.map((el) => {
      let sum = el.reduce((acc, curr) => {
        return acc + curr;
      }, 0);
      return +sum.toFixed(2);
    });
    ///////// Remaining funds amount logic ///////////
    this.individualAmountArray.forEach((el, i) => {
      this.remainingFunds.push(el - this.fundsExhausted[i]);
    });
  }

  onSubmit() {
    const data = this.fundsForm.value;
    data.cala = this.calaId;
    this.projectNotificationService.assginFundsToCala(data).subscribe(
      (res) => {
        console.log(res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
        this.calaFundDetailsSubs = this.projectNotificationService
          .getCalaFundDetails(this.calaId)
          .subscribe((res) => {
            // console.log(res);
            this.fundDetails = [];
            this.individualAmountArray = [];
            this.fundsExhausted = [];
            this.remainingFunds = [];
            this.fundDetails.push(...res);
            this.individualTotal(this.fundDetails);
            this.fundsExhaustedCalculations(this.fundDetails);
            this.totalAmount(this.fundDetails);
          });
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.calaIDSubs.unsubscribe();
    // this.calaNameSubs.unsubscribe();
    this.calaFundDetailsSubs.unsubscribe();
    this.calaProjectsSubs.unsubscribe();
    localStorage.removeItem("calaName");
    localStorage.removeItem("calaID");
  }
}
