import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DashboardService } from "../../../services/dashboard.service";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

@Component({
  selector: "app-projects-graph",
  templateUrl: "./projects-graph.component.html",
  styleUrls: ["./projects-graph.component.scss"],
})
export class ProjectsGraphComponent implements OnInit {
  projectName: string = "";
  cala_id;

  array3A = [];
  array3D = [];
  array3G = [];
  array3H = [];

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((param) => {
      this.projectName = param.get("name");
      this.cala_id = param.get("id");
    });

    this.dashboardService
      .getLastFourYearsData(this.cala_id)
      .subscribe((res) => {
        const [project] = res.filter(
          (item) => item.project_name === this.projectName
        );
        console.log(project);
        this.array3A = project["3A"].map((item) => {
          const [key, value] = Object.entries(item);
          return key;
        });
        this.array3D = project["3D"].map((item) => {
          const [key, value] = Object.entries(item);
          return key;
        });
        this.array3G = project["3G"].map((item) => {
          const [key, value] = Object.entries(item);
          return key;
        });
        this.array3H = project["3H"].map((item) => {
          const [key, value] = Object.entries(item);
          return key;
        });

        /////// Highchart Graph ////////////
        HC_exporting(Highcharts);
        this.graphOptions("container3A", "3(A)", this.array3A);
        this.graphOptions("container3D", "3(D)", this.array3D);
        this.graphOptions("container3G", "3(G)", this.array3G);
        this.graphOptions("container3H", "3(H)", this.array3H);
      });
  }

  graphOptions(containerName: string, process: string, arr) {
    const options: any = {
      chart: {
        type: "column",
      },
      title: {
        text: `Aquisition Step: ${process}`,
      },
      // subtitle: {
      //   text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>',
      // },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Area (hactares)",
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "Area acquired: <b>{point.y:.1f} Hactares</b>",
      },
      series: [
        {
          name: "Population",
          data: [...arr].reverse(),
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "#FFFFFF",
            align: "right",
            format: "{point.y:.1f}", // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: "13px",
              fontFamily: "Verdana, sans-serif",
            },
          },
        },
      ],
    };
    return Highcharts.chart(containerName, options);
  }
}
