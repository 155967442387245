import {AbstractControl, ValidatorFn} from '@angular/forms';

export function emailValidator(val: Array<any>,): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  {
        for (let index = 0; index < val.length; index++) {
            
            if (control.value==val[index]) {
                return {'usernameAlreadyExist':true}
            }
        }
        
        return null;

    }
}

export function officeNoValidator(val: Array<any>,): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  {
        for (let index = 0; index < val.length; index++) {
            
            if (control.value==val[index]) {
                return {'officeNoAlreadyExist':true}
            }
        }
        
        return null;

    }
}

export function mobileNoValidator(val: Array<any>,): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  {
        for (let index = 0; index < val.length; index++) {
            
            if (control.value==val[index]) {
                return {'mobileNoAlreadyExist':true}
            }
        }
        
        return null;

    }
}

export function telephoneNoValidator(val: Array<any>,): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  {
        for (let index = 0; index < val.length; index++) {
            
            if (control.value==val[index]) {
                return {'telephoneNoAlreadyExist':true}
            }
        }
        
        return null;

    }
}