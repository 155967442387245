import { Component, OnInit, ViewChild } from "@angular/core";
import { NotificationService } from "../../services/notification.service";
import { ProjectService } from "../../services/project.service";
import { SortingService } from "../../services/sorting.service";
import { AdminService } from "../../services/admin.service";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { Subscription } from "rxjs";

@Component({
  selector: "app-edit-project",
  templateUrl: "./edit-project.component.html",
  styleUrls: ["./edit-project.component.css"],
})
export class EditProjectComponent implements OnInit {
  projectList = [];
  statesList = [];
  districtList = [];
  tehsilList = [];
  villageList = [];
  agencyList = [];
  principalList = [];
  engineersList = [];
  collectorsList = [];
  calaList = [];
  tilrList = [];
  comissionersList = [];
  roList = [];

  districtNameID = [];
  pnRequestDate;

  ////////////////// Modified Lists //////////////////////////////
  modifiedTehsilList = [];
  ////////////////// Modified Lists //////////////////////////////

  isStateFormVisible: boolean = true;

  //////////////////////////////////////////////////////
  tilrs = [];
  statesArray: object[] = [];
  tehsilArray: object[] = [];
  districtArray: object[] = [];
  villageArray: object[] = [];
  // projectList: any;
  agencyArray: any;
  calaArray: any;
  principalArray: any;
  regionalOfficersArray: any;
  comissionersArray: any;
  executiveEngineersArray: any;
  collectorsArray: any;
  //////////////////////////////////////////////////////

  ///// Form ID ////////
  projectFormID: string;
  stateFormID: string;
  districtFormID: string;
  tehsilFormID: string;
  villageFormID: string;
  //////////////////////////////////////////////////////////
  stateDataId: any;
  districtDataId: any;
  tehsilDataId: any;
  villageDataId: any;
  stateDataDropdownList = [];
  districtDataDropdownList = [];
  tehsilDataDropdownList = [];
  villageDataDropdownList = [];

  districtCorrespondingToState = [];
  tehsilCorrespondingToDistrict = [];
  villageCorrespondingToTehsil = [];

  //////////////////////// For Add forms ///////////////////////
  stateIdArray: string[] = [];
  stateNameIdArray = [];
  stateNameArray: string[] = [];
  districtDataIdArray = [];
  districtNameIdArray = [];
  districtNameArray: string[] = [];
  tehsilDataIdArray = [];
  tehsilNameIdArray = [];
  tehsilNameArray: string[] = [];
  /////////////////////////////////////////////////////////////////

  projectData: any;
  projectId: any;

  ////////////// StateData ID Array //////////////////////
  stateDataIDArray: number[] = [];
  districtDataIDArray: number[] = [];
  tehsilDataIDArray: number[] = [];
  villageDataIDArray: number[] = [];

  /////////////////// Names //////////////////////////
  projectName: string = "Update Project";
  stateName: string;
  districtName: string;
  tehsilName: string;

  ///////////// Subscriptions //////////////////////////////
  updateProjectSubs: Subscription;
  retrieveProjectDataSubs: Subscription;
  retrieveStateDataSubs: Subscription;
  retrieveDistrictDataSubs: Subscription;
  updateFormValueChangeSubs: Subscription;
  tehsilRetrieveSubs: Subscription;
  ///////////// Subscriptions //////////////////////////////

  updateProjectForm: FormGroup;
  stateForm: FormGroup;
  districtForm: FormGroup;
  tehsilForm: FormGroup;
  villageForm: FormGroup;
  isEditable=false
  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private sortingService: SortingService,
    private notification: NotificationService,
    private adminService: AdminService,
    private router: Router
  ) {}

  @ViewChild("stepper") stepper: MatStepper;

  ngOnInit(): void {
    this.updateProjectForm = this.fb.group({
      project_name: ["", Validators.required],
      agency: ["", Validators.required],
      principal: ["", Validators.required],
      executive_engineer: ["", Validators.required],
      regional_officer: ["", Validators.required],
      project_ch_from_in_km: ["", Validators.required],
      project_ch_to_in_km: ["", Validators.required],
      PN_request_date:[""]
    });



    ///////////////////// ADD FORMS ////////////////////////////////
    this.stateForm = this.fb.group({
      stateControls: this.fb.array([this.states()]),
    });

    this.districtForm = this.fb.group({
      districtCorrespondingToState: this.fb.array([this.districtsForStates()]),
    });

    this.tehsilForm = this.fb.group({
      tehsilCorrespondingToDistrict: this.fb.array([this.tehsilForDistricts()]),
    });

    this.villageForm = this.fb.group({
      villageCorrespondingToTehsil: this.fb.array([this.villageForTehsils()]),
    });

    ////////////////// Subscribing to value change /////////////////
    this.updateFormValueChangeSubs = this.updateProjectForm
      .get("project_name")
      .valueChanges.subscribe((res) => {
        this.projectId = res.id;
        this.projectName = res.project_name;
        // project retrive for date
        this.adminService.getpnDate(this.projectId).subscribe((res)=>{
          console.log(res,"pn date")
          this.pnRequestDate=res.PN_request_date
        })
        // console.log(this.projectId);
        this.getStateData(this.projectId);
        this.retrieveStateDataSubs = this.adminService
          .retrieveProjectData(this.projectId)
          .subscribe((res) => {
            this.projectData = res;
            console.log(this.projectData,"project");
            if (this.projectData.statedata.length > 0) {
              this.isStateFormVisible = false;
            }
            this.retrieveDataID(this.projectData);
            this.updateProjectForm.patchValue({
              // project_name: res.project_name,
              agency: res.agency,
              principal: res.principal,
              executive_engineer: res.executive_engineer,
              regional_officer: res.regional_officer,
              project_ch_from_in_km: res.project_ch_from_in_km,
              project_ch_to_in_km: res.project_ch_to_in_km,
              PN_request_date:this.pnRequestDate
            });
          });
      });

    ////////////////// DropDown Data ///////////////////////////////
    this.adminService.getProjectList().subscribe((res) => {
      this.projectList.push(...res);
    });

    //2. States
    this.projectService.getStates().subscribe((res) => {
      this.statesList.push(...res);
      this.statesArray.push(...res);
    });

    //3. Agencies
    this.projectService.getAgencies().subscribe((res) => {
      this.agencyList = res;
      this.agencyArray = res;
    });

    //5. collectors
    this.projectService.getDistrictCollectors().subscribe((res) => {
      this.collectorsList = res;
      this.collectorsArray = res;
      // this.sortingService.sortName(this.collectorsArray);
    });

    //8. Principal Secratories
    this.projectService.getPrincipal().subscribe((res) => {
      this.principalList = res;
      this.principalArray = res;
      // this.sortingService.sortName(this.principalArray);
    });

    //9. Executive Engineers
    this.projectService.getExecutiveEngineers().subscribe((res) => {
      this.engineersList = res;
      this.executiveEngineersArray = res;
      // this.sortingService.sortName(this.executiveEngineersArray);
    });

    //10. Regional Officers
    this.projectService.getRegionalOfficers().subscribe((res) => {
      this.roList = res;
      this.regionalOfficersArray = res;
      // this.sortingService.sortName(this.regionalOfficersArray);
    });

    //11. CALA
    this.projectService.getCala().subscribe((res) => {
      this.calaList = res;
      this.calaArray = res;
      // this.sortingService.sortName(this.calaArray);
    });

    //12. TILR
    this.projectService.getTilr().subscribe((res) => {
      this.tilrList = res;
      this.tilrs = res;
      // this.sortingService.sortName(this.tilrs);
    });

    //13. Comissoners
    this.projectService.getComissioners().subscribe((res) => {
      this.comissionersList = res;
      this.comissionersArray = res;
      // this.sortingService.sortName(this.comissionersArray);
    });
  }

  // back to project details

  backtoProject(value){
    // this.isEditable=true
    this.stepper.previous();
    if (value=='dist') {
      this.tehsilDataDropdownList=[]
    }
    if (value=='teh') {
      this.villageDataDropdownList=[]
    }
    if (value=='stage') {
      this.districtDataDropdownList=[]
    }

  }

  getStateData(id) {
    this.adminService.getStateData(id).subscribe((res) => {
      this.stateDataDropdownList = [];
      this.stateDataDropdownList.push(...res);
    });
  }
  // ######################## ADD FORMS LOGIC #################################//

  states(): FormGroup {
    return this.fb.group({
      state: ["", [Validators.required]],
    });
  }

  statArray(): FormArray {
    return this.stateForm.get("stateControls") as FormArray;
  }

  addState() {
    const control = <FormArray>this.stateForm.controls["stateControls"];
    control.push(this.states());
  }

  removeState(stateIndex) {
    if (stateIndex <= 0) return;
    this.statArray().removeAt(stateIndex);
  }

  districtsForStates(): FormGroup {
    return this.fb.group({
      districtControls: this.fb.array([this.districts()]),
    });
  }

  districts() {
    return this.fb.group({
      district: ["", [Validators.required]],
      district_collector: ["", [Validators.required]],
      comissioner: ["", [Validators.required]],
      cala: ["", [Validators.required]],
    });
  }

  distForStateArray(): FormArray {
    return this.districtForm.get("districtCorrespondingToState") as FormArray;
  }

  distArray(distIndex) {
    return this.distForStateArray()
      .at(distIndex)
      .get("districtControls") as FormArray;
  }

  addDistrictForEachState() {
    const control = <FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    );
    control.push(this.districtsForStates());
  }

  removeDistrictAtLastIndex() {
    let index = this.stateIdArray.length;
    this.distForStateArray().removeAt(index);
  }

  addDistrict(stateDistIndex) {
    const control = (<FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    ))
      .at(stateDistIndex)
      .get("districtControls") as FormArray;
    control.push(this.districts());
    // console.log(stateDistIndex);
  }

  removeDistrict(stateDistIndex, distIndex) {
    if (distIndex === 0) return;
    const control = (<FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    ))
      .at(stateDistIndex)
      .get("districtControls") as FormArray;
    control.removeAt(distIndex);
  }

  tehsilForDistricts(): FormGroup {
    return this.fb.group({
      tehsilControls: this.fb.array([this.tehsils()]),
    });
  }

  tehsils() {
    return this.fb.group({
      tehsil: ["", [Validators.required]],
    });
  }

  tehsilForDistrictArray(): FormArray {
    return this.tehsilForm.get("tehsilCorrespondingToDistrict") as FormArray;
  }

  teshArray(tehsilIndex) {
    return this.tehsilForDistrictArray()
      .at(tehsilIndex)
      .get("tehsilControls") as FormArray;
  }

  addTehsilForEachDistrict() {
    const control = <FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    );
    control.push(this.tehsilForDistricts());
  }

  removeTehsilAtLastIndex() {
    let index = this.districtDataIdArray.length;
    this.tehsilForDistrictArray().removeAt(index);
  }

  addTehsil(distTehsilIndex) {
    const control = (<FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    ))
      .at(distTehsilIndex)
      .get("tehsilControls") as FormArray;
    control.push(this.tehsils());
    // console.log(distTehsilIndex);
  }

  removeTehsil(distTehsilIndex, tehsilIndex) {
    if (tehsilIndex === 0) return;
    const control = (<FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    ))
      .at(distTehsilIndex)
      .get("tehsilControls") as FormArray;
    control.removeAt(tehsilIndex);
  }

  villageForTehsils(): FormGroup {
    return this.fb.group({
      villageControls: this.fb.array([this.villages()]),
    });
  }

  villages() {
    return this.fb.group({
      village: ["", [Validators.required]],
      tilr: ["", [Validators.required]],
    });
  }

  villageForTehsilArray(): FormArray {
    return this.villageForm.get("villageCorrespondingToTehsil") as FormArray;
  }

  villArray(villageIndex) {
    return this.villageForTehsilArray()
      .at(villageIndex)
      .get("villageControls") as FormArray;
  }

  addVillageForEachTehsil() {
    const control = <FormArray>(
      this.villageForm.controls["villageCorrespondingToTehsil"]
    );
    control.push(this.villageForTehsils());
  }

  removeVillageAtLastIndex() {
    let index = this.tehsilDataIdArray.length;
    this.villageForTehsilArray().removeAt(index);
  }

  addVillage(tehsVillageIndex) {
    const control = (<FormArray>(
      this.villageForm.controls["villageCorrespondingToTehsil"]
    ))
      .at(tehsVillageIndex)
      .get("villageControls") as FormArray;
    control.push(this.villages());
    // console.log(tehsVillageIndex);
  }

  removeVillage(tehsVillageIndex, villageIndex) {
    if (villageIndex === 0) return;
    const control = (<FormArray>(
      this.villageForm.controls["villageCorrespondingToTehsil"]
    ))
      .at(tehsVillageIndex)
      .get("villageControls") as FormArray;
    control.removeAt(villageIndex);
  }

  ////////////////// District List, Tehsil List, Village List ///////////////////////

  retrieveDataID(data) {
    this.stateDataIDArray = [];
    this.districtDataIDArray = [];
    this.tehsilDataIDArray = [];
    this.districtNameID = [];
    this.villageDataIDArray = [];
    this.stateDataIDArray = data.statedata.map((val) => {
      return val.id;
    });
    this.districtDataIDArray = data.districtdata.map((val) => {
      return val.id;
    });
    this.districtNameID = data.districtdata.map((val) => {
      return val.district;
    });
    this.tehsilDataIDArray = data.tehsildata.map((val) => {
      return val.id;
    });
    this.villageDataIDArray = data.villagedata.map((val) => {
      return val.id;
    });
    // console.log(this.stateDataIDArray);
    // console.log(this.districtDataIDArray);
  }

  //////////////////// Main Functions ////////////////////////////////
  moveToDistrict(data) {
    console.log(data);
    this.stateDataId = data.id;
    this.stateName = data.state_name;
    console.log(this.stateName);

    this.adminService.getDistrictData(this.stateDataId).subscribe((res) => {
      this.districtDataDropdownList.push(...res);
      this.sortingService.sortDistrict(this.districtDataDropdownList);
      // console.log(this.districtDataDropdownList)
    });
    this.projectService.getDistrictForState(data.state_id).subscribe((res) => {
      this.districtCorrespondingToState.push(...res);
      this.sortingService.sortDistrict(this.districtCorrespondingToState);
    });

    // console.log(this.districtDataDropdownList);
    this.stepper.selected.completed = true;
    this.stepper.next();
  }
  moveToTehsil(data) {
    this.districtDataId = data.id;
    this.districtName = data.district;
    this.adminService.getTehsilData(this.districtDataId).subscribe((res) => {
      this.tehsilDataDropdownList.push(...res);
      this.sortingService.sortTehsil(this.tehsilDataDropdownList);
    });
    this.projectService
      .getTehsilsForDistrict(data.district_id)
      .subscribe((res) => {
        this.tehsilCorrespondingToDistrict.push(...res);
        this.sortingService.sortTehsil(this.tehsilCorrespondingToDistrict);
      });
    // console.log(this.tehsilDataDropdownList);
    this.stepper.selected.completed = true;
    this.stepper.next();
  }
  moveToVillage(data) {
    // console.log(data);
    this.tehsilDataId = data.id;
    this.tehsilName = data.tehsil;
    this.adminService.getVillageData(this.tehsilDataId).subscribe((res) => {
      this.villageDataDropdownList.push(...res);
      this.sortingService.sortVillage(this.villageDataDropdownList);
    });
    this.projectService
      .getVillagesForTehsil(data.tehsil_id)
      .subscribe((res) => {
        this.villageCorrespondingToTehsil.push(...res);
        this.sortingService.sortVillage(this.villageCorrespondingToTehsil);
      });
    // console.log(this.villageDataDropdownList);
    this.stepper.selected.completed = true;
    this.stepper.next();
  }
  reset() {
    this.router.navigate(["dashboard"]);
  }

  // ################### ADD FORM SUBMITS ################################# //
  onUpdateProjectForm() {
    const formData = this.updateProjectForm.value;
    formData.project_name = this.projectData.project_name;
    this.adminService.updateProjectDetails(this.projectId, formData).subscribe(
      (res) => {
        this.stepper.selected.completed = true;
        this.isEditable=true
        this.stepper.next();
      },
      (err) => {
        console.log(err.status);
      }
    );
  }

  onSubmitStateForm() {
    const data = this.stateForm.value.stateControls;
    const transformedData = data.map((val) => {
      return { state: val.state, project: this.projectId };
    });
    // console.log(transformedData);
    this.projectService.createStateData(transformedData).subscribe(
      (res) => {
        // console.log(res);
        this.stateDataDropdownList = [];
        this.getStateData(this.projectId);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    this.stateForm.reset();
  }

  onSubmitDistrictForm() {
    const formData = this.districtForm.value.districtCorrespondingToState;
    const rawDataArray = formData.map((val) => {
      return val.districtControls;
    });
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          cala: el.cala,
          district: el.district,
          statedata: this.stateDataId,
          comissioner: el.comissioner,
          district_collector: el.district_collector,
        };
      });
      return arr;
    });
    const data = flatArray.flat();
    this.projectService.createDistrictData(data).subscribe(
      (res) => {
        console.log(res,"djhs");
        this.districtDataDropdownList = [];
        this.adminService.getDistrictData(this.stateDataId).subscribe((res) => {
          this.districtDataDropdownList.push(...res);
          
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    this.districtForm.reset();
  }

  onSubmitTehsilForm() {
    const formData = this.tehsilForm.value.tehsilCorrespondingToDistrict;
    const rawDataArray = formData.map((val) => {
      return val.tehsilControls;
    });
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          tehsil: el.tehsil,
          districtdata: this.districtDataId,
        };
      });
      return arr;
    });
    const data = flatArray.flat();
    // console.log(data);
    this.projectService.createTehsilData(data).subscribe(
      (res) => {
        // console.log(res);
        this.tehsilDataDropdownList = [];
        this.adminService
          .getTehsilData(this.districtDataId)
          .subscribe((res) => {
            this.tehsilDataDropdownList.push(...res);
          });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
      },
      (err) => {
        // console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    this.tehsilForm.reset();
  }

  onSubmitVillageForm() {
    const formData = this.villageForm.value.villageCorrespondingToTehsil;
    const rawDataArray = formData.map((val) => {
      return val.villageControls;
    });
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          village: el.village,
          tilr: el.tilr,
          tehsildata: this.tehsilDataId,
        };
      });
      return arr;
    });
    const data = flatArray.flat();
    // console.log(data);
    this.projectService.createVillageData(data).subscribe(
      (res) => {
        // console.log(res);
        this.villageDataDropdownList = [];
        this.adminService.getVillageData(this.tehsilDataId).subscribe((res) => {
          this.villageDataDropdownList.push(...res);
        });

        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
        // this.router.navigate(["/dashboard"]);
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    this.villageForm.reset();
  }
  // ################### SUBMIT ADD FORMS ################################# //

  ngOnDestroy() {
    // this.updateProjectSubs.unsubscribe();
    // this.retrieveStateDataSubs.unsubscribe();
    // this.updateFormValueChangeSubs.unsubscribe();
    // this.retrieveProjectDataSubs.unsubscribe();
    // this.tehsilRetrieveSubs.unsubscribe();
  }
}
