import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { AllProjectModel } from "../../shared/data.model";
import { ProjectNotificationService } from "../../services/project.notification.service";
import { ReportsService } from "../../services/reports.service";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

@Component({
  selector: "app-project-dashboard",
  templateUrl: "./project-dashboard.component.html",
  styleUrls: ["./project-dashboard.component.css"],
})
export class ProjectDashboardComponent implements OnInit {
  displayedColumns: string[] = [
    "Project Name",
    "Total Land",
    "Mutation",
    "Remaining",
    "land_6a",
    "entry_date",
  ];
  public dataSource = new MatTableDataSource<any>();

  projectsList: string[] = [];
  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  calaID = new BehaviorSubject<any>(null);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private projectNotificationService: ProjectNotificationService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.projectNotificationService.allProjectsList().subscribe((res) => {
      this.projectsList = res.map((el) => {
        return el.project_name;
      });
      console.log(this.projectsList);
      this.dataSource.data = res as AllProjectModel[];

      //////////////////// High Charts //////////////////////////
      const options: any = {
        chart: {
          type: "bar",
        },
        title: {
          text: "Land Acquisition Chart",
        },
        xAxis: {
          categories: this.projectsList,
          title: {
            text: null,
          },
          min: 0,
          max: res.length - 1,
          scrollbar: {
            enabled: true,
          },
          tickLength: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Area (hectares)",
            align: "high",
          },
          labels: {
            overflow: "justify",
          },
        },
        tooltip: {
          valueSuffix: " hectares",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: true,
        },
        series: [
          {
            name: "Total Area To Be Acquired",
            data: res.map((el) => {
              return el.total_area;
            }),
          },
          {
            name: "Area Acquired So Far",
            data: res.map((el) => {
              return el.mutation;
            }),
          },
          {
            name: "Remaining Area",
            data: res.map((el) => {
              return el.remaining;
            }),
          },
          {
            name: "Acquisition Through Private Negotiation",
            data: res.map((el) => {
              return el.land_6a;
            }),
          },
        ],
      };
      HC_exporting(Highcharts);
      Highcharts.chart("container", options);
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  projectDetails(id, name) {
    console.log(id, name);
    localStorage.setItem("PROJECT_ID", id);
    localStorage.setItem("PROJECT_NAME", name);
    this.router.navigate(["/project-info"]);
  }

  downloadReport() {
    this.reportsService.getAllProjectsReport().subscribe((res) => {
      this.isDownloading = true;
      console.log(res);
      this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
        this.isDownloading = false;
        this.isDownloaded = res;
        console.log(res);
      });
    });
  }

  ngOnDestroy() {}
}
