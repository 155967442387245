import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";

import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  constructor(private auth: AuthService, private fb: FormBuilder) {}

  ngOnInit(): void {}
}
