import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Chart } from "chart.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { NotificationService } from "../../services/notification.service";
import { ProjectService } from "../../services/project.service";

import { ProjectDetailsData } from "../../shared/data.model";
import { ReportsService } from "../../services/reports.service";
import { GraphService } from "../../services/graph.service";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend,
  ApexTooltip,
  ApexTitleSubtitle,
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
  title2: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
};

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.css"],
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  displayedColumns: string[] = [
    "VILLAGE NAME",
    "START DATE",
    "3a",
    "JMS REQUEST",
    "JMS",
    "3(A)",
    "3(D)",
    "3(G)",
    "3(H)",
    "POSSESSION",
    "MUTATION",
  ];
  public dataSource = new MatTableDataSource<any>();

  villageList: object[] = [];
  projectName: string;

  cala_id: any;
  projectDetails_id: any;

  projectSubscription: Subscription;
  projectIDSubs: Subscription;
  projectVillageDataSub: Subscription;
  graphDataSubs: Subscription;

  //////////////////////////////////
  delayedVillagesList = [];
  ontimeVillageList = [];
  completedVillageList = [];
  //////////////////////////////////

  chartData: number[] = [];

  delayCount = 0;
  completedCount = 0;
  ontimeCount = 0;

  totalComplete = [];
  totalDelayed = [];
  totalOntime = [];

  complete = this.totalOntime.length;
  delayed = this.totalDelayed.length;
  ontime = this.totalComplete.length;
  counts = [];

  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private notification: NotificationService,
    private reportsService: ReportsService,
    private graphsService: GraphService
  ) {}

  ngOnInit(): void {
    this.projectSubscription = this.projectService.calaID.subscribe((res) => {
      this.cala_id = localStorage.getItem("calaDetails_id");

      this.projectIDSubs = this.projectService.projectID.subscribe((res) => {
        this.projectDetails_id = localStorage.getItem("projectDetails_id");

        this.projectVillageDataSub = this.projectService
          .getVillageList(this.projectDetails_id, this.cala_id)
          .subscribe(
            (res) => {
              console.log(res);
              this.dataSource.data = res as ProjectDetailsData[];
              this.villageList.push(...res);
              this.dataCalculations(this.villageList);
              this.sortAccordingToStatus(this.villageList);
            },
            (err) => {
              this.notification.showNotification(
                "top",
                "right",
                "error",
                err.message
              );
            }
          );
      });
    });

    this.graphDataSubs = this.graphsService
      .getProjectGraphData(this.projectDetails_id, this.cala_id)
      .subscribe((res) => {
        console.log(res);
        this.chartOptions = {
          series: [
            {
              name: "Actual",
              data: [
                {
                  x: "3a",
                  y: this.daysCalculation(
                    res.start_date,
                    res.date_of_3a_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.date_of_3a_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.date_of_3a_expected,
                      currentStatusDate: res.date_of_3a_completed,
                      status:
                        res.date_of_3a_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: "",
                    },
                  ],
                },
                {
                  x: "JMS Request",
                  y: this.daysCalculation(
                    res.start_date,
                    res.jms_request_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.jms_request_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.jms_request_expected,
                      currentStatusDate: res.jms_request_completed,
                      status:
                        res.jms_request_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: "",
                    },
                  ],
                },
                {
                  x: "JMS",
                  y: this.daysCalculation(
                    res.start_date,
                    res.Jms_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.Jms_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.Jms_expected,
                      currentStatusDate: res.Jms_completed,
                      status:
                        res.Jms_completed === "" ? "In Progress" : "Completed",
                      land_aquire: res.total_jms,
                    },
                  ],
                },
                {
                  x: "3(A)",
                  y: this.daysCalculation(
                    res.start_date,
                    res.dates_of_3A_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.dates_of_3A_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.dates_of_3A_expected,
                      currentStatusDate: res.dates_of_3A_completed,
                      status:
                        res.dates_of_3A_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: res.total_3A,
                    },
                  ],
                },
                {
                  x: "3(D)",
                  y: this.daysCalculation(
                    res.start_date,
                    res.dates_of_3D_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.dates_of_3D_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.dates_of_3D_expected,
                      currentStatusDate: res.dates_of_3D_completed,
                      status:
                        res.dates_of_3D_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: res.total_3D,
                    },
                  ],
                },
                {
                  x: "3(G)",
                  y: this.daysCalculation(
                    res.start_date,
                    res.dates_of_3G_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.dates_of_3G_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.dates_of_3G_expected,
                      currentStatusDate: res.dates_of_3G_completed,
                      status:
                        res.dates_of_3G_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: res.total_3G,
                    },
                  ],
                },
                {
                  x: "3(H)",
                  y: this.daysCalculation(
                    res.start_date,
                    res.dates_of_3H_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.dates_of_3H_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.dates_of_3H_expected,
                      currentStatusDate: res.dates_of_3H_completed,
                      status:
                        res.dates_of_3H_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: res.total_3H_area,
                    },
                  ],
                },
                {
                  x: "Possession",
                  y: this.daysCalculation(
                    res.start_date,
                    res.dates_possesion_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.dates_possesion_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.dates_possesion_expected,
                      currentStatusDate: res.dates_possesion_completed,
                      status:
                        res.dates_possesion_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: res.total_possession,
                    },
                  ],
                },
                {
                  x: "Mutation",
                  y: this.daysCalculation(
                    res.start_date,
                    res.dates_of_mutation_completed || new Date()
                  ),
                  goals: [
                    {
                      name: "Expected",
                      value: this.daysCalculation(
                        res.start_date,
                        res.dates_of_mutation_expected
                      ),
                      strokeWidth: 5,
                      strokeColor: "#775DD0",
                      expectedDate: res.dates_of_mutation_expected,
                      currentStatusDate: res.dates_of_mutation_completed,
                      status:
                        res.dates_of_mutation_completed === ""
                          ? "In Progress"
                          : "Completed",
                      land_aquire: res.total_mutation,
                    },
                  ],
                },
              ],
            },
          ],
          chart: {
            height: 350,
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              rangeBarGroupRows: true,
            },
          },
          colors: [
            "#00E396",
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
          ],
          title: {
            text: `Project status as of: ${new Date()
              .toJSON()
              .slice(0, 10)
              .replace(/-/g, "/")
              .split("/")
              .reverse()
              .join("/")}`,
          },
          title2: {
            text: `Total land to be acquired: ${res.total_land_aquare}`,
          },
          subtitle: {
            text: `Project started at: ${res.start_date
              .replace(/-/g, "/")
              .split("/")
              .reverse()
              .join("/")}
              `,
            align: "left",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
              color: "#263238",
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val: number, opts: any): string | number {
              const goals =
                opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
                  .goals;
              if (goals && goals.length) {
                return `${goals[0]?.land_aquire} / ${res.total_land_aquare}`;
              }
              return val;
            },
          },
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const goals =
                w.config.series[seriesIndex].data[dataPointIndex].goals;
              return (
                '<div style="padding: 7px 15px;" class="arrow_box">' +
                '<div style="width: 100%; padding: 4px 15px; display: block; text-align: center; border-bottom: 1px solid #111">' +
                w.globals.labels[dataPointIndex] +
                "</div>" +
                "<span>" +
                `Expected Date: ${goals[0].expectedDate
                  .replace(/-/g, "/")
                  .split("/")
                  .reverse()
                  .join("/")}` +
                "</span>" +
                "<div>" +
                "<span>" +
                `Completion Date: ${goals[0].currentStatusDate
                  .replace(/-/g, "/")
                  .split("/")
                  .reverse()
                  .join("/")}` +
                "</span>" +
                "</div>" +
                "<div>" +
                "<span>" +
                `Current Status: ${goals[0].status}` +
                "</span>" +
                "</div>" +
                "<div>" +
                "<span>" +
                `Land Acquired So far: ${goals[0].land_aquire}` +
                "</span>" +
                "</div>" +
                "</div>"
              );
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ["Actual days", "Expected days"],
            markers: {
              fillColors: ["#00E396", "#775DD0"],
            },
          },
        };
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterContentInit() {
    this.projectName = localStorage.getItem("project_name");
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  daysCalculation(startDate, expectedDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(expectedDate);
    const timeDifference = date2.getTime() - date1.getTime();
    const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return days;
  }

  ///////////////////// Chart data calculations /////////////////////////
  percentCalc(obj, arr) {
    const valuesArray = Object.values(obj);
    const percentArray = valuesArray.map((val: number) => {
      return (val / arr.length) * 100;
    });
    return percentArray;
  }

  dataCalculations(arr) {
    let countObject = {
      count3a: 0,
      countJMSRequest: 0,
      countJMS: 0,
      count3A: 0,
      count3D: 0,
      count3G: 0,
      count3H: 0,
      countPossession: 0,
      countMutation: 0,
    };
    arr.forEach((el) => {
      if (el.date_of_3a === "Completed") {
        countObject.count3a++;
      }
      if (el.date_of_jms_request === "Completed") {
        countObject.countJMSRequest++;
      }
      if (el.dates_ofJms_done === "Completed") {
        countObject.countJMS++;
      }
      if (el.date_of_3A_notification === "Completed") {
        countObject.count3A++;
      }
      if (el.dates_of_3D_notification === "Completed") {
        countObject.count3D++;
      }
      if (el.dates_of_3G_notification === "Completed") {
        countObject.count3G++;
      }
      if (el.h_3H === "Completed") {
        countObject.count3H++;
      }
      if (el.dates_possesion_of_land_handling === "Completed") {
        countObject.countPossession++;
      }
      if (el.dates_of_mutation_of_land_record === "Completed") {
        countObject.countMutation++;
      }
    });
    this.chartData = this.percentCalc(countObject, this.villageList);
  }

  //////////////////////// Village status calculations //////////////////////////

  sortAccordingToStatus(arr) {
    this.ontimeVillageList = [];
    this.delayedVillagesList = [];
    this.completedVillageList = [];
    arr.forEach((e) => {
      if (
        e.dates_of_3D_notification === "Delayed" ||
        e.date_of_3A_notification === "Delayed" ||
        e.date_of_3a === "Delayed" ||
        e.date_of_jms_request === "Delayed" ||
        e.dates_ofJms_done === "Delayed" ||
        e.dates_of_3G_notification === "Delayed" ||
        e.dates_possesion_of_land_handling === "Delayed" ||
        e.dates_of_mutation_of_land_record === "Delayed" ||
        e.h_3H === "Delayed"
      ) {
        this.delayedVillagesList.push(e);
      } else if (
        e.date_of_3A_notification === "Completed" &&
        e.date_of_3a === "Completed" &&
        e.date_of_jms_request === "Completed" &&
        e.dates_ofJms_done === "Completed" &&
        e.dates_of_3D_notification === "Completed" &&
        e.dates_of_3G_notification === "Completed" &&
        e.dates_of_mutation_of_land_record === "Completed" &&
        e.dates_possesion_of_land_handling === "Completed" &&
        e.h_3H === "Completed"
      ) {
        this.completedVillageList.push(e);
      } else {
        this.ontimeVillageList.push(e);
      }
    });
  }

  totalVillages() {
    // console.log(this.villageList);
    this.dataSource.data = this.villageList;
  }
  ontimeVillages() {
    // console.log(this.ontimeVillageList);
    this.dataSource.data = this.ontimeVillageList;
  }
  delayedVillages() {
    // console.log(this.delayedVillagesList);
    this.dataSource.data = this.delayedVillagesList;
  }
  completedVillages() {
    // console.log(this.completedVillageList);
    this.dataSource.data = this.completedVillageList;
  }

  villageDetails(id): void {
    this.projectService.newVillageID.next(id);
    localStorage.setItem("villageDetails_id", id);
    this.router.navigateByUrl("/village-details");
  }

  //donwload pdf from original canvas
  downloadPDF() {
    var canvas = document.querySelector(
      "#projectStatusChart"
    ) as HTMLCanvasElement;
    //creates image
    var canvasImg = canvas.toDataURL("image/jpeg", 1.0);

    //creates PDF from img
    var doc: any = new jsPDF("landscape");
    doc.setFontSize(20);
    doc.text(15, 15, "Cool Chart");
    doc.addImage(canvasImg, "JPEG", 10, 10, 280, 150);
    doc.save("canvas.pdf");
  }

  downloadReport() {
    this.reportsService
      .getProjectWiseVillagesReport(this.projectDetails_id, this.cala_id)
      .subscribe((res) => {
        this.isDownloading = true;
        console.log(res);
        this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
          this.isDownloading = false;
          this.isDownloaded = res;
          console.log(res);
        });
      });
  }

  ngOnDestroy() {
    this.villageList = [];
    this.projectSubscription.unsubscribe();
    this.projectVillageDataSub.unsubscribe();
    this.projectIDSubs.unsubscribe();
    this.graphDataSubs.unsubscribe();
  }
}
