import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { AdminService } from "../services/admin.service";

import { NotificationService } from "../services/notification.service";
import { DeletevillageModal } from "../shared/data.model";


@Component({
    selector: 'app-dalete-village',
    templateUrl: 'dalete-village.component.html',
    styleUrls: ['dalete-village.component.css']
})
export class DeleteVillageComponent implements OnInit {

    deletevillageForm;
    villageId;
    showorhideModal = false;
    projectColumns: string[] = ["Village name", "Delete id"];
    public dataSource = new MatTableDataSource<any>();


    constructor(private fb: FormBuilder, private adminService: AdminService, private notification: NotificationService) { }

    ngOnInit(): void {

        this.deletevillageForm = this.fb.group({
            village: [null,],
        });
    }


    onSubmitDeleteVillageForm() {
        console.log(this.deletevillageForm.value.village)
        this.adminService.villagelist(this.deletevillageForm.value.village).subscribe((res) => {
            console.log(res, "village list")
            document.getElementById("village_list").style.display = ""
            this.dataSource.data = res as DeletevillageModal[];
        })
    }

    setid(id) {
        console.log(id, "id of vill")
        this.villageId = id
    }

    delete() {
        // console.log(id)
        this.adminService.deletevillage(this.villageId).subscribe(res => {
            console.log(res, "success");
            if (res) {
                this.showorhideModal = false;
                this.onSubmitDeleteVillageForm()
                const successMessage = "Village deleted successfully!";
                this.notification.showNotification(
                    "top",
                    "right",
                    "success",
                    successMessage
                );
            }

        },
            (err) => {
                console.log(err, "error")

                if (err) {
                    const successMessage = "Village is associated with the project and cannot be deleted.";
                    this.notification.showNotification(
                        "top",
                        "right",
                        "error",
                        successMessage
                    );
                }
            })

    }


    showpopup() {
        this.showorhideModal = true
    }

    hidepopup() {
        this.showorhideModal = false

    }
}