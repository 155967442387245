import { Component, OnInit, ViewChild } from "@angular/core";

import { NotificationService } from "../../services/notification.service";
import { ProjectService } from "../../services/project.service";
import { SortingService } from "../../services/sorting.service";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { AdminService } from "../../services/admin.service";

@Component({
  selector: "app-stepper-form",
  templateUrl: "./stepper-form.component.html",
  styleUrls: ["./stepper-form.component.css"],
})
export class StepperFormComponent implements OnInit {
  projectForm: FormGroup;
  stateForm: FormGroup;
  districtForm: FormGroup;
  tehsilForm: FormGroup;
  villageForm: FormGroup;
  isEditable = false;
  stepone = false

  tilrs = [];
  statesArray: object[] = [];
  tehsilArray: object[] = [];
  districtArray: object[] = [];
  villageArray: object[] = [];
  projectList: any;
  agencyArray: any;
  calaArray: any;
  principalArray: any;
  regionalOfficersArray: any;
  comissionersArray: any;
  executiveEngineersArray: any;
  collectorsArray: any;

  ///// Form ID ////////
  projectFormID: string;
  stateFormID: string;
  districtFormID: string;
  tehsilFormID: string;
  villageFormID: string;
  //////////////////////

  ////////////////////////
  stateIDArray: string[] = [];
  stateNameIDArray = [];
  stateNameArray: string[] = [];
  districtDataIDArray = [];
  districtNameIDArray = [];
  districtNameArray: string[] = [];
  tehsilDataIDArray = [];
  tehsilNameIDArray = [];
  tehsilNameArray: string[] = [];
  removelastIndex=true
  ////////////////////////

  //////Project Update//////
  projectUpdate: boolean = false;
  stateUpdate: boolean = false;
  disUpdate: boolean = false;
  tehsilupdate: boolean = false;
  
  //////Project Update//////

  // Form data//

  disFormData=[];
  disFormDatafirst=[];
  updateDis;
  tehsilupdatedata=[];
  tehsilres;
  tehid;
  tehdata;
  addedOrnot=false;
  addedornotDist=false;


  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private sortingService: SortingService,
    private notification: NotificationService,
    private router: Router,
    private adminService: AdminService,
  ) { }

  @ViewChild("stepper") stepper: MatStepper;

  ngOnInit() {
    this.projectForm = this.fb.group({
      project_name: ["", [Validators.required]],
      agency: ["", [Validators.required]],
      principal: ["", [Validators.required]],
      regional_officer: ["", [Validators.required]],
      executive_engineer: ["", [Validators.required]],
      project_ch_from_in_km: ["", [Validators.required]],
      project_ch_to_in_km: ["", [Validators.required]],
    });

    this.stateForm = this.fb.group({
      stateControls: this.fb.array([this.states()]),
    });

    this.districtForm = this.fb.group({
      districtCorrespondingToState: this.fb.array([this.districtsForStates()]),
    });

    this.tehsilForm = this.fb.group({
      tehsilCorrespondingToDistrict: this.fb.array([this.tehsilForDistricts()]),
    });

    this.villageForm = this.fb.group({
      villageCorrespondingToTehsil: this.fb.array([this.villageForTehsils()]),
    });

    ////////////////// DropDown Logics ///////////////////////////////
    //1. Project Name
    this.projectService.allProjectList().subscribe((res) => {
      this.projectList = res;
    });

    //2. States
    this.projectService.getStates().subscribe((res) => {
      this.statesArray.push(...res);
    });

    //3. Agencies
    this.projectService.getAgencies().subscribe((res) => {
      this.agencyArray = res;
    });

    //4. Districts

    //5. collectors
    this.projectService.getDistrictCollectors().subscribe((res) => {
      this.collectorsArray = res;
      // console.log(res,"collector")
      // this.sortingService.sortName(this.collectorsArray);
    });

    //8. Principal Secratories
    this.projectService.getPrincipal().subscribe((res) => {
      // console.log(res);
      this.principalArray = res;
    });

    //9. Executive Engineers
    this.projectService.getExecutiveEngineers().subscribe((res) => {
      this.executiveEngineersArray = res;
    });

    //10. Regional Officers
    this.projectService.getRegionalOfficers().subscribe((res) => {
      this.regionalOfficersArray = res;
    });

    //11. CALA
    this.projectService.getCala().subscribe((res) => {
      this.calaArray = res;
      // this.sortingService.sortName(this.calaArray);
    });

    //12. TILR
    this.projectService.getTilr().subscribe((res) => {
      this.tilrs = res;
      // this.sortingService.sortName(this.tilrs);
    });

    //13. Comissoners
    this.projectService.getComissioners().subscribe((res) => {
      this.comissionersArray = res;
      // this.sortingService.sortName(this.comissionersArray);
    });
  }

  // District list function
  districtList(index) {
    this.projectService
      .getDistrictList(this.stateIDArray[index])
      .subscribe((res) => {
        this.districtArray.push(...res);
        this.sortingService.sortDistrictByIndex(this.districtArray, index);
      });
  }

  states(): FormGroup {
    return this.fb.group({
      state: ["", [Validators.required]],
    });
  }

  statArray(): FormArray {
    return this.stateForm.get("stateControls") as FormArray;
  }

  addState() {
    const control = <FormArray>this.stateForm.controls["stateControls"];
    // control.push(this.states());
  }

  removeState(stateIndex) {
    if (stateIndex <= 0) return;
    this.statArray().removeAt(stateIndex);
  }

  districtsForStates(): FormGroup {
    return this.fb.group({
      districtControls: this.fb.array([this.districts()]),
    });
  }
  tehsilForDistricts(): FormGroup {
    return this.fb.group({
      tehsilControls: this.fb.array([this.tehsils()]),
    });
  }
  villageForTehsils(): FormGroup {
    return this.fb.group({
      villageControls: this.fb.array([this.villages()]),
    });
  }

  districts() {
    return this.fb.group({
      district: ["", [Validators.required]],
      district_collector: ["", [Validators.required]],
      comissioner: ["", [Validators.required]],
      cala: ["", [Validators.required]],
    });
  }

  tehsils() {
    return this.fb.group({
      tehsil: ["", [Validators.required]],
    });
  }

  villages() {
    return this.fb.group({
      village: ["", [Validators.required]],
      tilr: ["", [Validators.required]],
    });
  }

  distForStateArray(): FormArray {
    return this.districtForm.get("districtCorrespondingToState") as FormArray;
  }

  tehsilForDistrictArray(): FormArray {
    return this.tehsilForm.get("tehsilCorrespondingToDistrict") as FormArray;
  }

  villageForTehsilArray(): FormArray {
    return this.villageForm.get("villageCorrespondingToTehsil") as FormArray;
  }

  distArray(distIndex) {
    return this.distForStateArray()
      .at(distIndex)
      .get("districtControls") as FormArray;
  }

  teshArray(tehsilIndex) {
    return this.tehsilForDistrictArray()
      .at(tehsilIndex)
      .get("tehsilControls") as FormArray;
  }

  villArray(villageIndex) {
    return this.villageForTehsilArray()
      .at(villageIndex)
      .get("villageControls") as FormArray;
  }

  addDistrictForEachState() {
    const control = <FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    );
    control.push(this.districtsForStates());
  }

  addTehsilForEachDistrict() {
    const control = <FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    );
    control.push(this.tehsilForDistricts());
   
  }

  addVillageForEachTehsil() {
    const control = <FormArray>(
      this.villageForm.controls["villageCorrespondingToTehsil"]
    );
    control.push(this.villageForTehsils());
  }

  removeDistrictAtLastIndex() {
    let index = this.stateIDArray.length;
    this.distForStateArray().removeAt(index);
  }

  removeTehsilAtLastIndex() {
    let index = this.districtDataIDArray.length;
    this.tehsilForDistrictArray().removeAt(index);
  }

  removeVillageAtLastIndex() {
    let index = this.tehsilDataIDArray.length;
    this.villageForTehsilArray().removeAt(index);
  }

  addDistrict(stateDistIndex) {
    const control = (<FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    ))
      .at(stateDistIndex)
      .get("districtControls") as FormArray;
    control.push(this.districts());
    // console.log(stateDistIndex);
  }

  addTehsil(distTehsilIndex) {
    
    const control = (<FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    ))
      .at(distTehsilIndex)
      .get("tehsilControls") as FormArray;
    control.push(this.tehsils());
    // console.log(distTehsilIndex);
  }

  addVillage(tehsVillageIndex) {
    const control = (<FormArray>(
      this.villageForm.controls["villageCorrespondingToTehsil"]
    ))
      .at(tehsVillageIndex)
      .get("villageControls") as FormArray;
    control.push(this.villages());
    // console.log(tehsVillageIndex);
  }

  removeDistrict(stateDistIndex, distIndex) {
    console.log(stateDistIndex,distIndex,"ids")
    if (distIndex === 0) return;
    const control = (<FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    ))
      .at(stateDistIndex)
      .get("districtControls") as FormArray;
    control.removeAt(distIndex);
  }

  removeTehsil(distTehsilIndex, tehsilIndex) {
    if (tehsilIndex === 0) return;
    const control = (<FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    ))
      .at(distTehsilIndex)
      .get("tehsilControls") as FormArray;
    control.removeAt(tehsilIndex);
  }

  removeVillage(tehsVillageIndex, villageIndex) {
    if (villageIndex === 0) return;
    const control = (<FormArray>(
      this.villageForm.controls["villageCorrespondingToTehsil"]
    ))
      .at(tehsVillageIndex)
      .get("villageControls") as FormArray;
    control.removeAt(villageIndex);
  }

  /////////////////// List //////////////////////////
  districtListService(id, index) {
    this.projectService.getDistrictForState(id).subscribe((res) => {
      this.districtArray.push(res);
      this.sortingService.sortDistrictByIndex(this.districtArray, index);
    });
  }

  tehsilListService(id, index) {
    this.projectService.getTehsilsForDistrict(id).subscribe((res) => {
      this.tehsilArray.push(res);
      
      this.sortingService.sortTehsilByIndex(this.tehsilArray, index);
      // console.log(this.tehsilArray,"ye error")
    });
  }

  villageListService(id, index) {
    this.projectService.getVillagesForTehsil(id).subscribe((res) => {
      this.villageArray.push(res);
      this.sortingService.sortVillageByIndex(this.villageArray, index);
    });
  }

  /////////////// Submit Logic //////////////////////
  onSubmitProjectForm() {
    // this.stepper.selected.completed = true
    // this.stepper.next();
    this.isEditable = true;
    // console.log(this.projectForm.value);
    this.projectService.createProject(this.projectForm.value).subscribe(
      (res) => {
        // console.log(res,"project is created");
        this.projectFormID = res.id;
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Project created successfully!!!"
        );
        this.stepper.selected.completed = true;
        this.stepper.next();
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    // this.projectForm.reset();
  }

  backtoProjectForm() {
    this.stepper.previous();
    this.projectUpdate = true
  }
  onUpdateProjectForm() {
    // this.stepper.selected.completed = true
    // this.stepper.next()
    const formData = this.projectForm.value;
    this.adminService.updateProjectDetails(this.projectFormID, formData).subscribe(
      (res) => {
        // console.log(res,"project is updated")
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Project Updated successfully!!!"
        );
        // this.stepper.selected.completed = true;
        this.stepper.next();
      },
      (err) => {
        console.log(err.status);
      }
    );
  }

  onSubmitStateForm() {
    const data = this.stateForm.value.stateControls;
    const transformedData = data.map((val) => {
      return { state: val.state, project: this.projectFormID };
    });
    // this.stepper.selected.completed = true;
    // this.stepper.next();
    // console.log(transformedData);
    this.projectService.createStateData(transformedData).subscribe(
      (res) => {
        // console.log(res);
        this.stateIDArray = res.map((val) => {
          return val.id;
        });
        // console.log(this.stateIDArray);
        this.stateNameIDArray = res.map((val) => {
          return val.state;
        });
        // console.log(this.stateNameIDArray);
        this.stateNameIDArray.forEach((el, index) => {
          this.districtListService(el, index);
        });
        this.stateNameArray = res.map((val) => {
          return val.state_name;
        });
        // console.log(this.districtArray);
        this.stateIDArray.forEach((val) => {
          this.addDistrictForEachState();
        });
        this.removeDistrictAtLastIndex();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "State added to the project successfully!!!"
        );
        this.stepper.selected.completed = true;
        this.stepper.next();
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    // this.stateForm.reset();
  }

  backtoStateForm() {
    this.stateUpdate = true;
    this.stepper.previous();
  }
  onUpdateStateform(data) {
    // console.log(data);
    // this.stateDataId = data.id;
    // this.stateName = data.state_name;
    // console.log(this.stateName);

    // this.adminService.getDistrictData(this.stateDataId).subscribe((res) => {
    //   this.districtDataDropdownList.push(...res);
    //   this.sortingService.sortDistrict(this.districtDataDropdownList);
    // });
    // this.projectService.getDistrictForState(data.state_id).subscribe((res) => {
    //   this.districtCorrespondingToState.push(...res);
    //   this.sortingService.sortDistrict(this.districtCorrespondingToState);
    // });

    // console.log(this.districtDataDropdownList);
    // this.stepper.selected.completed = true;
    this.stepper.next();
  }

  

  handleChange(obj,event,index){
    obj['district']=event.target.value;
    obj['disable']=false;
    this.updateDis=obj;
    this.districtNameArray[index]=obj['district_name'];
    // console.log(this.districtNameArray,"disnamearray",index)


    // console.log(obj,"new form data")
  }
  handleChangeDC(obj,event,index){
    obj['district_collector']=event.target.value;
    obj['disable']=false;
    this.updateDis=obj;



    // console.log(obj,"new form data")
  }
  handleChangeComm(obj,event){
    obj['comissioner']=event.target.value;
    obj['disable']=false;
    this.updateDis=obj


    // console.log(obj,"new form data")
  }
  handleChangecala(obj,event){
    obj['cala']=event.target.value;
    obj['disable']=false;
    this.updateDis=obj


    // console.log(obj,"new form data")
  }

  onSubmitDistrictForm() {
    const formData = this.districtForm.value.districtCorrespondingToState;
    console.log("initail form data",formData)
    const rawDataArray = formData.map((val) => {
      return val.districtControls;
    });
   
    // this.stepper.selected.completed = true
    // this.stepper.next();
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          cala: el.cala,
          district: el.district,
          statedata: this.stateIDArray[index],
          comissioner: el.comissioner,
          district_collector: el.district_collector,
        };
      });
      return arr;
    });
    const data = flatArray.flat();
    // console.log(data,"form data");
    for (let index = 0; index < data.length; index++) {
      // console.log(data[index].district.length)
      if (data[index].district.length>0) {
        this.addedornotDist=true
        
      }
      
    }
    
    // this.disFormData=data;
    // this.disFormDatafirst=data


   if (this.addedornotDist==true) {
    this.projectService.createDistrictData(data).subscribe(
      (res) => {
      this.disFormDatafirst= this.disFormData.concat(res);
      this.disFormData=res
        // console.log(res);
        // this.disFormDatafirst=[];
        res.forEach((element,index) => {
          this.districtDataIDArray.push(element.id)
        });
        this.districtDataIDArray = this.districtDataIDArray.map((val) => {
          return val;
        });
        // this.districtDataIDArray = res.map((val) => {
        //   return val.id;
        // });
        // console.log(this.districtDataIDArray);
        this.districtNameIDArray = res.map((val) => {
          return val.district;
        });
        // console.log(this.districtNameIDArray);
        // this.districtNameArray.push(res.district_name);
        res.forEach((element,index) => {
          this.districtNameArray.push(element.district_name)
        });
        // console.log(this.districtNameArray,"disks")

        this.districtNameArray = this.districtNameArray.map((val) => {
          return val;
        });
        // console.log(this.districtNameArray);
        this.districtNameIDArray.forEach((el, index) => {
          this.tehsilListService(el, index);
        });
        // console.log(this.tehsilArray);
        // this.districtDataIDArray.forEach((val) => {
        //   this.addTehsilForEachDistrict();
        // });
       
        // console.log(this.districtDataIDArray.length,"length")
        for (let index = 0; index < this.districtNameIDArray.length; index++) {
          
          this.addTehsilForEachDistrict();
        }
        if (this.removelastIndex==true) {
          this.removeTehsilAtLastIndex();
          this.removelastIndex=true;
        }
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "District added to the project successfully!!!"
        );
        this.stepper.selected.completed = true;
        this.stepper.next();
        // const control = <FormArray>(
        //   this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
        // );
        // console.log(control.value,"kya data hai")
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
   } else {
    this.stepper.selected.completed = true;
    this.stepper.next();
   }
    // this.districtForm.reset();
  }
  backtoDisForm(){
    this.addedornotDist=false
    // console.log(this.disFormDatafirst,"empty data");
    for (let index = 0; index < this.disFormDatafirst.length; index++) {
        this.disFormDatafirst[index]["disable"]=true
      
    }
    this.disUpdate = true;
    const control = <FormArray>(
      this.districtForm.controls["districtCorrespondingToState"]
    );
    control.controls=[];
    this.stateIDArray.forEach((val) => {
      this.addDistrictForEachState();
    });
    this.removeDistrictAtLastIndex();
    // control.push(this.districtsForStates());

    
    this.stepper.previous();
  }
  onUpdateDisform(){
    this.stepper.next()
  }

  editDistrict(index){
    // console.log(this.updateDis,"single form value");
    let data={
      statedata:this.updateDis.statedata,
      district:this.updateDis.district,
      comissioner:this.updateDis.comissioner,
      cala:this.updateDis.cala,
      district_collector:this.updateDis.district_collector
    }
    this.projectService.updateDistrictData(data,this.updateDis.id).subscribe((res)=>{
      // console.log(res,"edit project report");
      this.districtNameArray[index]=res['district_name'];
      // console.log(this.districtNameArray,"disnamearray",index);
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "District updated successfully!!!"
      );
    })

    
    // let editId=this.districtDataIDArray[indexofDis];
    // let data=this.disFormData[indexofDis]
    // console.log(editId,data,"diseditid and dis form data")

  }

  

  onSubmitTehsilForm() {
    const formData = this.tehsilForm.value.tehsilCorrespondingToDistrict;
    const rawDataArray = formData.map((val) => {
      return val.tehsilControls;
    });
    // this.stepper.selected.completed = true
    // this.stepper.next();
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          tehsil: el.tehsil,
          districtdata: this.districtDataIDArray[index],
          distname:this.districtNameArray[index],
          tehsilArr:this.tehsilArray[index],
          disabled:true,
        };
      });
      return arr;
    });
    const control = <FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    );
    // console.log(control.value,"value ")
    const data = flatArray.flat();
    // console.log(data,"data")
    for (let index = 0; index < data.length; index++) {
      // console.log(data[index].tehsil.length)
      if (data[index].tehsil.length>0) {
        this.addedOrnot=true;
        this.tehsilupdatedata=this.tehsilupdatedata.concat(data[index])
        
      }
      
    }
   
    // console.log(this.tehsilupdatedata,"tehsil submit dsata");
    // this.stepper.selected.completed = true;
    // this.stepper.next();
    // let tehsildata =[]
    // for (let index = 0; index < data.length; index++) {
    //     tehsildata.push({"tehsil":data[index].tehsil,"districtdata":data[index].districtdata})  
    // }
    // console.log(tehsildata,"tehsil data")

    if (this.addedOrnot==true) {
      this.projectService.createTehsilData(data).subscribe(
        (res) => {
          // console.log(res);
          this.tehsilres=res;
          res.forEach(element => {
            this.tehsilDataIDArray.push(element.id)
          });
          
          this.tehsilDataIDArray = this.tehsilDataIDArray.map((val) => {
            return val;
          });
          // console.log(this.tehsilDataIDArray);
          this.tehsilNameIDArray = res.map((val) => {
            return val.tehsil;
          });
          // console.log(this.tehsilNameIDArray);
          res.forEach(element => {
            this.tehsilNameArray.push(element.tehsil_name)
          });
          this.tehsilNameArray = this.tehsilNameArray.map((val) => {
            return val;
          });
          // console.log(this.tehsilNameArray);
          this.tehsilNameIDArray.forEach((el, index) => {
            this.villageListService(el, index);
          });
          // console.log(this.villageArray);
          this.tehsilNameIDArray.forEach((val) => {
            this.addVillageForEachTehsil();
          });
          this.removeVillageAtLastIndex();
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Tehsil added to the project successfully!!!"
          );
          this.stepper.selected.completed = true;
          this.stepper.next();
        },
        (err) => {
          console.error(err.error.message);
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.message
          );
        }
      );
    } else {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
    // this.tehsilForm.reset();
  }
  
  addTehsilindist(distid){
    // console.log(distid)
    const formData = this.tehsilForm.value.tehsilCorrespondingToDistrict;
    const rawDataArray = formData.map((val) => {
      return val.tehsilControls;
    });
    // this.stepper.selected.completed = true
    // this.stepper.next();
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          tehsil: el.tehsil,
          districtdata: this.districtDataIDArray[index],
          distname:this.districtNameArray[index],
          tehsilArr:this.tehsilArray[index]
        };
      });
      return arr;
    });
    const control = <FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    );
    // console.log(control.value,"value ")
    const data = flatArray.flat();
    // console.log(data,"data");
     let newdata= data.filter((el)=>
        el.districtdata===distid
      )
      // console.log(newdata,"bahvshvsgvsv")
      this.tehsilupdatedata=this.tehsilupdatedata.concat(newdata)
      this.projectService.createTehsilData(newdata).subscribe(
        (res) => {
          console.log(res);
          this.tehsilres=this.tehsilres.concat(res);
          // console.log(this.tehsilres,"data combile")
          res.forEach(element => {
            this.tehsilDataIDArray.push(element.id)
          });
          
          this.tehsilDataIDArray = this.tehsilDataIDArray.map((val) => {
            return val;
          });
          // console.log(this.tehsilDataIDArray);
          this.tehsilNameIDArray = res.map((val) => {
            return val.tehsil;
          });
          // console.log(this.tehsilNameIDArray);
          res.forEach(element => {
            this.tehsilNameArray.push(element.tehsil_name)
          });
          this.tehsilNameArray = this.tehsilNameArray.map((val) => {
            return val;
          });
          // console.log(this.tehsilNameArray);
          this.tehsilNameIDArray.forEach((el, index) => {
            this.villageListService(el, index);
          });
          // console.log(this.villageArray);
          this.tehsilNameIDArray.forEach((val) => {
            this.addVillageForEachTehsil();
          });
          this.removeVillageAtLastIndex();
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Tehsil added to the project successfully!!!"
          );
          const control = <FormArray>(
            this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
          );
          control.controls=[];
          for (let index = 0; index < this.districtNameArray.length; index++) {
                
            this.addTehsilForEachDistrict();
          }
          if (this.removelastIndex==true) {
            this.removeTehsilAtLastIndex();
            this.removelastIndex=true;
          }
          // this.stepper.selected.completed = true;
          // this.stepper.next();
        },
        (err) => {
          console.error(err.error.message);
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.message
          );
        }
      );
  }

  handletehChange(data,event,index){
     this.tehid =this.tehsilres[index].id
    // console.log(this.tehid);
    data.disabled=false;
    this.tehdata={
      tehsil:event.target.value,
      districtdata:data.districtdata
    }
    // console.log(this.tehdata)
   
  }
  editTeh(index){
    // console.log(this.tehsilNameIDArray,"tehsilnameidarrayupdated");
    //  console.log(this.tehsilNameArray,"tehsilnameidarrayupdated");
    this.projectService.updateTehsilData(this.tehdata,this.tehid).subscribe((res)=>{
      // console.log(res,"tehsil update response")
     this.tehsilNameIDArray[index]=res.tehsil;
     this.tehsilNameArray[index]=res.tehsil_name;
    //  console.log(this.tehsilNameIDArray,"tehsilnameidarrayupdated");
    //  console.log(this.tehsilNameArray,"tehsilnameidarrayupdated");
     this.notification.showNotification(
      "top",
      "right",
      "success",
      "Tehsil updated successfully!!!"
    );
     
    })
  }
  backtotehform(){
    this.addedOrnot=false
    this.tehsilupdate = true;
    this.stepper.previous();
    // console.log(this.tehsilupdatedata,"data rehata h ya nahi")
    // const control = <FormArray>(
    //   this.villageForm.controls["villageCorrespondingToTehsil"]
    // );
    // control.controls=[];
    const control = <FormArray>(
      this.tehsilForm.controls["tehsilCorrespondingToDistrict"]
    );
    control.controls=[];
    for (let index = 0; index < this.districtNameArray.length; index++) {
          
      this.addTehsilForEachDistrict();
    }
    if (this.removelastIndex==true) {
      this.removeTehsilAtLastIndex();
      this.removelastIndex=true;
    }
    }

  onUpdatetehsilform(){
    this.stepper.next()
  }

  onSubmitVillageForm() {
    const formData = this.villageForm.value.villageCorrespondingToTehsil;
    const rawDataArray = formData.map((val) => {
      return val.villageControls;
    });
    // this.stepper.selected.completed = true
    // this.stepper.next();
    const flatArray = rawDataArray.map((val, index) => {
      const arr = val.map((el) => {
        return {
          village: el.village,
          tilr: el.tilr,
          tehsildata: this.tehsilDataIDArray[index],
        };
      });
      return arr;
    });
    const data = flatArray.flat();
    this.projectService.createVillageData(data).subscribe(
      (res) => {
        this.router.navigate(["/dashboard"]);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Villade added to the project successfully!!!"
        );
        this.stepper.selected.completed = true;
        this.stepper.next();
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    // this.villageForm.reset();
  }
  
}
