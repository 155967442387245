import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SortingService } from "../../services/sorting.service";
import { ProjectNotificationService } from "../../services/project.notification.service";
import { ProjectService } from "../../services/project.service";
import { NewProgressService } from "../../services/newProgress.service";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NotificationService } from "../../services/notification.service";
import { AdminService } from "../../services/admin.service";
// import $ from "jquery";

@Component({
  selector: "app-filter-project",
  templateUrl: "./filter-project.component.html",
  styleUrls: ["./filter-project.component.css"],
})
export class FilterProjectComponent implements OnInit {
  projectList = [];
  stateList = [];
  districtList = [];
  tehsilList = [];
  villageList = [];
  projectListPN = [];
  stateListPN = [];
  districtListPN = [];
  tehsilListPN = [];
  villageListPN = [];

  projectName: string;
  stateName: string;
  districtName: string;
  tehsilName: string;
  villageName: string;

  projectNamePN: string;
  stateNamePN: string;
  districtNamePN: string;
  tehsilNamePN: string;
  villageNamePN: string;

  calaList = [];
  projectsUnderCala = [];

  projectID: string;
  stateID: string;
  districtID: string;
  tehsilID: string;

  projectIDPN: string;
  stateIDPN: string;
  districtIDPN: string;
  tehsilIDPN: string;

  buttonDisable = false;

  villageId = new BehaviorSubject<any>(null);

  // saving village id to localStorage
  village_id: string;

  calaID: any;
  calaName: string;

  filteredOptions: Observable<object[]>;
  // multiple select dropdown

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;

  filterForm: FormGroup;
  privateForm: FormGroup;
  districtForm: FormGroup;
  calaFundForm: FormGroup;
  addPriority: FormGroup
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private projectNotificationService: ProjectNotificationService,
    private fb: FormBuilder,
    private sortingService: SortingService,
    private newProgressService: NewProgressService,
    private notification: NotificationService,
    private admin :AdminService
  ) {

    this.addPriority = this.fb.group({
      project: [null, [Validators.required]],
      stage: [null, [Validators.required]],
      priority: [null, [Validators.required]],
      progress: [null, [Validators.required]],
      monitoring: [null, [Validators.required]],
    });

    this.filterForm = this.fb.group({
      project: [null, [Validators.required]],
      // state: [null, [Validators.required]],
      district: [null, [Validators.required]],
      tehsil: [null, [Validators.required]],
      village: [null, [Validators.required]],
    });

    // private negotiation form 

    this.privateForm = this.fb.group({
      pnproject: [null, [Validators.required]],
      // pnstate: [null, [Validators.required]],
      pndistrict: [null, [Validators.required]],
      pntehsil: [null, [Validators.required]],
      pnvillage: [null, [Validators.required]],
    });

    this.districtForm = this.fb.group({
      project: [null, [Validators.required]],
      state: [null, [Validators.required]],
      district: [null, [Validators.required]],
    });

    this.calaFundForm = this.fb.group({
      calaName: [null, [Validators.required]],
    });
  }

  ngOnInit() {


    // dropdown select

    this.dropdownList = [
      { item_text: 'PRAGATI' },
      { item_text: 'PMG' },
      { item_text: 'CM/MIN (RTH)' },
      { item_text: 'PM laid foundation' },

    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_text',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: false,

    };

    ////////////////////// DropDown ////////////////////////////
    this.admin.getProjectList().subscribe((res) => {
      this.projectList.push(...res);


      this.projectList = this.projectList.sort(function (a, b) {
        if (a.project_name.toLowerCase() < b.project_name.toLowerCase()) { return -1; }
        if (a.project_name.toLowerCase() > b.project_name.toLowerCase()) { return 1; }
        return 0;
      })
      // console.log(this.projectList);
    });

    // 1. Project => State
    this.filterForm.get("project").valueChanges.subscribe((res) => {
      // this.projectID = res.id;
      this.projectName = res.project_name;
      // this.filterForm.controls['state'].reset()
      console.log(res, "project d")

      // this.projectService.getProjectWiseStates(this.projectID).subscribe(
      //   (res) => {
      //     this.stateList = [];
      //     this.stateList.push(...res);
      //     console.log(this.stateList);
      //   },
      //   (err) => {
      //     alert("Please select valid value!");
      //   }
      // );
    });

    // 1.In private Project => State
    //  this.privateForm.get("pnproject").valueChanges.subscribe((res) => {
    //   this.projectIDPN = res.id;
    //   this.projectNamePN = res.project_name;

    //   this.projectService.getProjectWiseStates(this.projectIDPN).subscribe(
    //     (res) => {
    //       this.stateListPN = [];
    //       this.stateListPN.push(...res);
    //       // console.log(this.stateList);
    //     },
    //     (err) => {
    //       alert("Please select valid value!");
    //     }
    //   );
    // });

    // 2. Project => State
    this.districtForm.get("project").valueChanges.subscribe((res) => {
      this.projectID = res.id;
      this.projectName = res.project_name;

      this.projectService.getProjectWiseStates(this.projectID).subscribe(
        (res) => {
          this.stateList = [];
          this.stateList.push(...res);
          // console.log(this.stateList);
        },
        (err) => {
          alert("Please select valid value!");
        }
      );
    });

    // 1. Project => State => District
    // this.filterForm.get("state").valueChanges.subscribe((res) => {
      // this.stateID = res.id;
      // this.stateName = res.state;
      // this.filterForm.controls['district'].reset()
      // if (res=="Select State") {
      //   this.buttonDisable = true
      // }
      // else{
      //   this.buttonDisable = false
      // }
      // console.log(res);
      // this.projectService
      //   .getStateWiseDistricts(this.projectID, this.stateID)
      //   .subscribe((res) => {
      //     console.log(res,"dist");
      //     this.districtList = [];
      //     this.districtList.push(...res);
      //   });
    // });

    // 1.In private Project => State => District
    // this.privateForm.get("pnstate").valueChanges.subscribe((res) => {
    //   this.stateIDPN = res.id;
    //   this.stateNamePN = res.state;
    //   // console.log(res);
    //   this.projectService
    //     .getStateWiseDistricts(this.projectIDPN, this.stateIDPN)
    //     .subscribe((res) => {
    //       console.log(res);
    //       this.districtListPN = [];
    //       this.districtListPN.push(...res);
    //     });
    // });

    // 2. Project => State => District
    this.districtForm.get("state").valueChanges.subscribe((res) => {
      this.stateID = res.id;
      this.stateName = res.state;
      // console.log(res);
      this.projectNotificationService
        .districtListByProjectAndState(this.projectID, this.stateID)
        .subscribe((res) => {
          console.log(res);
          this.districtList = [];
          this.districtList.push(...res);
        });
    });

    //1. Project => State => District => Tehsil
    this.filterForm.get("district").valueChanges.subscribe((res) => {
      // this.districtID = res.id;
      this.districtName = res.district;
      // if (res=="Select District") {
      //   this.buttonDisable = true
      // } else{
      //   this.buttonDisable = false
      // }
      // console.log(res);
      // this.projectService
      //   .getDistrictWiseTehsil(this.projectID, this.districtID)
      //   .subscribe((res) => {
      //     console.log(res,"tehsil")
      //     this.tehsilList = [];
      //     this.tehsilList.push(...res);
      //   });
    });

    //1. In private  Project => State => District => Tehsil
    // this.privateForm.get("pndistrict").valueChanges.subscribe((res) => {
    //   this.districtIDPN = res.id;
    //   this.districtNamePN = res.district;
    //   // console.log(res);
    //   this.projectService
    //     .getDistrictWiseTehsil(this.projectIDPN, this.districtIDPN)
    //     .subscribe((res) => {
    //       this.tehsilListPN = [];
    //       this.tehsilListPN.push(...res);
    //     });
    // });

    //2. Project => State => District
    this.districtForm.get("district").valueChanges.subscribe((res) => {
      console.log(res);
      this.districtID = res.id;
      this.districtName = res.district;
    });

    // Project => State => District => Tehsil => Village
    this.filterForm.get("tehsil").valueChanges.subscribe((res) => {
      // this.tehsilID = res.id;
      this.tehsilName = res.tehsil;
      // if (res=="Select Tehsil") {
      //   this.buttonDisable = true
      // } else{
      //   this.buttonDisable = false
      // }
      // this.projectService
      //   .getTehsilWiseVillages(this.projectID, this.tehsilID)
      //   .subscribe((res) => {
      //     console.log(res);
      //     this.villageList = [];
      //     this.villageList.push(...res);
      //   });
    });

    //In private Project => State => District => Tehsil => Village
    // this.privateForm.get("pntehsil").valueChanges.subscribe((res) => {
    //   this.tehsilIDPN = res.id;
    //   this.tehsilNamePN = res.tehsil;
    //   this.projectService
    //     .getTehsilWiseVillages(this.projectIDPN, this.tehsilIDPN)
    //     .subscribe((res) => {
    //       console.log(res);
    //       this.villageListPN = [];
    //       this.villageListPN.push(...res);
    //     });
    // });

    //  Project => State => District => Tehsil => Village => Project Progress Form
    this.filterForm.get("village").valueChanges.subscribe((res) => {
      console.log(res);
      // this.village_id = res.id;
      this.villageName = res.village;
      // if (res=="Select Village") {
      //   this.buttonDisable = true
      // } else{
      //   this.buttonDisable = false
      // }
      this.projectService.villageID.next(res.id);
    });

    //In private  Project => State => District => Tehsil => Village => Project Progress Form
    //  this.privateForm.get("pnvillage").valueChanges.subscribe((res) => {
    //   console.log(res);
    //   this.village_id = res.id;
    //   this.villageNamePN = res.village;
    //   this.projectService.villageID.next(res.id);
    // });


    //////////////////// CALA LIST ////////////////////////////////////
    this.projectNotificationService.getCalaNames().subscribe((res) => {
      this.calaList.push(...res);
      // console.log(this.calaList)
      this.calaList = this.calaList.sort(function (a, b) {
        if (a.cala_name.toLowerCase() < b.cala_name.toLowerCase()) { return -1; }
        if (a.cala_name.toLowerCase() > b.cala_name.toLowerCase()) { return 1; }
        return 0;
      })
      // console.log(this.calaList);
    });

    this.calaFundForm.get("calaName").valueChanges.subscribe((res) => {
      this.calaID = res;
      this.getCalaNameFromId(this.calaList, this.calaID);
    });
  }

  projectSelection(pro) {
    console.log((pro), "pro");
    let proname = this.projectList.filter(p => p.id == pro);
    console.log(proname, "proname");
    this.projectName = proname[0]['project_name'];
    // console.log(this.projectName)
    this.projectID = pro;
    // this.filterForm.controls['state'].reset()
    this.filterForm.controls['district'].reset()
    this.filterForm.controls['tehsil'].reset()
    this.filterForm.controls['village'].reset()
    this.projectService.getProjectWiseStates(pro).subscribe(
      (res) => {
        this.stateList = [];
        this.stateList.push(...res);
        console.log(this.stateList);
        this.stateSelection(1)
      },
      (err) => {
        alert("Please select valid value!");
      }
    );
  }


  stateSelection(stateid) {
    console.log(stateid, "statename");

    if (stateid == "seletedState") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.stateID = stateid
      let statename = this.stateList.filter(p => p.id == stateid);
      this.stateName = statename[0]['state'];
      console.log(statename, "statename")
      this.filterForm.controls['district'].reset()
      this.filterForm.controls['tehsil'].reset()
      this.filterForm.controls['village'].reset()
      this.projectService
        .getStateWiseDistricts(this.projectID, this.stateID)
        .subscribe((res) => {
          console.log(res, "dist");
          this.districtList = [];
          this.districtList.push(...res);
        });
    }

  }

  distSelection(distId) {
    if (distId == "seletedDis") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.districtID = distId
      let distName = this.districtList.filter(p => p.id == distId);
      this.districtName = distName[0]['district']
      console.log(distName, "distName")
      this.filterForm.controls['tehsil'].reset()
      this.filterForm.controls['village'].reset()
      this.projectService
        .getDistrictWiseTehsil(this.projectID, this.districtID)
        .subscribe((res) => {
          console.log(res, "tehsil")
          this.tehsilList = [];
          this.tehsilList.push(...res);
        });
    }

  }

  tehsilSelection(tehId) {
    if (tehId == "seletedTeh") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.filterForm.controls['village'].reset()
      let tehName = this.tehsilList.filter(p => p.id == tehId);
      this.tehsilName = tehName[0]['tehsil']
      console.log(tehName, "tehName")
      this.tehsilID = tehId;
      this.projectService
        .getTehsilWiseVillages(this.projectID, this.tehsilID)
        .subscribe((res) => {
          console.log(res);
          this.villageList = [];
          this.villageList.push(...res);
        });
    }

  }

  villSelection(villId) {
    if (villId == "seletedVill") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.village_id = villId
      let villName = this.villageList.filter(p => p.id == villId);
      this.villageName = villName[0]['village']
      console.log(villName, "vilName")
      this.projectService.villageID.next(this.village_id);
    }

  }
  // Private negotiation form
  projectSelectionPN(pro) {
    console.log((pro), "pro");
    let proname = this.projectList.filter(p => p.id == pro);
    console.log(proname, "proname");
    this.projectNamePN = proname[0]['project_name'];
    // console.log(this.projectName)
    this.projectIDPN = pro;
    // this.privateForm.controls['pnstate'].reset()
    this.privateForm.controls['pndistrict'].reset()
    this.privateForm.controls['pntehsil'].reset()
    this.privateForm.controls['pnvillage'].reset()
    this.projectService.getProjectWiseStates(pro).subscribe(
      (res) => {
        this.stateListPN = [];
        this.stateListPN.push(...res);
        console.log(this.stateListPN);
        this.stateSelectionPN(1)
      },
      (err) => {
        alert("Please select valid value!");
      }
    );
  }


  stateSelectionPN(stateid) {
    if (stateid == "seletedState") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.stateIDPN = stateid
    let statename = this.stateListPN.filter(p => p.id == stateid);
    this.stateNamePN = statename[0]['state'];
    console.log(statename, "statename")
    this.privateForm.controls['pndistrict'].reset()
    this.privateForm.controls['pntehsil'].reset()
    this.privateForm.controls['pnvillage'].reset()
    this.projectService
      .getStateWiseDistricts(this.projectIDPN, this.stateIDPN)
      .subscribe((res) => {
        console.log(res, "dist");
        this.districtListPN = [];
        this.districtListPN.push(...res);
      });
    }
    
  }

  distSelectionPN(distId) {
    if (distId == "seletedDis") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.districtIDPN = distId
      let distName = this.districtListPN.filter(p => p.id == distId);
      this.districtNamePN = distName[0]['district']
      console.log(distName, "distName")
      this.privateForm.controls['pntehsil'].reset()
      this.privateForm.controls['pnvillage'].reset()
      this.projectService
        .getDistrictWiseTehsil(this.projectIDPN, this.districtIDPN)
        .subscribe((res) => {
          console.log(res, "tehsil")
          this.tehsilListPN = [];
          this.tehsilListPN.push(...res);
        });
    }
   
  }

  tehsilSelectionPN(tehId) {
    if (tehId == "seletedTeh") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.privateForm.controls['pnvillage'].reset()
      let tehName = this.tehsilListPN.filter(p => p.id == tehId);
      this.tehsilNamePN = tehName[0]['tehsil']
      console.log(tehName, "tehName")
      this.tehsilIDPN = tehId;
      this.projectService
        .getTehsilWiseVillages(this.projectIDPN, this.tehsilIDPN)
        .subscribe((res) => {
          console.log(res);
          this.villageListPN = [];
          this.villageListPN.push(...res);
        });
    }
   
  }

  villSelectionPN(villId) {
    if (villId == "seletedVill") {
      this.buttonDisable = true
    }
    else {
      this.buttonDisable = false;
      this.village_id = villId
    let villName = this.villageListPN.filter(p => p.id == villId);
    this.villageNamePN = villName[0]['village']
    console.log(villName, "vilName")
    this.projectService.villageID.next(this.village_id);
    }
    
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items.item_text);
  }

  addNewProgressPage() {
    let monitoringUnder = '';
    for (let index = 0; index < this.addPriority.value.monitoring.length; index++) {
      monitoringUnder = this.addPriority.value.monitoring[index].item_text + "," + monitoringUnder

    }

    let bodyData = {
      project: this.addPriority.value.project,
      priority: this.addPriority.value.priority,
      stage: this.addPriority.value.stage,
      progress: this.addPriority.value.progress,
      monitoring: monitoringUnder
    }
    console.log(bodyData,"bodyData of add progress")
    this.newProgressService.addNewProgressData(bodyData).subscribe((res) => {
      console.log(res, "adding priority response");
      this.addPriority.reset();
      const successMessage = "Data Posted successfully!";
      this.notification.showNotification(
        "top",
        "right",
        "success",
        successMessage
      );
    },
      (err) => {
        console.log(err, "error response")
        const successMessage = "Data already added in this Project ";
        this.notification.showNotification(
          "top",
          "right",
          "error",
          successMessage
        );
      }
    )


  }

  getCalaNameFromId(arr, id) {
    let cala_name = arr.filter((el) => {
      return el.cala_id == id;
    });
    this.calaName = cala_name[0].cala_name;
  }

  progressPage() {
    const projectInfo = {
      projectName: this.projectName,
      stateName: this.stateName,
      districtName: this.districtName,
      tehsilName: this.tehsilName,
      villageName: this.villageName,
    };
    localStorage.setItem("projectID", this.projectID);
    localStorage.setItem("village_id", this.village_id);
    localStorage.setItem("project_info", JSON.stringify(projectInfo));
    this.router.navigate(["/project-progress"]);
  }

  //  add project progress by private negotiation

  progressPagePrivate() {
    const projectInfo = {
      projectName: this.projectNamePN,
      stateName: this.stateNamePN,
      districtName: this.districtNamePN,
      tehsilName: this.tehsilNamePN,
      villageName: this.villageNamePN,
    };
    localStorage.setItem("projectID", this.projectIDPN);
    localStorage.setItem("village_id", this.village_id);
    localStorage.setItem("project_info", JSON.stringify(projectInfo));
    this.router.navigate(["/private-negotiation-project-progress"]);
  }

  onSubmitCalaForm() {
    this.projectNotificationService.calaDetailForFundsManagement.next(
      this.calaID
    );
    this.projectNotificationService.calaName.next(this.calaName);
    localStorage.setItem("calaID", this.calaID);
    localStorage.setItem("calaName", this.calaName);
    this.router.navigate(["/cala-fund-management"]);
  }

  districtProgress() {
    const projectDetail = {
      projectName: this.projectName,
      stateName: this.stateName,
      districtName: this.districtName,
    };
    localStorage.setItem("project_detail", JSON.stringify(projectDetail));
    localStorage.setItem("districtData_id", this.districtID);
    this.router.navigate(["/district-progress"]);
  }
}
