import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../services/notification.service";
import { ProjectService } from "../../services/project.service";
import { TotallandAcquistionModelComponent } from "./modal/totalLandacquistion.component";

@Component({
  selector: "app-private-negotiation",
  templateUrl: "private-negotiation.component.html",
  styleUrls: ["private-negotiation.component.css"],
})
export class PrivateNegotiationComponent implements OnInit {
  villageid;
  actualPNdataId;
  fundDepositedataId;
  fundpaiddataId;

  ////////////////// Form Groups ////////////////////////
  form_PN: FormGroup;
  form_actual_PN: FormGroup;
  form_fund_deposited: FormGroup;
  form_fund_paid: FormGroup;

  ////////// Project Information ///ks/////////
  projectInfo: any;

  dataActualPN = [];
  datafundDeposition = [];
  datafundpaid = [];
  oldVillageDetail = [];
  oldVillageDetail2 = [];

  editActualPnData: boolean = false;
  editfunddeposite: boolean = false;
  editfundpaid: boolean = false;
  showFormfield: boolean = true;

  // file upload
  fileToUpload: File | null = null;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private projectService: ProjectService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.villageid = localStorage.getItem("village_id");

    ///////////////////// Getting Table Data ////////////////////////
    this.retreiveTableData();

    this.form_PN = this.fb.group({
      total_area_aquired_pn: new FormControl(null, Validators.required),
      total_compensation_pn: new FormControl(null, Validators.required),
    });
    this.form_actual_PN = this.fb.group({
      actual_area_aquired: new FormControl(null, Validators.required),
      actual_area_aquired_date: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });
    this.form_fund_deposited = this.fb.group({
      pn_fund_deposition: new FormControl(null, Validators.required),
    });
    this.form_fund_paid = this.fb.group({
      pn_fund_distribution: new FormControl(null, Validators.required),
    });
  }

  // file upload logic

  handleFileInput(files: FileList) {
    this.fileToUpload = files[0];
    console.log(this.fileToUpload, "what is this");
  }

  retreiveTableData() {
    this.projectService.getPNactualarea(this.villageid).subscribe((res) => {
      console.log(res);
      this.dataActualPN.push(...res);
    });
    this.projectService.getFundDeposition(this.villageid).subscribe((res) => {
      console.log(res);
      this.datafundDeposition.push(...res);
    });
    this.projectService.getFundpaid(this.villageid).subscribe((res) => {
      console.log(res);
      this.datafundpaid.push(...res);
    });
    this.projectService.retriveVilldetails(this.villageid).subscribe((res) => {
      this.oldVillageDetail2 = res;
      console.log(this.oldVillageDetail, "old");
    });
    this.projectService
      .getIndividualVillageDetail(this.villageid)
      .subscribe((res) => {
        this.oldVillageDetail = res;
        console.log(this.oldVillageDetail, "old");
      });
  }

  //  on save of form_PN form

  totallandacquired() {
    console.log(this.form_PN.value, "form value");
    this.projectService
      .updateCompletionStatus(this.villageid, this.form_PN.value)
      .subscribe((res) => {
        console.log(res, "total add data response response");
        this.projectService
          .getIndividualVillageDetail(this.villageid)
          .subscribe((res) => {
            this.oldVillageDetail = res;
            console.log(this.oldVillageDetail, "villages data");
            this.showFormfield = true;
            this.notification.showNotification(
              "top",
              "right",
              "success",
              "Data Posted successfully"
            );
          });
      });
  }

  edittotalData() {
    this.form_PN.patchValue({
      total_area_aquired_pn: this.oldVillageDetail["total_area_aquired_pn"],
      total_compensation_pn: this.oldVillageDetail["total_compensation_pn"],
    });
    this.showFormfield = false;
  }

  // Actual PN add edit retrive delete api integration

  actuallandacquired() {
    // let bodyData = [{
    //     actual_area_aquired: this.form_actual_PN.value.actual_area_aquired,
    //     actual_area_aquired_date: this.form_actual_PN.value.actual_area_aquired_date,
    //     village: parseInt(this.villageid)
    // }]
    let bodyData = new FormData();
    bodyData.append(
      "actual_area_aquired",
      this.form_actual_PN.value.actual_area_aquired
    ),
      bodyData.append(
        "actual_area_aquired_date",
        this.form_actual_PN.value.actual_area_aquired_date
      ),
      bodyData.append("village", this.villageid);
    bodyData.append("document", this.fileToUpload);
    this.projectService.postPNactualarea(bodyData).subscribe((res) => {
      console.log(res);
      this.projectService
        .getIndividualVillageDetail(this.villageid)
        .subscribe((res) => {
          this.oldVillageDetail = res;
          console.log(this.oldVillageDetail, "villages data");
          this.projectService.getPNactualarea(this.villageid).subscribe((res) => {
            console.log(res);
            this.dataActualPN =res;
          });
          // this.showFormfield=true;
        });
      // this.dataActualPN.push(...res);
      this.form_actual_PN.reset();
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "Data Posted successfully"
      );
    });
  }

  editactualPNData(id) {
    this.actualPNdataId = id;
    this.editActualPnData = true;
    this.projectService
      .retrivePNactualarea(this.actualPNdataId)
      .subscribe((res) => {
        console.log(res);
        this.form_actual_PN.patchValue({
          actual_area_aquired: res.actual_area_aquired,
          actual_area_aquired_date: res.actual_area_aquired_date,
        });
      });
  }

  onUpdateactualPNData() {
    let data = {
      actual_area_aquired: this.form_actual_PN.value.actual_area_aquired,
      actual_area_aquired_date:
        this.form_actual_PN.value.actual_area_aquired_date,
      village: this.villageid,
    };
    this.projectService
      .updatePNactualarea(this.actualPNdataId, data)
      .subscribe((res) => {
        console.log(res, "update res");
        this.projectService.getPNactualarea(this.villageid).subscribe((res) => {
          // console.log(res)
          this.projectService
            .getIndividualVillageDetail(this.villageid)
            .subscribe((res) => {
              this.oldVillageDetail = res;
              console.log(this.oldVillageDetail, "villages data");
              // this.showFormfield=true;
            });
          this.dataActualPN = [];
          this.dataActualPN.push(...res);
          this.form_actual_PN.reset();
          this.editActualPnData = false;
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data Updated successfully"
          );
        });
      });
  }

  deleteactualPNData(id) {
    this.projectService.deletePNactualarea(id).subscribe((res) => {
      console.log(res, "delete res");
      this.projectService.getPNactualarea(this.villageid).subscribe((res) => {
        // console.log(res)
        this.projectService
          .getIndividualVillageDetail(this.villageid)
          .subscribe((res) => {
            this.oldVillageDetail = res;
            console.log(this.oldVillageDetail, "villages data");
            // this.showFormfield=true;
          });
        this.dataActualPN = [];
        this.dataActualPN.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Deleted successfully"
        );
      });
    });
  }

  //End Of Actual PN add edit retrive delete api integration

  //start of Fund deposited with CALA under PN api integration

  onAddfundDeposite() {
    let bodyData = 
      {
        pn_fund_deposition: this.form_fund_deposited.value.pn_fund_deposition,
        village: parseInt(this.villageid),
      }
    ;
    this.projectService.postFundDeposition(bodyData).subscribe((res) => {
      console.log(res, "add fund deposition res");
      this.projectService
        .getIndividualVillageDetail(this.villageid)
        .subscribe((res) => {
          this.oldVillageDetail = res;
          console.log(this.oldVillageDetail, "villages data");
          // this.showFormfield=true;
        });
        // this.datafundDeposition = []
        this.projectService.getFundDeposition(this.villageid).subscribe((res) => {
          console.log(res);
          this.datafundDeposition=res;
        });
      // this.datafundDeposition.push(...res);
      this.form_fund_deposited.reset();
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "Data Posted successfully"
      );
    });
  }

  editfundDepositeData(id) {
    this.fundDepositedataId = id;
    this.projectService
      .retrivefundDeposite(this.fundDepositedataId)
      .subscribe((res) => {
        console.log(res);
        this.editfunddeposite = true;
        this.form_fund_deposited.patchValue({
          pn_fund_deposition: res.pn_fund_deposition,
        });
      });
  }

  onUpdatefundDepositeData() {
    let data = {
      pn_fund_deposition: this.form_fund_deposited.value.pn_fund_deposition,
      village: parseInt(this.villageid),
    };
    this.projectService
      .updatefundDeposite(this.fundDepositedataId, data)
      .subscribe((res) => {
        console.log(res, "update res");
        this.editfunddeposite = false;
        this.projectService
          .getFundDeposition(this.villageid)
          .subscribe((res) => {
            // console.log(res);
            this.projectService
              .getIndividualVillageDetail(this.villageid)
              .subscribe((res) => {
                this.oldVillageDetail = res;
                console.log(this.oldVillageDetail, "villages data");
                this.showFormfield = true;
              });
            this.datafundDeposition = [];
            this.datafundDeposition.push(...res);
            this.form_fund_deposited.reset();
            // this.editActualPnData = false;
            this.notification.showNotification(
              "top",
              "right",
              "success",
              "Data Updated successfully"
            );
          });
      });
  }

  deletefundDepositeData(id) {
    this.projectService.deletefundDeposite(id).subscribe((res) => {
      console.log(res, "delete res");
      this.projectService.getFundDeposition(this.villageid).subscribe((res) => {
        // console.log(res);
        this.projectService
          .getIndividualVillageDetail(this.villageid)
          .subscribe((res) => {
            this.oldVillageDetail = res;
            console.log(this.oldVillageDetail, "villages data");
            // this.showFormfield=true;
          });
        this.datafundDeposition = [];
        this.datafundDeposition.push(...res);
        // this.form_fund_deposited.reset();
        // this.editActualPnData = false;
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Deleted successfully"
        );
      });
    });
  }

  //end of Fund deposited with CALA under PN api integration

  //start of  Fund paid to land owners under PN api integration

  onAddfundpaid() {
    let bodyData = 
      {
        pn_fund_distribution: this.form_fund_paid.value.pn_fund_distribution,
        village: parseInt(this.villageid),
      }
    ;
    this.projectService.postFundpaid(bodyData).subscribe((res) => {
      console.log(res, "add fund deposition res");
      // this.datafundpaid.push(...res);
      this.projectService
        .getIndividualVillageDetail(this.villageid)
        .subscribe((res) => {
          this.oldVillageDetail = res;
          console.log(this.oldVillageDetail, "villages data");
          // this.showFormfield=true;
        });
        this.projectService.getFundpaid(this.villageid).subscribe((res) => {
          console.log(res);
          this.datafundpaid=res;
        });
      this.form_fund_paid.reset();
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "Data Posted successfully"
      );
    });
  }

  editfundpaidData(id) {
    this.fundpaiddataId = id;
    this.projectService
      .retrivefundpaid(this.fundpaiddataId)
      .subscribe((res) => {
        console.log(res);
        this.editfundpaid = true;
        this.form_fund_paid.patchValue({
          pn_fund_distribution: res.pn_fund_distribution,
        });
      });
  }

  onUpdatefundpaidData() {
    let data = {
      pn_fund_distribution: this.form_fund_paid.value.pn_fund_distribution,
      village: parseInt(this.villageid),
    };
    this.projectService
      .updatefundpaid(this.fundpaiddataId, data)
      .subscribe((res) => {
        console.log(res, "update res");
        this.editfundpaid = false;
        this.projectService.getFundpaid(this.villageid).subscribe((res) => {
          console.log(res);
          this.projectService
            .getIndividualVillageDetail(this.villageid)
            .subscribe((res) => {
              this.oldVillageDetail = res;
              console.log(this.oldVillageDetail, "villages data");
              // this.showFormfield=true;
            });
          this.datafundpaid = [];
          this.form_fund_paid.reset();
          this.datafundpaid.push(...res);
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data Updated successfully"
          );
        });
      });
  }

  deletefundpaidData(id) {
    this.projectService.deletefundpaid(id).subscribe((res) => {
      console.log(res, "delete res");
      this.projectService.getFundpaid(this.villageid).subscribe((res) => {
        console.log(res);
        this.projectService
          .getIndividualVillageDetail(this.villageid)
          .subscribe((res) => {
            this.oldVillageDetail = res;
            console.log(this.oldVillageDetail, "villages data");
            // this.showFormfield=true;
          });
        this.datafundpaid = [];
        this.form_fund_paid.reset();
        this.datafundpaid.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Deleted successfully"
        );
      });
    });
  }

  //end of  Fund paid to land owners under PN api integration

  ngAfterContentInit() {
    const projectInformation = localStorage.getItem("project_info");
    this.projectInfo = JSON.parse(projectInformation);
    console.log(this.projectInfo);
  }

  // open dialbox

  openJMSDialog(): void {
    // this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(TotallandAcquistionModelComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      //   this.hideForm();
    });
  }
}
