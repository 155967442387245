import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";

import { LoginResponseData } from "../shared/loginResponse.model";
import { UserLoginModel } from "../shared/userLogin.model";
import { NotificationService } from "./notification.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnInit {
  serverUrl: string = environment.baseUrl;

  jwtHelper = new JwtHelperService();
  public isUserLoggedIn: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // public userLoginStatus: BehaviorSubject<boolean> =
  //   new BehaviorSubject<boolean>(null);

  userName: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {}

  userRegister(data) {
    return this.http.post<any>("", data);
  }

  userLogin(user: UserLoginModel): Observable<boolean> {
    if (user && user.email && user.password) {
      return this.http
        .post<LoginResponseData>(
          `http://${this.serverUrl}/api/account/login`,
          user
        )
        .pipe(
          map((data: any) => {
            if (!data.token) {
              return false;
            }
            console.log(data);
            localStorage.setItem("designation", data.designation);
            localStorage.setItem("access_token", data.token);
            const decodedUser = this.jwtHelper.decodeToken(data.token);
            localStorage.setItem("expiration", decodedUser.exp);
            //Sending user's login status via behaivior subject
            this.isUserLoggedIn.next(true);
            // this.userName.next(data.name);
            localStorage.setItem("userName", data.name);
            return true;
          })
        );
    }
    return of(false);
  }

  userLogout() {
    localStorage.clear();
    this.notification.showNotification(
      "top",
      "right",
      "info",
      "You are Logged out!"
    );
    this.isUserLoggedIn.next(false);
  }

  registerDevice(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/push-message/register/devices/`,
      data
    );
  }
}
