import { filter } from "rxjs/operators";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  DoCheck,
} from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PopStateEvent,
} from "@angular/common";

import { NavbarComponent } from "../../components/navbar/navbar.component";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import PerfectScrollbar from "perfect-scrollbar";
import { AuthService } from "../../services/auth.service";
import { NavbarService } from "../../components/navbar/navbar.service";

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit, DoCheck {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  isAuthenticated: boolean = true;
  isShowing

  constructor(
    public location: Location,
    private router: Router,
    private auth: AuthService,
    private nav:NavbarService
  ) {}

  ngOnInit() {
    this.isShowing=this.nav.showOrHide
    /////////////// Checking access token //////////////////////////
    // const token = localStorage.getItem("access_token");
    // if (token) {
    //   this.isAuthenticated = !this.isAuthenticated;
    //   console.log(token);
    //   console.log(this.isAuthenticated);
    // }
    /////////////// Checking access token //////////////////////////

    const isWindows = navigator.platform.indexOf("Win") > -1 ? true : false;
    if (
      isWindows &&
      !document
        .getElementsByTagName("body")[0]
        .classList.contains("sidebar-mini")
    ) {
      // if we are on windows OS we activate the perfectScrollbar function

      document
        .getElementsByTagName("body")[0]
        .classList.add("perfect-scrollbar-on");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("perfect-scrollbar-off");
    }
    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        // elemSidebar.scrollTop = 0;
      });
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      // ps = new PerfectScrollbar(elemSidebar);
    }
  }

  ngDoCheck() {
    this.auth.isUserLoggedIn.subscribe((val) => {
      this.isAuthenticated = val;
    });
    // console.log(this.isAuthenticated);
    const token = localStorage.getItem("access_token");
    if (token) {
      this.isAuthenticated = true;
      // console.log(token);
      // console.log(this.isAuthenticated);
    }
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }
  isMaps(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path == titlee) {
      return false;
    } else {
      return true;
    }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
}
