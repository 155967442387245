import { Component, OnInit, DoCheck } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { NavbarService } from "../navbar/navbar.service";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  
  // {
  //   path: "/dashboard",
  //   title: "Dashboard",
  //   icon: "design_app",
  //   class: "",
  // },
  // {
  //   path: "/cala-lists",
  //   title: "CALA Dashboard",
  //   icon: "design_app",
  //   class: "",
  // },
  // {
  //   path: "/projects-dashboard",
  //   title: "Projects Dashboard",
  //   icon: "ui-1_send",
  //   class: "",
  // },
  {
    path: "/create-project",
    title: "Create Project",
    icon: "design-2_ruler-pencil",
    class: "",
  },
  
  {
    path: "/edit-project",
    title: "Edit Project",
    icon: "design_vector",
    class: "abcd",
  },
  {
    path: "/filter-project",
    title: "Add Progress",
    icon: "education_agenda-bookmark",
    class: "",
  },
  {
    path: "/change-deadlines",
    title: "Change Deadline",
    icon: "loader_gear",
    class: "",
  },
  // {
  //   path: "/create-user",
  //   title: "Create User",
  //   icon: "business_badge",
  //   class: "",
  // },
  {
    path: "/add-data",
    title: "Add Data",
    icon: "objects_planet",
    class: "",
  },
  {
    path: "/delete-village",
    title: "Delete Village",
    icon: "business_bank",
    class: "",
  },
  // {
  //   path: "/activity",
  //   title: "Activity",
  //   icon: "ui-2_settings-90",
  //   class: "",
  // },
];


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit, DoCheck {
  menuItems: any[];
  desiganation:any;
  loggedInUsername;

  public isUserLoggedIn: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  constructor(private nav:NavbarService, private router: Router,
    private auth: AuthService,) {}

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.desiganation=localStorage.getItem("designation");
    this.loggedInUsername = localStorage.getItem("userName");
  }
  toggle(){
    this.nav.showOrHide=true
  }

  ngDoCheck() {}
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
  onLogout() {
    this.isUserLoggedIn.next(false);
    this.auth.userLogout();
    this.router.navigate(["/login"]);
  }
  
}
