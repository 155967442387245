import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { ReportsService } from "../../../services/reports.service";
import { DashboardService } from "../../../services/dashboard.service";
import { ProjectDetailModel } from "../../../shared/dashboradData.model";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-project-report-dashboard",
  templateUrl: "./dashboard-report.component.html",
  styleUrls: ["./dashboard-report.component.css"],
})
export class DashboardReportComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  displayedColumns: string[] = [
    "Village Name",
    "District Name",
    "Total land to be aquired",
    "Start Date",
    "3a status",
    "JMS request status",
    "JMS",
    "3A",
    "3C",
    "3D",
    "3G",
    "3H",
    "3H funds distributed",
    "Possession",
    "Mutation",
    "Govt. land to be aquired",
    "Govt. Land Possession",
    "Govt. Land Mutation",
    "Total Area to be acquired by PN",
    "Area Actually acquired by PN",
    "Total Private fund disbursed",
    "Funds Diposition Under PN",
    "Funds Distribution Under PN",
    
  ];
  public dataSource = new MatTableDataSource<any>();
  public dataSource2:any=[];
  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  id: string = "";
  projectName: string = "";
  toBeCompleted: any;
  completedTillNow: any;
  inPercentage: any;
  overviewData: any;
  pnDetails: any;
  graphData: object;
  totalProjects: number;
  concernedPersons: any;
  date: Date;
  fivedataArray = [];
  tendataarray = [];
  
  
  twentyTableData=[];
  totalVillagesList=[];
  arrayOfTwentyData=[];
  array=[];

  day: number = 5;
  colors = ["#E59866", "#BFF68A"];
  flag=true;
  dateTobecompleted;
  jmsvalue;
  avalue;
  newProgressDetails;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private reportsService: ReportsService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.date = new Date

    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      this.projectName = params.get("name");
    });
    this.dashboardService.getSingleProjectDetailsReport(this.id).subscribe((res) => {
      this.totalVillagesList=res.details;
      this.newProgressDetails= res.new_progress_details
      console.log(res,"response details")
      this.array=res.details;
      this.array.sort(function (a, b) {
        if (a.village_name.toLowerCase() < b.village_name.toLowerCase()) {
          return -1
        }
        if (a.village_name.toLowerCase() > b.village_name.toLowerCase()) {
          return 1
        }
        return 0;
      })

      

      // console.log(this.totalVillagesList, "jabhjahujah");
      
      // for (let index = 0; index < res.details.length; index++) {

      //   if (index < 6) {
      //     this.fivedataarray.push(res.details[index]);
      //     console.log(this.fivedataarray, "shiuhhuhhuiyhuiyi")
      //   }
      //   if (index >= 6 && index < 40) {
      //     this.tendataarray.push(res.details[index]);
      //     console.log(this.tendataarray, "shiuhhuhhuiyhuiyi")
      //   }

      // };

      // this.slice=res.details.slice(0,5);

      //  ******* Dynamic generation of table for providing page split********  //

      while (this.totalVillagesList.length>0) {
        // console.log(this.flag,"flag")
        if (this.flag==true) {
          // console.log(res.name.length,"name length")
          this.fivedataArray=this.totalVillagesList.slice(0,10)
          this.totalVillagesList=this.totalVillagesList.slice(10)
          this.flag=false;
          // console.log(this.fivedataArray,"slice one")
        } 
       else {
        this.twentyTableData=this.totalVillagesList.slice(0,16);
        this.arrayOfTwentyData.push(this.twentyTableData);
        // console.log(this.arrayOfTwentyData,"multysyys")
        this.totalVillagesList=this.totalVillagesList.slice(16);
        // console.log(this.twentyTableData,"slice 2")
       }
      }

      // console.log(this.slice," slice data");
      this.projectName = res.name;
      this.pnDetails=res.PN_details
      this.concernedPersons = res.officers_list;
      this.overviewData = res.overview;
      this.toBeCompleted = res.overview.to_be_completed;
      this.completedTillNow = res.overview.total_completed_till_now;
      this.inPercentage = res.percentage;
      this.jmsvalue = res.percentage.percentage_jms_request;
      this.avalue = res.percentage.percentage_3a;
      this.totalProjects = res.details.length;
      this.dataSource.data = this.fivedataArray as ProjectDetailModel[];

      //  logic For multiple data in mat table //

      for (let index = 0; index < this.arrayOfTwentyData.length; index++) {
        // this.dataSource2.data = this.tendataarray as ProjectDetailModel[];
        this.dataSource2.push(new MatTableDataSource<any>());
        this.dataSource2[index].data=this.arrayOfTwentyData[index] as ProjectDetailModel[];
        
      }

     // End Of logic For multiple data in mat table //
      
    });

    setTimeout(() => {
      this.dashboardService.getGraphData(this.id).subscribe((res) => {
        console.log(res);
        this.dateTobecompleted=res;
        // this.dateFormatter(res.start_date);
        //////////////////// High Charts //////////////////////////
        const options: any = {
          chart: {
            type: "bar",
          },
          title: {
            text: `Total Area To Be Acquired: ${res.total_land_aquare} Ha`,
          },
          subtitle: {
            text: `Project Start Date: ${res.start_date
              .split("-")
              .reverse()
              .join("/")}`,
          },
          xAxis: {
            categories: [
              "3a",
              "JMS Request",
              "JMS",
              "3(A)",
              "3(C)",
              "3(D)",
              "3(G)",
              "3(H)",
              "Possession",
              "Mutation",
            ],
          },
          yAxis: {
            min: this.expectedDateFormatter(res.start_date),
            type: "datetime",
            labels: {
              formatter: function () {
                return Highcharts.dateFormat("%d/%m/%y", this.value);
              },
            },
            title: {
              text: "Dates",
            },
          },
          tooltip: {
            enabled: false
            // formatter: function () {
            //   const days = this.y / (1000 * 3600 * 24);
            //   return `${this.series.name}: ${days} days`;
            // },
          },
          plotOptions: {
            series: {
              pointWidth: 15,
              stacking: "true",
              dataLabels: {
                enabled: true,
                // formatter: function () {
                //   var y = this.series.data[4].name
  
                //   const total = res.total_land_aquare;
                //   const pct = (y / total) * 100;
                //   console.log(this.series.data.length,)
                //   return `${pct.toFixed(2)}%`;
                // },
              }
            },
          },
          colors: this.colors,
          series: [
            {
              name: "Delayed",
              dataLabels: [{
                align: 'right',
                x: +60,
                format: '{point.name}'
              }],
              data: [
                {
                  name: this.avalue, y: this.runningDateFormatter(
                    res.date_of_3a_expected,
                    res.date_of_3a_completed || new Date()
                  )
                },
                {
                  name: this.jmsvalue, y: this.runningDateFormatter(
                    res.jms_request_expected,
                    res.jms_request_completed || new Date()
                  )
                },
                {
                  name: ((res.total_jms / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.Jms_expected,
                    res.Jms_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3A / res.total_land_aquare) * 100).toFixed(2) + '%', y: this.runningDateFormatter(
                    res.dates_of_3A_expected,
                    res.dates_of_3A_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3C / res.total_land_aquare) * 100).toFixed(2) + '%', y: this.runningDateFormatter(
                    res.dates_of_3C_expected,
                    res.dates_of_3C_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3D / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_3D_expected,
                    res.dates_of_3D_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3G / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_3G_expected,
                    res.dates_of_3G_completed || new Date()
                  )
                },
                {
                  name: ((res.total_3H_area / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_3H_expected,
                    res.dates_of_3H_completed || new Date()
                  )
                },
                {
                  name: ((res.total_possession / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_possesion_expected,
                    res.dates_possesion_completed || new Date()
                  )
                },
                {
                  name: ((res.total_mutation / res.total_land_aquare) * 100).toFixed(2) + "%", y: this.runningDateFormatter(
                    res.dates_of_mutation_expected,
                    res.dates_of_mutation_completed || new Date()
                  )
                },
              ],
            },
            {
              name: "Scheduled time",
              dataLabels: [{
                align: 'right',
                x: +10,
                format: ''
              }],
              data: [
                {
                  name: '', y: this.dateComparator(
                    res.start_date,
                    res["date_of_3a_expected"],
                    res.date_of_3a_completed || new Date()
                  )
                },
  
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["jms_request_expected"],
                    res.jms_request_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["Jms_expected"],
                    res.Jms_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3A_expected"],
                    res.dates_of_3A_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3C_expected"],
                    res.dates_of_3C_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3D_expected"],
                    res.dates_of_3D_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3G_expected"],
                    res.dates_of_3G_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_3H_expected"],
                    res.dates_of_3H_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_possesion_expected"],
                    res.dates_possesion_completed || new Date()
                  )
                },
                {
                  name: res.total_3D, y: this.dateComparator(
                    res.start_date,
                    res["dates_of_mutation_expected"],
                    res.dates_of_mutation_completed || new Date()
                  )
                },
              ],
            },
          ],
        };
        HC_exporting(Highcharts);
        Highcharts.chart("container", options);
      });
    }, 1000);

    setTimeout(() => {
      this.downloadPageAsPDF();
    }, 5000);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  dateFormatter(date) {
    const dayConvertor = 1000 * 3600 * 24;
    const jsDate = Date.UTC(1970, 1, 1);
    const dateObject = new Date(date);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth();
    const day = dateObject.getUTCDate();
    const dateDifference = Date.UTC(year, month, day) - jsDate;
    const days = dateDifference / dayConvertor;
    console.log(days);

    return dateDifference;
  }

  expectedDateFormatter(date) {
    const initialState = Date.UTC(1970, 0, 1);
    const dateObject = new Date(date);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth();
    const day = dateObject.getUTCDate();

    const returnDate = Date.UTC(year, month, day) - initialState;

    return Date.UTC(year, month, day);
    // return returnDate;
  }

  dateComparator(projectStartDate, expectedDate, completedDate) {
    const start_date = this.expectedDateFormatter(projectStartDate);
    const date1 = this.expectedDateFormatter(expectedDate);
    const date2 = this.expectedDateFormatter(completedDate);
    // console.log(date1, date2);
    if (date2 < date1) {
      // console.log(date2 - start_date);
      return date2;
    } else if (date2 >= date1) {
      // console.log(date1 - start_date);
      return date1;
    }
  }

  runningDateFormatter(startDate, endDate) {
    const startingDate = this.expectedDateFormatter(startDate);
    const endingDate = this.expectedDateFormatter(endDate);
    const difference = endingDate - startingDate;
    const days = difference / (1000 * 3600 * 24);
    Date.prototype["addDays"] = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    // console.log("days", days);
    let date = new Date(startDate);
    // console.log(date["addDays"](days));
    const newDate = date["addDays"](days);
    const date1 = this.expectedDateFormatter(newDate);
    const date2 = this.expectedDateFormatter(startDate);
    const timeDifference = date1 - date2;
    // console.log(date1, date2);
    // console.log(timeDifference);

    return timeDifference;
  }

  ngAfterContentInit() { }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.toLocaleLowerCase();
  };

  downloadReport() {
    this.reportsService.getAllProjectsReport().subscribe((res) => {
      this.isDownloading = true;
      console.log(res);
      this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
        this.isDownloading = false;
        this.isDownloaded = res;
        console.log(res);
      });
    });
  }

  downloadPageAsPDF() {


    let data = document.getElementById("pagePDF");

    html2canvas(data).then((canvas) => {

      var imgWidth = 295;
      var pageHeight = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL("image/png", .01);

      var doc = new jsPDF('l', 'mm', '', true);
      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        // doc.setDisplayMode(1, 'single', 'UseOutlines')
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("exportedPdf.pdf");



      // let docWidth = 208;
      // let docHeight = (canvas.height * docWidth) / canvas.width;
      // const contentDataURL = canvas.toDataURL("image/png");
      // let doc = new jsPDF("p", "mm", "a4");
      // let position = 0;
      // doc.addImage(contentDataURL, "PNG", 0, position, docWidth, docHeight);
      // doc.save("exportedPdf.pdf");
    });
  }

  ngOnDestroy() { }
}
