import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DashboardService } from "../../../services/dashboard.service";
import { MatDialog } from "@angular/material/dialog";
import { DateDialogComponent } from "../../../dashboard/critical-analysis-reports/date-dialog/date-dialog.component";

declare var require: any;

import * as html2pdf from 'html2pdf.js';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import domtoimage from 'dom-to-image';

export interface ProjectListModel {
  project_id: number;
  project_name: string;
}

export interface CalaListModel {
  cala_id: number;
  cala_name: string;
}

@Component({
  selector: "app-critical-report",
  templateUrl: "./criticle-report.component.html",
  styleUrls: ["./criticle-report.component.css"],
})
export class CriticalReportComponent implements OnInit {
  pendingListSubs: Subscription;
  roID: string;
  PIDs: string;

  dashboardData: any;
  projectsList$: ProjectListModel[];
  calaList$: CalaListModel[];

  overallPendingDetails: any;
  cardsDetails: any;
  pending3A = [];
  pending3D = [];
  pending3G = [];
  pending3H = [];
  dialogValue: string;
  date: Date;


  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.date = new Date;
    this.route.paramMap.subscribe((params) => {
      this.roID = params.get("id");
    });
    this.PIDs = this.route.snapshot.queryParamMap.get("id");
    console.log(this.PIDs);

    this.pendingListSubs = this.dashboardService
      .getAllCriticalReports(this.roID, this.PIDs)
      .subscribe((res) => {
        this.pending3A = res["3A"].flat();
        this.pending3D = res["3D"].flat();
        this.pending3G = res["3G"].flat();
        this.pending3H = res["3H_amount"].flat();
        this.cardsDetails = res;
        this.overallPendingDetails = res.details;
      });

    // setTimeout(() => {
    //   this.downloadPDF();
    // }, 5000);


    console.log(document.body, "whta")


  }


  downloadPDF()
  {

    var node = document.getElementById('pagePDF');

    var img;
    var filename;
    var newImage;


    domtoimage.toPng(node, { bgcolor: '#fff' })

      .then(function(dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = function(){

        var pdfWidth = img.width;
        var pdfHeight = img.height;

          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if(pdfWidth > pdfHeight)
          {
            doc = new jsPDF('l', 'px', [pdfWidth , pdfHeight]);
          }
          else
          {
            doc = new jsPDF('p', 'px', [pdfWidth , pdfHeight]);
          }


          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();


          doc.addImage(newImage, 'PNG',  10, 10, width, height);
          filename = 'mypdf_' + '.pdf';
          doc.save(filename);

        };


      })
      .catch(function(error) {

       // Error Handling

      });



  }



  downloadPageAsPDF() {

    // var element = document.getElementById('pagePDF');
    // var opt = {
    //   margin: 1,
    //   filename: 'myfile.pdf',
    //   image: { type: 'PNG', quality: 0.98 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait',compress:true, Fontsize:1 }
    // };

    // New Promise-based usage:
    // html2pdf().set(opt).from(element).save();


    let data = document.getElementById("pagePDF");

    html2canvas(data).then((canvas) => {




      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', 'a4', true);
      var position = 10; // give some top padding to first page

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('file.pdf');



      // var imgWidth = 208;
      // var pageHeight = 297;
      // var imgHeight = canvas.height * imgWidth / canvas.width;
      // var heightLeft = imgHeight;
      // const contentDataURL = canvas.toDataURL("image/png", .01);

      // var doc = new jsPDF('p', 'mm', 'a4', true);

      // var position = 0;
      // doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      // heightLeft -= pageHeight;


      // while (heightLeft >= 0) {
      //   doc.setDrawColor('black'),
      //     doc.setLineWidth(2 / 297)
      //   doc.line(5, 5, 5, 285),
      //   doc.line(55, 50, 5, 285)
      //   doc.html(data,{margin:[5,5,5,285]}),
      //   doc.viewerPreferences({'FitWindow': true}, true)
      //   console.log(doc.getFontList,"hkhhjhjhjhjhjhjhffff")


      //   position = heightLeft - imgHeight;
      //   doc.addPage();
      //   doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;



      // }
      // doc.save("exportedPdf.pdf");
      // console.log(canvas,"ggysgsguy")
      // let docWidth = 208;
      // let docHeight = (canvas.height * docWidth) / canvas.width;
      // const contentDataURL = canvas.toDataURL("image/png");
      // let doc = new jsPDF("p", "mm", "a4");
      // let position = 0;
      // doc.addImage(contentDataURL, "PNG", 0, position, docWidth, docHeight);
      // doc.save("exportedPdf.pdf");
      // doc.context2d
    });
  }

  openDialog(project) {
    const dialogRef = this.dialog.open(DateDialogComponent, {
      backdropClass: "custom-dialog-backdrop-class",
      panelClass: "custom-dialog-panel-class",
      data: { project: project },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log("The dialog was closed", result);
    //   this.dialogValue = result.data;
    // });
  }

  padString(num) {
    const str = num + "";
    const lastFourDigits = str.slice(-4);
    const paddedString = lastFourDigits.padStart(str.length, "*");
    console.log(paddedString);
  }

  ngAfterViewInit() { }

  ngOnDestroy() { }
}
