import { Component, OnInit, Optional, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DashboardService } from "../../../services/dashboard.service";

@Component({
  selector: "app-date-dialog",
  templateUrl: "./date-dialog.component.html",
  styleUrls: ["./date-dialog.component.scss"],
})
export class DateDialogComponent implements OnInit {
  project: any;
  previousDates = [];
  fromDialog: string;

  hide3HTable: boolean = false;

  dateForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    public dialogRef: MatDialogRef<DateDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.project = data.project;
    this.getPreviousCompletionDates(this.project);
  }

  ngOnInit(): void {
    this.fromDialog = "Hello I am coming form dialog component";
    this.dateForm = this.fb.group({
      dateInput: [null, Validators.required],
    });
    // this.getPreviousCompletionDates(this.project);
  }

  postNewDate(project, date) {
    let aquisitionStep;
    for (const key in project) {
      if (
        key === "expected 3A" ||
        key === "expected 3D" ||
        key === "expected 3G" ||
        key === "expected 3H"
      ) {
        aquisitionStep = key.split(" ")[1];
      }
    }
    console.log(aquisitionStep);
    if (aquisitionStep === "3A") {
      const data = {
        project: project.project_id,
        cala: project.cala_id,
        land: project.pending_3A,
        date: date,
      };
      this.dashboardService.postNew3ADate(data).subscribe((res) => {
        console.log(res);
      });
    }
    if (aquisitionStep === "3D") {
      const data = {
        project: project.project_id,
        cala: project.cala_id,
        land: project.pending_3D,
        date: date,
      };
      this.dashboardService.postNew3DDate(data).subscribe((res) => {
        console.log(res);
      });
    }
    if (aquisitionStep === "3G") {
      const data = {
        project: project.project_id,
        cala: project.cala_id,
        land: project.pending_3G,
        date: date,
      };
      this.dashboardService.postNew3GDate(data).subscribe((res) => {
        console.log(res);
      });
    }
    if (aquisitionStep === "3H") {
      const data = {
        project: project.project_id,
        cala: project.cala_id,
        amount: project.pending_3H_amount,
        date: date,
      };
      this.dashboardService.postNew3HDate(data).subscribe((res) => {
        console.log(res);
      });
    }
  }

  getPreviousCompletionDates(project) {
    console.log(project);
    let aquisitionStep;
    for (const key in project) {
      if (
        key === "expected 3A" ||
        key === "expected 3D" ||
        key === "expected 3G" ||
        key === "expected 3H"
      ) {
        aquisitionStep = key.split(" ")[1];
      }
    }
    console.log(aquisitionStep);
    if (aquisitionStep === "3A" || "3D" || "3G") {
      this.hide3HTable = false;
    }
    if (aquisitionStep === "3H") {
      this.hide3HTable = true;
    }
    this.dashboardService
      .getPreviousDates(project.project_id, project.cala_id, aquisitionStep)
      .subscribe((res) => {
        console.log(res.all_details);
        this.previousDates = res.all_details;
        this.previousDates=this.previousDates.reverse()
      });
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onSubmit() {
    const date = this.dateForm.value;
    const newDate = this.convert(date.dateInput);
    this.postNewDate(this.project, newDate);
  }

  // closeDialog() {
  //   this.dialogRef.close({ event: "close", data: this.fromDialog });
  // }
}
