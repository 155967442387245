import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ProjectService } from "../../../services/project.service";
import { NotificationService } from "../../../services/notification.service";
// import { group } from "console";

interface DialogData {
  email: string;
}

@Component({
  selector: "app-modal3D",
  templateUrl: "./modal3D.component.html",
  styleUrls: ["./modal.component.css"],
})
export class Modal3DComponent implements OnInit {
  completionForm_3D: FormGroup;
  id: any;

  villageData: any;
  showWarning: boolean = false;
  remainingLand: any;
  constructor(
    public dialogRef: MatDialogRef<Modal3DComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private notification: NotificationService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.projectService.villageID.subscribe((res) => {
      this.id = res;
    });
    this.projectService.retrieveVillageData(this.id).subscribe((res) => {
      this.villageData = res;
      this.remainingLand =
        this.villageData.a_3A_notification - this.villageData.d_3D_notification;

      if (this.remainingLand > 0) {
        this.showWarning = true;
      }
    });
    this.completionForm_3D = this.fb.group({
      dates_of_3D_notification: new FormControl(null, Validators.required),
      d_3D_completed_remark: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    const data = this.completionForm_3D.value;
    this.projectService.updateCompletionStatus(this.id, data).subscribe(
      (res) => {
        console.log(res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );

    this.dialogRef.close();
  }
}
