import { Component, OnInit } from "@angular/core";

@Component({
    selector:'app-private-dashboard',
    templateUrl:'private-dashboard.component.html',
    styleUrls: ['private-dashboard.component.css']
})
export class PrivateDashboardComponent implements OnInit{

    constructor(){}

    ngOnInit(): void {
        
    }
}