import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { Chart } from "chart.js";
import jsPDF from "jspdf";
import { ProjectService } from "../../services/project.service";
import { environment } from "../../../environments/environment";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { CalaListData } from "../../shared/data.model";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "app-cala-list",
  templateUrl: "./cala-list.component.html",
  styleUrls: ["./cala-list.component.css"],
})
export class CalaListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "CALA NAME",
    "PROJECTS",
    "ON TIME",
    "DELAYED",
    "COMPLETED",
    "EFFICIENCY",
  ];
  public dataSource = new MatTableDataSource<any>();
  ///// Spinner Data ////////
  isLoading = true;
  environmentName = environment.envName;
  ///// Spinner Data ////////

  calaDataSubs: Subscription;
  calaDataChartSubs: Subscription;

  delayed = false;
  completed = false;
  ontime = false;

  calaData: object[] = [];
  totalCala: any;

  delayedCalaList = [];
  ontimeCalaList = [];
  completedCalaList = [];
  chartData: any;
  requiredChart: boolean = false;

  calaID = new BehaviorSubject<any>(null);

  delayCount = 0;
  completedCount = 0;
  ontimeCountt = 0;

  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.calaDataSubs = this.projectService.getCalaStats().subscribe((res) => {
      console.log(res);
      this.dataSource.data = res as CalaListData[];
      this.calaData.push(...res);
      // this.calaData.push(...res);
      this.sortListAccordingToStatus(this.calaData);
      this.totalCala = this.calaData.length;
      this.countsCalculation(this.calaData);
    });
    this.checkStatus(this.calaData);
  }

  countsCalculation(arr) {
    arr.forEach((val) => {
      let el = val;
      if (el.delayed > 0) {
        this.delayCount++;
      } else if (el.delayed == 0 && el.ontime == 0) {
        this.completedCount++;
      } else {
        this.ontimeCountt++;
      }
    });
  }

  renderRequiredCharts(arr) {
    this.calaDataChartSubs = this.projectService
      .getCalaStats()
      .subscribe((res) => {
        arr.forEach((val) => {
          new Chart(`chart${val["cala_id"]}`, {
            type: "doughnut",
            data: {
              labels: ["Completed", "Ontime", "Delayed"],
              datasets: [
                {
                  data: [val.completed, val.ontime, val.delayed],
                  backgroundColor: [
                    "rgba(113,211,59,0.4)",
                    "rgba(53,169,255,0.4)",
                    "rgba(255,68,66,0.4)",
                  ],
                  hoverBackgroundColor: [
                    "rgba(113,211,59,0.9)",
                    "rgba(53,169,255,0.9)",
                    "rgba(255,68,66,0.9)",
                  ],
                  fill: false,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
              },
            },
          });
        });
      });
  }

  ngAfterViewInit() {
    this.calaDataChartSubs = this.projectService
      .getCalaStats()
      .subscribe((res) => {
        res.forEach((val) => {
          new Chart(`calaDataChart${val["cala_id"]}`, {
            type: "doughnut",
            data: {
              labels: ["Completed", "Ontime", "Delayed"],
              datasets: [
                {
                  data: [val.completed, val.ontime, val.delayed],
                  backgroundColor: [
                    "rgba(113,211,59,0.4)",
                    "rgba(53,169,255,0.4)",
                    "rgba(255,68,66,0.4)",
                  ],
                  hoverBackgroundColor: [
                    "rgba(113,211,59,0.9)",
                    "rgba(53,169,255,0.9)",
                    "rgba(255,68,66,0.9)",
                  ],
                  fill: false,
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                enabled: true,
              },
            },
          });
        });
      });

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  calaDetails(id, name) {
    this.projectService.calaID.next(id);
    localStorage.setItem("calaDetails_id", id);
    localStorage.setItem("cala_name", name);
    this.router.navigate(["/cala-details"]);
  }

  checkStatus(arr) {
    arr.forEach((element) => {
      if (element.delayed > 0) {
        // console.log(element.cala_name);
        this.delayed = true;
        console.log(this.delayed);
      } else {
        this.delayed = false;
      }
    });
  }

  sortListAccordingToStatus(arr) {
    arr.forEach((el) => {
      if (el.delayed > 0) {
        this.delayedCalaList.push(el);
      } else if (el.delayed === 0 && el.completed >= 0) {
        this.ontimeCalaList.push(el);
      } else {
        this.completedCalaList.push(el);
      }
    });
  }

  totalList() {
    this.dataSource.data = [];
    this.dataSource.data = this.calaData;
    this.requiredChart = false;
    this.ngAfterViewInit();
  }

  ontimeList() {
    this.dataSource.data = [];
    this.dataSource.data = this.ontimeCalaList;
    this.requiredChart = true;
    this.chartData = this.ontimeCalaList;
    this.renderRequiredCharts(this.chartData);
  }

  delayedList() {
    this.dataSource.data = [];
    this.dataSource.data = this.delayedCalaList;
    this.requiredChart = true;
    this.chartData = this.delayedCalaList;
    this.renderRequiredCharts(this.delayedCalaList);
  }

  completedList() {
    this.dataSource.data = [];
    this.dataSource.data = this.completedCalaList;
    this.requiredChart = true;
    this.chartData = this.completedCalaList;
    this.renderRequiredCharts(this.completedCalaList);
  }

  //donwload pdf from original canvas
  downloadPDF() {
    var canvas = document.querySelector(
      "#projectStatusChart"
    ) as HTMLCanvasElement;
    //creates image
    var canvasImg = canvas.toDataURL("image/jpeg", 1.0);

    //creates PDF from img
    var doc: any = new jsPDF("landscape");
    doc.setFontSize(20);
    doc.text(15, 15, "Cool Chart");
    doc.addImage(canvasImg, "JPEG", 10, 10, 280, 150);
    doc.save("canvas.pdf");
  }

  downloadRequiredPDF() {
    var canvas = document.querySelector(
      "#requiredProjectStatusChart"
    ) as HTMLCanvasElement;
    //creates image
    var canvasImg = canvas.toDataURL("image/jpeg", 1.0);

    //creates PDF from img
    var doc: any = new jsPDF("landscape");
    doc.setFontSize(20);
    doc.text(15, 15, "Cool Chart");
    doc.addImage(canvasImg, "JPEG", 10, 10, 280, 150);
    doc.save("canvas.pdf");
  }

  downloadReport() {
    this.reportsService.getAllCalaReports().subscribe((res) => {
      this.isDownloading = true;
      console.log(res);
      this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
        this.isDownloading = false;
        this.isDownloaded = res;
        console.log(res);
      });
    });
  }

  ngOnDestroy() {
    this.calaDataSubs.unsubscribe();
    this.calaDataChartSubs.unsubscribe();
  }
}
