import { Component, OnInit } from '@angular/core';
import {
  Location,
} from "@angular/common";
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test : Date = new Date();
  location: Location;
  showorhidebackbtn:string;
  showbtn:boolean;
  loggedInUsername;

  public isUserLoggedIn: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(null);

  header:string="Managed & Maintained by Credible Management Consultants Pvt Ltd"
  
  constructor( location: Location, private auth: AuthService,private router: Router,) { 
    this.location = location
  }
  
  ngOnInit() {
  }

  back() {
    this.location.back();
    // console.log(this.location, "location")

  }
  ngDoCheck(): void {
    // console.log(this.location.path());
    this.showorhidebackbtn=this.location.path();
    if (this.showorhidebackbtn=='/login' || this.showorhidebackbtn=='/dashboard') {
      this.showbtn=true
    }
    else{
      this.showbtn=false
    }
  }

  onLogout() {
    this.isUserLoggedIn.next(false);
    this.auth.userLogout();
    this.router.navigate(["/login"]);
  }

}
