import { Injectable, OnInit } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SortingService implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  sortName(array) {
    array.sort(function (a, b) {
      var nameA = a.name.split(" ").splice(1).join(" ").toLowerCase(),
        nameB = b.name.split(" ").splice(1).join(" ").toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortStrings(array) {
    array.sort(function (a, b) {
      var nameA = a.split(" ").splice(1).join(" ").toLowerCase(),
        nameB = b.split(" ").splice(1).join(" ").toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortState(array) {
    array.sort(function (a, b) {
      var nameA = a.state.toLowerCase(),
        nameB = b.state.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortDistrict(array) {
    array.sort(function (a, b) {
      var nameA = a.district.toLowerCase(),
        nameB = b.district.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortDistrictByIndex(array, index) {
    array[index].sort(function (a, b) {
      var nameA = a.district.toLowerCase(),
        nameB = b.district.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortTehsil(array) {
    array.sort(function (a, b) {
      var nameA = a.tehsil.toLowerCase(),
        nameB = b.tehsil.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortTehsilByIndex(array, index) {
    array[index].sort(function (a, b) {
      var nameA = a.tehsil.toLowerCase(),
        nameB = b.tehsil.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortVillage(array) {
    array.sort(function (a, b) {
      var nameA = a.village.toLowerCase(),
        nameB = b.village.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortVillageByIndex(array, index) {
    array[index].sort(function (a, b) {
      var nameA = a.village.toLowerCase(),
        nameB = b.village.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }
}
