import { Component, DoCheck, OnDestroy, OnInit } from "@angular/core";
// import { Chart } from "chart.js";
import { Subscription } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";

import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";
import { ProjectService } from "../../services/project.service";
import { VillageDetailsData } from "../../shared/data.model";
import { ProjectNotificationService } from "../../services/project.notification.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
// noData(Highcharts);

@Component({
  selector: "app-village-details",
  templateUrl: "./village-details.component.html",
  styleUrls: ["./village-details.component.css"],
})
export class VillageDetailsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    "Land area to be accquired(in ha)",
    "Date of request",
    "Date of 3(a)",
    "JMS completed",
    "3(A)(area in ha)",
    "3(D)(area in ha)",
    "3(G)(area in ha)",
    // "Fund Deposition for Area(area in ha)",
    // "Fund(in cr)",
    "3(H)/Fund(in cr)",
    "3(H)/Area(area in ha)",
    "Possession(area in ha)",
    "Mutation completed(area in ha)",
  ];
  public dataSource = new MatTableDataSource<any>();

  villageDataSubs: Subscription;
  villageIDSub: Subscription;
  last12YearsData: Subscription;
  last12QuartersData: Subscription;
  last12MonthsData: Subscription;
  villageData = [];

  villageDetails_id: any;

  years = [];
  lastThreeYears = [];
  months = [];
  twelveYearData = [];

  villageName: string;

  chartForm: FormGroup;

  constructor(
    private projectService: ProjectService,
    private projectNotificationService: ProjectNotificationService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.chartForm = this.fb.group({
      chartControl: [null, Validators.required],
    });

    this.villageIDSub = this.projectService.newVillageID.subscribe((res) => {
      this.villageDetails_id = localStorage.getItem("villageDetails_id");
      this.villageDataSubs = this.projectService
        .retrieveVillageData(this.villageDetails_id)
        .subscribe((res) => {
          console.log(res);
          this.villageData = [];
          this.dataSource.data = res as VillageDetailsData[];
          this.villageData.push(res);
          // console.log(this.villageData);
          //////////////////////// Chart configuration ////////////////////////
        });
    });
    this.last12YearsData = this.projectNotificationService
      .lastTweleveYearData(this.villageDetails_id)
      .subscribe((res) => {
        this.extractChartDataFor12Years(res);
        /////////////// High Charts Options /////////////////////////////
        const options: any = {
          title: {
            text: `Land area notified in last 12 years, ${this.years[11]} - ${this.years[0]}`,
          },

          yAxis: {
            title: {
              text: "Land Area (in ha)",
            },
          },

          xAxis: {
            categories: this.years.reverse(),
          },

          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
          },

          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
              // enableMouseTracking: false,
            },
            credits: {
              enabled: false,
            },
            exporting: {
              enabled: true,
            },
            line: {
              dataLabels: {
                enabled: true,
              },
              enableMouseTracking: false,
            },
          },
          series: [
            {
              name: "JMS completed",
              data: this.twelveYearData[0].reverse(),
            },
            {
              name: "3(A)",
              data: this.twelveYearData[1].reverse(),
            },
            {
              name: "3(D)",
              data: this.twelveYearData[2].reverse(),
            },
            {
              name: "3(G)",
              data: this.twelveYearData[3].reverse(),
            },
            {
              name: "3(H)/Area",
              data: this.twelveYearData[4].reverse(),
            },
            {
              name: "Possession",
              data: this.twelveYearData[5].reverse(),
            },
            {
              name: "Mutation completed",
              data: this.twelveYearData[6].reverse(),
            },
          ],

          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  },
                },
              },
            ],
          },
        };
        HC_exporting(Highcharts);
        Highcharts.chart("container", options);
      });
  }

  extractChartDataFor12Years(res) {
    const jmsData = [];
    res.jms.forEach((el) => {
      for (const key in el) {
        jmsData.push(el[key]);
        this.years.push(+key);
      }
    });
    const possessionData = [];
    res.possession.forEach((el) => {
      for (const key in el) {
        possessionData.push(el[key]);
      }
    });
    const mutationData = [];
    res.mutation.forEach((el) => {
      for (const key in el) {
        mutationData.push(el[key]);
      }
    });
    const A3Data = [];
    res["3A"].forEach((el) => {
      for (const key in el) {
        A3Data.push(el[key]);
      }
    });
    const D3Data = [];
    res["3D"].forEach((el) => {
      for (const key in el) {
        D3Data.push(el[key]);
      }
    });
    const G3Data = [];
    res["3G"].forEach((el) => {
      for (const key in el) {
        G3Data.push(el[key]);
      }
    });
    const H3Data = [];
    res["3H"].forEach((el) => {
      for (const key in el) {
        H3Data.push(el[key]);
      }
    });

    this.twelveYearData = [
      jmsData,
      A3Data,
      D3Data,
      G3Data,
      H3Data,
      possessionData,
      mutationData,
    ];
  }

  extractChartDataFor12Quarters(res) {
    const jmsData = [];
    const years = [];
    res.jms.forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          jmsData.push(el[key]);
        }
      }
    });
    const possessionData = [];
    res.possession.forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          possessionData.push(el[key]);
        }
      }
    });
    const mutationData = [];
    res.mutation.forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          mutationData.push(el[key]);
        }
      }
    });
    const A3Data = [];
    res["3A"].forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          A3Data.push(el[key]);
        }
      }
    });
    const D3Data = [];
    res["3D"].forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          D3Data.push(el[key]);
        }
      }
    });
    const G3Data = [];
    res["3G"].forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          G3Data.push(el[key]);
        }
      }
    });
    const H3Data = [];
    res["3H"].forEach((el) => {
      for (const key in el) {
        if (key === "year") {
          years.push(el[key]);
        } else {
          H3Data.push(el[key]);
        }
      }
    });

    this.twelveYearData = [
      jmsData,
      A3Data,
      D3Data,
      G3Data,
      H3Data,
      possessionData,
      mutationData,
    ];
    let uniqueYears = years.filter((c, index) => {
      return years.indexOf(c) === index;
    });

    this.lastThreeYears = uniqueYears.reverse();
    // console.log(this.lastThreeYears);
  }

  extractChartDataFor12Months(res) {
    const jmsData = [];
    res.jms.forEach((el) => {
      for (const key in el) {
        jmsData.push(el[key]);
        this.months.push(key);
      }
    });
    const possessionData = [];
    res.notification_possession.forEach((el) => {
      for (const key in el) {
        possessionData.push(el[key]);
      }
    });
    const mutationData = [];
    res.notification_mutation.forEach((el) => {
      for (const key in el) {
        mutationData.push(el[key]);
      }
    });
    const A3Data = [];
    res["3A"].forEach((el) => {
      for (const key in el) {
        A3Data.push(el[key]);
      }
    });
    const D3Data = [];
    res["3D"].forEach((el) => {
      for (const key in el) {
        D3Data.push(el[key]);
      }
    });
    const G3Data = [];
    res["3G"].forEach((el) => {
      for (const key in el) {
        G3Data.push(el[key]);
      }
    });
    const H3Data = [];
    res["3H"].forEach((el) => {
      for (const key in el) {
        H3Data.push(el[key]);
      }
    });

    this.twelveYearData = [
      jmsData,
      A3Data,
      D3Data,
      G3Data,
      H3Data,
      possessionData,
      mutationData,
    ];
  }

  ngAfterViewInit(): void {
    this.projectService
      .retrieveVillageData(this.villageDetails_id)
      .subscribe((res) => {
        this.villageName = res.village;
      });
  }

  onSubmitChartForm() {
    let id = this.chartForm.value.chartControl;
    if (id === "2") {
      this.last12QuartersData = this.projectNotificationService
        .lastThreeYearData(this.villageDetails_id)
        .subscribe((res) => {
          // console.log(res);
          this.extractChartDataFor12Quarters(res);
          /////////////// High Charts Options /////////////////////////////
          const options: any = {
            title: {
              text: "Land area notified in last 12 quarters.",
            },

            yAxis: {
              title: {
                text: "Land Area (in ha)",
              },
            },

            xAxis: {
              categories: [
                `Jan-Mar ${this.lastThreeYears[0]}`,
                `Apr-Jun ${this.lastThreeYears[0]}`,
                `Jul-Sep ${this.lastThreeYears[0]}`,
                `Oct-dec ${this.lastThreeYears[0]}`,
                `Jan-Mar ${this.lastThreeYears[1]}`,
                `Apr-Jun ${this.lastThreeYears[1]}`,
                `Jul-Sep ${this.lastThreeYears[1]}`,
                `Oct-dec ${this.lastThreeYears[1]}`,
                `Jan-Mar ${this.lastThreeYears[2]}`,
                `Apr-Jun ${this.lastThreeYears[2]}`,
                `Jul-Sep ${this.lastThreeYears[2]}`,
                `Oct-dec ${this.lastThreeYears[2]}`,
              ],
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                // enableMouseTracking: false,
              },

              line: {
                dataLabels: {
                  enabled: true,
                },
                enableMouseTracking: false,
              },
            },

            series: [
              {
                name: "JMS completed",
                data: this.twelveYearData[0].reverse(),
              },
              {
                name: "3(A)",
                data: this.twelveYearData[1].reverse(),
              },
              {
                name: "3(D)",
                data: this.twelveYearData[2].reverse(),
              },
              {
                name: "3(G)",
                data: this.twelveYearData[3].reverse(),
              },
              {
                name: "3(H)/Area",
                data: this.twelveYearData[4].reverse(),
              },
              {
                name: "Possession",
                data: this.twelveYearData[5].reverse(),
              },
              {
                name: "Mutation completed",
                data: this.twelveYearData[6].reverse(),
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };
          Highcharts.chart("container", options);
        });
    }
    if (id === "1") {
      this.ngOnInit();
    }
    if (id === "3") {
      this.last12MonthsData = this.projectNotificationService
        .lastTwelveMonthsData(this.villageDetails_id)
        .subscribe((res) => {
          this.extractChartDataFor12Months(res);
          /////////////// High Charts Options /////////////////////////////
          const options: any = {
            title: {
              text: "Land area notified in last 12 months.",
            },

            yAxis: {
              title: {
                text: "Land Area (in ha)",
              },
            },

            xAxis: {
              categories: this.months.reverse(),
            },

            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "middle",
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false,
                },
                // enableMouseTracking: false,
              },

              line: {
                dataLabels: {
                  enabled: true,
                },
                enableMouseTracking: false,
              },
            },

            series: [
              {
                name: "JMS completed",
                data: this.twelveYearData[0].reverse(),
              },
              {
                name: "3(A)",
                data: this.twelveYearData[1].reverse(),
              },
              {
                name: "3(D)",
                data: this.twelveYearData[2].reverse(),
              },
              {
                name: "3(G)",
                data: this.twelveYearData[3].reverse(),
              },
              {
                name: "3(H)/Area",
                data: this.twelveYearData[4].reverse(),
              },
              {
                name: "Possession",
                data: this.twelveYearData[5].reverse(),
              },
              {
                name: "Mutation completed",
                data: this.twelveYearData[6].reverse(),
              },
            ],

            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      layout: "horizontal",
                      align: "center",
                      verticalAlign: "bottom",
                    },
                  },
                },
              ],
            },
          };
          Highcharts.chart("container", options);
        });
    }
  }
  ngOnDestroy() {
    this.villageDataSubs.unsubscribe();
    this.villageIDSub.unsubscribe();
    this.last12YearsData.unsubscribe();
    // this.last12MonthsData.unsubscribe();
    // this.last12QuartersData.unsubscribe();
  }
}
