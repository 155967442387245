import {
    Component,
    OnInit,
    OnDestroy,
    AfterContentInit,
    ViewChild,
    AfterViewInit,
    ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { ReportsService } from "../../../services/reports.service";
import { DashboardService } from "../../../services/dashboard.service";
import { CalaDetailModel } from "../../../shared/dashboradData.model";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: "app-collector-report",
    templateUrl: "./collector-reports.component.html",
    styleUrls: ["./collector-reports.component.css"],
})
export class CollectorReportComponent
    implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
    displayedColumns: string[] = [
        "CALA Name",
        "Total land to be aquired",
        "Start Date",
        "3a status",
        "JMS request status",
        "JMS",
        "3A",
        "3C",
        "3D",
        "3G",
        "3H",
        "3H funds distributed",
        "Possession",
        "Mutation",
        "Govt. Land To Be Aquired",
        "Govt. Land Possession",
        "Govt. Land Mutation",
        "Private Land To Be acquired",
        "Total Private Land acquired",
        "Total Private fund disbursed",
        "Funds Diposition Under PN",
        "Funds Distribution Under PN",
    ];
    public dataSource = new MatTableDataSource<any>();
    public dataSource2 = [];

    isDownloading: boolean = false;
    isDownloaded: boolean = false;

    id: string = "128";
    projectName: string = "";
    toBeCompleted: any;
    completedTillNow: any;
    projectsList: any;
    inPercentage: any;
    overviewData: any;
    graphData: object;
    date: Date;

    day: number = 5;
    colors = ["#FD6452", "#8FCF52"];
    totalCALAList = [];
    sixCalaList = [];
    twentycalaList = [];
    arrayofTwentycalalist = []
    flag = true;
    array = [];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild("convertToPDF") makePDF: ElementRef;
    @ViewChild("overviewTable") overviewTable: ElementRef;
    constructor(
        private router: Router,
        private reportsService: ReportsService,
        private dashboardService: DashboardService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {

        this.date = new Date

        this.route.paramMap.subscribe((params) => {
            this.id = params.get("id");
            this.projectName = params.get("name");
        });
        this.dashboardService
            .getCollectorDashboardDataReport(this.id)
            .subscribe((res) => {

                this.array = res.details;
                this.array.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    }
                    return 0;
                })

                // this.showOrhide=res.details.length
                // for (let index = 0; index < res.details.length; index++) {

                //   if (index < 6) {
                //     this.fivedataarray.push(res.details[index]);
                //     console.log(this.fivedataarray, "shiuhhuhhuiyhuiyi")
                //   }
                //   if (index >= 6 && index < 40) {
                //     this.tendataarray.push(res.details[index]);
                //     console.log(this.tendataarray, "shiuhhuhhuiyhuiyi")
                //   }

                // }


                //  ******* Dynamic generation of table for providing page split********  //
                this.totalCALAList = res.details
                while (this.totalCALAList.length > 0) {
                    // console.log(this.flag,"flag")
                    if (this.flag == true) {
                        this.sixCalaList = this.totalCALAList.slice(0, 6)
                        this.totalCALAList = this.totalCALAList.slice(6)
                        this.flag = false;
                        // console.log(this.fivedataArray,"slice one")
                    }
                    else {
                        this.twentycalaList = this.totalCALAList.slice(0, 10);
                        this.arrayofTwentycalalist.push(this.twentycalaList);
                        // console.log(this.arrayOfTwentyData,"multysyys")
                        this.totalCALAList = this.totalCALAList.slice(10);
                        // console.log(this.twelveProjectList,"slice 2")
                    }
                }

                console.log(res);
                this.projectName = res.name
                this.overviewData = res.overview;
                this.toBeCompleted = res.overview.to_be_completed;
                this.completedTillNow = res.overview.total_completed_till_now;
                this.inPercentage = res.percentage;
                this.projectsList = res.details;

                this.dataSource.data = this.sixCalaList;


                //  logic For multiple data in mat table //

                for (let index = 0; index < this.arrayofTwentycalalist.length; index++) {
                    // this.dataSource2.data = this.tendataarray as ProjectDetailModel[];
                    this.dataSource2.push(new MatTableDataSource<any>());
                    this.dataSource2[index].data = this.arrayofTwentycalalist[index];

                }

                // End Of logic For multiple data in mat table //

                //////////////////// High Charts //////////////////////////
                const options: any = {
                    chart: {
                        type: "bar",
                    },
                    title: {
                        text: "Total land aquisition in hactare",
                    },
                    subtitle: {
                        text: "",
                    },
                    accessibility: {
                        announceNewData: {
                            enabled: true,
                        },
                    },
                    xAxis: {
                        type: "category",
                    },
                    yAxis: {
                        title: {
                            text: "In hactares",
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    const y = this.y;
                                    const total = res.overview.total_land_aquire;
                                    const pct = (y / total) * 100;
                                    return `${pct.toFixed(2)}%`;
                                },
                            },
                        },
                    },

                    tooltip: {
                        headerFormat:
                            '<span style="font-size:11px">{series.name}</span><br>',
                        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of <b>${res.overview.total_land_aquire}</b><br/>`,
                    },

                    series: [
                        {
                            name: "Aquisition Step",
                            colorByPoint: true,
                            data: [
                                {
                                    name: "JMS",
                                    y: res.overview.total_completed_till_now.jms,
                                },
                                {
                                    name: "3(A)",
                                    y: res.overview.total_completed_till_now["3A"],
                                },
                                {
                                    name: "3(C)",
                                    y: res.overview.total_completed_till_now["3C"],
                                },
                                {
                                    name: "3(D)",
                                    y: res.overview.total_completed_till_now["3D"],
                                },
                                {
                                    name: "3(G)",
                                    y: res.overview.total_completed_till_now["3G"],
                                },
                                {
                                    name: "3(H)",
                                    y: res.overview.total_completed_till_now["3H_area"],
                                },
                                {
                                    name: "Possession",
                                    y: res.overview.total_completed_till_now.possession,
                                },
                                {
                                    name: "Mutation",
                                    y: res.overview.total_completed_till_now.mutation,
                                },
                                {
                                    name: "Govt. Land Possession",
                                    y: res.overview.total_completed_till_now.govt_possession,
                                },
                                {
                                    name: "Govt. Land Mutation",
                                    y: res.overview.total_completed_till_now.govt_mutation,
                                },
                            ],
                        },
                    ],
                };
                HC_exporting(Highcharts);
                Highcharts.chart("container", options);
            });

        setTimeout(() => {
            this.downloadPageAsPDF();
        }, 5000);
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngAfterContentInit() { }

    public doFilter = (value: string) => {
        this.dataSource.filter = value.toLocaleLowerCase();
    };

    projectDetails(id, name) {
        console.log(id, name);
        localStorage.setItem("PROJECT_ID", id);
        localStorage.setItem("PROJECT_NAME", name);
        this.router.navigate(["/project-info"]);
    }

    logData(cala) {
        this.router.navigate(["/cala", cala.id]);
    }

    downloadReport() {
        this.reportsService.getAllProjectsReport().subscribe((res) => {
            this.isDownloading = true;
            console.log(res);
            this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
                this.isDownloading = false;
                this.isDownloaded = res;
                console.log(res);
            });
        });
    }

    downloadPageAsPDF() {


        let data = document.getElementById("pagePDF");

        html2canvas(data).then((canvas) => {

            var imgWidth = 295;
            var pageHeight = 208;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            const contentDataURL = canvas.toDataURL("image/png", .01);

            var doc = new jsPDF('l', 'mm', '', true);
            var position = 0;

            doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save("exportedPdf.pdf");

            //   let docWidth = 208;
            //   let docHeight = (canvas.height * docWidth) / canvas.width;
            //   const contentDataURL = canvas.toDataURL("image/png");
            //   let doc = new jsPDF("p", "mm", "a4");
            //   let position = 0;
            //   doc.addImage(contentDataURL, "PNG", 0, position, docWidth, docHeight);
            //   doc.save("exportedPdf.pdf");
        });
    }

    // downlaodOverviewTableAsPDF() {
    //   const pdfTable = this.overviewTable.nativeElement;
    //   var html = htmlToPdfmake(pdfTable.innerHTML);
    //   const documentDefinition = {
    //     pageSize: "A2",
    //     content: html,
    //     pageOrientation: "portrait",
    //     pageMargins: [10, 60, 10, 60],
    //   };
    //   pdfMake.createPdf(documentDefinition).download();
    // }

    ngOnDestroy() { }
}
