import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { LoginComponent } from "./user-auth/login/login.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthGuard } from "./services/auth.guard";
import { RouteGuardGuard } from "./services/route-guard.guard";
import { DashboardReportComponent } from "./reports/dashboard-reports/dashboard-report/dashboard-report.component";
import { CalaReportComponent } from "./reports/dashboard-reports/cala-report/cala-report.component";
import { CollectorReportComponent } from "./reports/dashboard-reports/collector-reports/collector-reports.component";
import { CommissionerReportComponent } from "./reports/dashboard-reports/Commissioner-reports/commissioner-reports.component";
import { SecretaryComponent } from "./dashboard/secretary-dashboard/secretary-dashboard.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent },
  { path: "secretary/:id", component: SecretaryComponent },
  { path: "project/report/:id" , component:DashboardReportComponent},
  { path: "cala/report/:id", component: CalaReportComponent },
  { path: "collector/report/:id", component:CollectorReportComponent},
  { path: "commissioner/report/:id", component:CommissionerReportComponent},
  {
    path: "",
    component: AdminLayoutComponent,
    // canActivate: [AuthGuard],
    canActivateChild: [RouteGuardGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./layouts/admin-layout/admin-layout.module").then(
            (m) => m.AdminLayoutModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "login",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{ useHash: true }, ),
  ],
  exports: [],
})
export class AppRoutingModule {}
