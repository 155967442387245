import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DashboardService } from "../../services/dashboard.service";
import { MatDialog } from "@angular/material/dialog";
import { DateDialogComponent } from "./date-dialog/date-dialog.component";

export interface ProjectListModel {
  project_id: number;
  project_name: string;
}

export interface CalaListModel {
  cala_id: number;
  cala_name: string;
}

@Component({
  selector: "app-critical-analysis-reports",
  templateUrl: "./critical-analysis-reports.component.html",
  styleUrls: ["./critical-analysis-reports.component.scss"],
})
export class CriticalAnalysisReportsComponent implements OnInit {
  pendingListSubs: Subscription;
  roID: string;
  PIDs: string;

  dashboardData: any;
  projectsList$: ProjectListModel[];
  calaList$: CalaListModel[];

  overallPendingDetails: any;
  cardsDetails: any;
  pending3A = [];
  pending3D = [];
  pending3G = [];
  pending3H = [];
  dialogValue: string;
  urlForcriticalreport;

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.roID = params.get("id");
    });
    this.PIDs = this.route.snapshot.queryParamMap.get("id");
    // console.log(this.PIDs);

    this.pendingListSubs = this.dashboardService
      .getAllCriticalReports(this.roID, this.PIDs)
      .subscribe((res) => {
        // console.log(res,"critical res")
        this.pending3A = res["3A"].flat();
        this.pending3D = res["3D"].flat();
        this.pending3G = res["3G"].flat();
        this.pending3H = res["3H_amount"].flat();
        this.cardsDetails = res;
        this.overallPendingDetails = res.details;
      });
  }

  openDialog(project) {
    const dialogRef = this.dialog.open(DateDialogComponent, {
      backdropClass: "custom-dialog-backdrop-class",
      panelClass: "custom-dialog-panel-class",
      data: { project: project },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   console.log("The dialog was closed", result);
    //   this.dialogValue = result.data;
    // });
  }

  padString(num) {
    const str = num + "";
    const lastFourDigits = str.slice(-4);
    const paddedString = lastFourDigits.padStart(str.length, "*");
    // console.log(paddedString);
  }


  generateReport(){
    this.dashboardService.downloadAllCriticalReports(this.roID,this.PIDs).subscribe((res)=>{
      // console.log(res,"critical report url"),
      document.getElementById("show").style.display="",
      document.getElementById("hide").style.display="none"
      this.urlForcriticalreport=res.file
    })
  }

  ngAfterViewInit() {}

  // pdf(){
  //   // document.getElementById("print")
  //   var printContents = document.getElementById("Print").innerHTML;
  //    var originalContents = document.body.innerHTML;

  //    document.body.innerHTML = printContents;

  //    window.print();

  //    document.body.innerHTML = originalContents;
  // }

  ngOnDestroy() {}
}
