import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Subscription } from "rxjs";
import { ReportsService } from "../services/reports.service";
import { ReportModel } from "../shared/report.model";
import { DashboardService } from "../services/dashboard.service";
import { MothlyReportModel } from "../shared/monthlyreport.model";
import { ProjectService } from "../services/project.service";
import { Router } from "@angular/router";


export interface ProjectListModel {
  project_id: number;
  project_name: string;
}

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit, OnDestroy {
  allReportsSubs$: Subscription;
  allReportsOnDownloadSubs$: Subscription;
  masterReport$: Subscription;
  allProjectReportsList = [];
  reportsOnDownload = [];
  masterFileLink;
  showorHide= true
  displayedColumns: string[] = ["name", "datetimestamp", "id", "file_link"];
  projectColumns: string[] = ["name", "date", "type", "pdf",];
  criticalColumns: string[] = ["name", "date", "pdf",];

  //  for dropdown list  //

  dashboardDataSubs: Subscription;
  projectsListSubs: Subscription;
  calaListSubs: Subscription;
  roListSubs: Subscription;
  collectorListSubs: Subscription;
  commissionerListSubs: Subscription;

  dashboardData: any;
  projectsList$: ProjectListModel[];
  calaList$ = [];

  roList = [];
  collectorList$ = [];
  commissionerList$ = [];
  monthlyProjectReport: Subscription;
  monthlyCalaReport:Subscription;
  monthlycollectorreport:Subscription;
  monthlycommissionerReport:Subscription;
  monthlycriticalReport:Subscription;
  RoName;
  ProjectName;
  calaName;
  collectorName;
  commissionerName;
  criticaldata=[];
  agencyId="total"
  agencyArray:any;
  disignation;

  //  Ends of dropdown list 

  public dataSource = new MatTableDataSource<any>();
  public projectdataSource = new MatTableDataSource<any>();
  public caladataSource = new MatTableDataSource<any>();
  public collectordataSource = new MatTableDataSource<any>();
  public commissionerdataSource = new MatTableDataSource<any>();
  public criticaldataSource = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private reportsService: ReportsService,
     private dashboardService:DashboardService,
     private projectService: ProjectService,
     private router:Router) { }

  ngOnInit(): void {

    this.disignation = localStorage.getItem('designation')

      //3. Agencies
      this.projectService.getAgencies().subscribe((res) => {
        console.log(res, "aggenciesks")
        this.agencyArray = res;
      });

    //  For getting project,cala,collector and commisioner list //

    
      this.projectsListSubs = this.dashboardService
        .getAllProjects(this.agencyId)
        .subscribe((res) => {
          this.projectsList$ = res;
          this.projectsList$ = this.projectsList$.sort(function (a, b) {
            if (a.project_name.toLowerCase() < b.project_name.toLowerCase()) { return -1; }
            if (a.project_name.toLowerCase() > b.project_name.toLowerCase()) { return 1; }
            return 0;
          })
        });
  
      this.dashboardDataSubs = this.dashboardService
        .getDashboardData(this.agencyId)
        .subscribe((res) => {
          this.dashboardData = res;
          console.log(res);
        });
  
      this.calaListSubs = this.dashboardService
        .getAllCalaList(this.agencyId)
        .subscribe((res) => {
          this.calaList$ = [...res];
          this.calaList$ = this.calaList$.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
          // console.log(res);
        });
  
      this.roListSubs = this.dashboardService.getROList(this.agencyId).subscribe((res) => {
        this.roList = [...res];
      });
  
      this.collectorListSubs = this.dashboardService
        .getDistrictCollectorList(this.agencyId)
        .subscribe((res) => {
          this.collectorList$ = [...res];
          this.collectorList$ = this.collectorList$.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
        });
  
      this.commissionerListSubs = this.dashboardService
        .getCommissionerList(this.agencyId)
        .subscribe((res) => {
          this.commissionerList$ = [...res];
          this.commissionerList$ = this.commissionerList$.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
        });

    this.allReportsSubs$ = this.reportsService
      .getAllProjectsList()
      .subscribe((res) => {
        console.log(res);
        this.dataSource.data = res.reverse() as ReportModel[];
      });

    this.reportsService.getReportsOnDownload().subscribe((res) => {
      this.reportsOnDownload = [...res];
    });


    this.roListSubs = this.dashboardService.getROList(this.agencyId).subscribe((res) => {
      this.roList = [...res];
    });

   
  }

  getAgencyId(id){

    this.projectsListSubs = this.dashboardService
        .getAllProjects(id)
        .subscribe((res) => {
          this.projectsList$ = res;
          this.projectsList$ = this.projectsList$.sort(function (a, b) {
            if (a.project_name.toLowerCase() < b.project_name.toLowerCase()) { return -1; }
            if (a.project_name.toLowerCase() > b.project_name.toLowerCase()) { return 1; }
            return 0;
          })
        });
  
      this.dashboardDataSubs = this.dashboardService
        .getDashboardData(id)
        .subscribe((res) => {
          this.dashboardData = res;
          console.log(res);
        });
  
      this.calaListSubs = this.dashboardService
        .getAllCalaList(id)
        .subscribe((res) => {
          this.calaList$ = [...res];
          this.calaList$ = this.calaList$.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
          // console.log(res);
        });
  
      this.roListSubs = this.dashboardService.getROList(id).subscribe((res) => {
        this.roList = [...res];
      });
  
      this.collectorListSubs = this.dashboardService
        .getDistrictCollectorList(id)
        .subscribe((res) => {
          this.collectorList$ = [...res];
          this.collectorList$ = this.collectorList$.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
        });
  
      this.commissionerListSubs = this.dashboardService
        .getCommissionerList(id)
        .subscribe((res) => {
          this.commissionerList$ = [...res];
          this.commissionerList$ = this.commissionerList$.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
        });

    


    this.roListSubs = this.dashboardService.getROList(id).subscribe((res) => {
      this.roList = [...res];
    });
  }



  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  masterReport(){
    this.masterReport$ = this.reportsService.getAllMasterReport().subscribe((res => {
      console.log("master res", res)
      this.masterFileLink = res.file
      document.getElementById('showOrhide').style.display = "";
      document.getElementById('hide').style.display="none"
    }))
  }

  projectDashboard(id) {
    const [project] = this.projectsList$.filter(
      (p) => p.project_id === parseInt(id),
      
    );
    console.log(project)
    this.ProjectName= project.project_name
    // this.router.navigate(["project",  id]);
    this.monthlyProjectReport= this.reportsService.getProjectMonthlyReport(id).subscribe(res=>{
      console.log(res,"project mothly report");
      this.projectdataSource.data = res as MothlyReportModel[];
      document.getElementById('project_report').style.display="";
    })

    
  }

  calaDashboard(id) {
    const [cala] = this.calaList$.filter((p) => p.id === parseInt(id));
    console.log(cala,"cala name")
    this.calaName=cala.name
    // this.router.navigate(["cala", id]);
    this.monthlyCalaReport= this.reportsService.getCalaMonthlyReport(id).subscribe(res=>{
      console.log(res,"cala monthly report");
      this.caladataSource.data = res as MothlyReportModel[];
      document.getElementById('cala_report').style.display="";
    })
    
  }

  collectorDashboard(id) {
    const [collector] = this.collectorList$.filter(
      (p) => p.id === parseInt(id)
    );
    console.log(collector,"collector")
    this.collectorName=collector.name
    this.monthlycollectorreport= this.reportsService.getCollectorMonthlyReport(id).subscribe(res=>{
      console.log(res,"collector monthly report");
      this.collectordataSource.data = res as MothlyReportModel[];
      document.getElementById('collector_report').style.display="";
    })
    // this.router.navigate(["collector", id]);
  }

  commissionerDashboard(id) {
    const [comm] = this.commissionerList$.filter((p) => p.id === parseInt(id));
    // this.router.navigate(["commissioner", id]);
    console.log(comm);
    this.commissionerName=comm.name
    this.monthlycommissionerReport= this.reportsService.getCommissionerMonthlyReport(id).subscribe(res=>{
      console.log(res,"commisioner monthly report");
      this.commissionerdataSource.data = res as MothlyReportModel[];
      document.getElementById('commissioner_report').style.display="";
    })
  }

  criticalReports(id) {
    const [ro] = this.roList.filter((p) => p.id === parseInt(id));
    // this.router.navigate([
    //   "critical-analysis-report",
    //   id,
    //   "projectList",
    // ]);
    console.log(ro)

    this.RoName=ro.name
    this.monthlycriticalReport= this.reportsService.getCriticalMonthlyReport(id).subscribe(res=>{
      console.log(res,"commisioner monthly report");
      this.criticaldata=res.reverse()
      this.criticaldataSource.data = this.criticaldata as MothlyReportModel[];
      document.getElementById('critical_report').style.display="";
    })
  }

  criticalReportsgenerate(id) {
    const [ro] = this.roList.filter((p) => p.id === parseInt(id));
    this.router.navigate([
      "critical-analysis-report",
      id,
      "projectList",
    ]);
  }



  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.allReportsSubs$.unsubscribe();
    // this.masterReport$.unsubscribe();

    //  for unsub

    this.dashboardDataSubs.unsubscribe();
    this.projectsListSubs.unsubscribe();
    this.calaListSubs.unsubscribe();
    this.roListSubs.unsubscribe();
    this.collectorListSubs.unsubscribe();
    this.commissionerListSubs.unsubscribe();
  }


 
}
