import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectInfo } from "../../shared/dashboradData.model";
import { DashboardService } from "../../services/dashboard.service";

@Component({
    selector: 'app-project-info',
    templateUrl: 'project-info.component.html',
    styleUrls: ['project-info.component.css']
})
export class ProjectListInfoComponent implements OnInit {

    projectInfoId;
    projectlistdata;
    projectCMlistdata;
    proprioriitylist;
    prostatelist;
    projectName;
    displayedColumns: string[] = [
        "id",
        "project_name",   
    ];
    pageIndex=0;
    pageSize=10;
    p: number = 1;
    m: number = 1;
    q: number = 1;
    r: number = 1;
    totalcount;
    showorhide;
    public dataSource = new MatTableDataSource<any>();
    public dataSourcecm = new MatTableDataSource<any>();
    public dataSourcestage = new MatTableDataSource<any>();
    public dataSourcepriority= new MatTableDataSource<any>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatPaginator) paginator2: MatPaginator;
    @ViewChild(MatPaginator) paginator3: MatPaginator;
    @ViewChild(MatPaginator) paginator4: MatPaginator;


    constructor(private router:Router,private route: ActivatedRoute, private dashboardservice:DashboardService) { }

    ngOnInit(): void {
       this.showorhide= localStorage.getItem('showOrhide')
        this.route.paramMap.subscribe((params) => {
            this.projectInfoId = params.get("id");
            // this.projectName = params.get("name");
        });
        this.route.paramMap.subscribe((params) => {
            this.projectInfoId = params.get("id");
            this.projectName = params.get("name");
        });
        if (this.showorhide=='project') {
            this.projectList();
        }
        if (this.showorhide=='PMG' || this.showorhide=='PM laid foundation' || this.showorhide=='PRAGATI' || this.showorhide=='CM/MIN (RTH)') {
            this.monitoringProject();
        }
        if (this.showorhide=="Priority 1" || this.showorhide=="Priority 2") {
            this.priorityProject();
        }
        if (this.showorhide=="Ongoing" || this.showorhide=="DPR") {
            this.stageProject();
        }
       

    }

    getPaginatorData(event) {

        // console.log(event);
        this.pageIndex=event.pageIndex;
        this.pageSize=event.pageSize;
        
        const startIndex = event.pageIndex * event.pageSize;
        // let endIndex = startIndex + event.pageSize;
        // if (endIndex > this.calalists.length ) {
        //   endIndex = this.calalists.length
        // }
        //  this.pageSlice =this.calalists.slice(startIndex,endIndex);
    }

    projectList(){
        this.dashboardservice.projectCount(this.projectInfoId,this.p).subscribe(res=>{
            // console.log('project details',res)
            this.projectlistdata=res.results;
            this.totalcount=res.count
            this.dataSource.data = res.results as ProjectInfo[];
        })
    }

    onpagechangeprolist(event){
        this.p=event;
        this.dashboardservice.projectCount(this.projectInfoId,this.p).subscribe(res=>{
            console.log('project details',res)
            this.projectlistdata=res.results;
        })
    }

    monitoringProject(){
        this.dashboardservice.monitoringProjectdata(this.projectInfoId,this.projectName,this.m).subscribe(res=>{
            // console.log("Monitoring Project list",res);
            this.projectCMlistdata=res.results
            this.totalcount=res.count
            // this.dataSourcecm.data=res.results as ProjectInfo[];
        })
    }
    onpagechangecmprolist(event){
        this.m=event;
        console.log(this.projectName,"abcd");
        
        this.dashboardservice.monitoringProjectdata(this.projectInfoId,this.projectName,this.m).subscribe(res=>{
            // console.log("Monitoring Project list",res);
            this.projectCMlistdata=res.results
            this.totalcount=res.count
            // this.dataSourcecm.data=res.results as ProjectInfo[];
        })
    }

    priorityProject(){
        this.dashboardservice.priorityProjectdata(this.projectInfoId,this.projectName,this.q).subscribe(res=>{
            // console.log("Monitoring priority list",res)
            this.proprioriitylist=res.results;
            this.totalcount=res.count
            // this.dataSourcepriority.data=res.results as ProjectInfo[];
        })
    }
    onpagechangepropriolist(event){
        this.q=event;
        this.dashboardservice.priorityProjectdata(this.projectInfoId,this.projectName,this.q).subscribe(res=>{
            // console.log("Monitoring priority list",res)
            this.proprioriitylist=res.results;
            this.totalcount=res.count
            // this.dataSourcepriority.data=res.results as ProjectInfo[];
        })
    }

    stageProject(){
        this.dashboardservice.stageProjectdata(this.projectInfoId,this.projectName,this.r).subscribe(res=>{
            // console.log("Monitoring stage list",res);
            this.totalcount=res.count;
            this.prostatelist=res.results
            this.dataSourcestage.data=res.results as ProjectInfo[];
        })
    }
    onpagechangeprostagelist(event){
        this.r=event;
        console.log(this.projectInfoId,this.projectName,"abcd");
        
        this.dashboardservice.stageProjectdata(this.projectInfoId,this.projectName,this.r).subscribe(res=>{
            // console.log("Monitoring stage list",res);
            this.prostatelist=res.results
            this.dataSourcestage.data=res.results as ProjectInfo[];
        })
    }


    public doFilter = (value: string) => {
        this.dataSource.filter = value.toLocaleLowerCase();
    };

    gotoProjectDashboard(id){
        this.router.navigate(["project", id]);

    }


    ngAfterViewInit() {

        if (this.showorhide=='PMG' || this.showorhide=='PM laid foundation' || this.showorhide=='PRAGATI' || this.showorhide=='CM/MIN (RTH)') {
            this.dataSourcecm.paginator = this.paginator2;

        }
        if (this.showorhide=="project") {
            this.dataSource.paginator = this.paginator;

        }

        if (this.showorhide=="Ongoing" || this.showorhide=="DPR") {
            this.dataSourcestage.paginator = this.paginator4;

        
         // this.dataSourcecala.paginator = this.paginator;
        //  this.dataSource.sort = this.sort;
        }
        if (this.showorhide=="Priority 1" || this.showorhide=="Priority 2") {
            this.dataSourcepriority.paginator = this.paginator3;
        }
    }
}