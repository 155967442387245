import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  villageDetail: any;
  cala_name: string;
  individualVillageDetail: any;
  villageID = new BehaviorSubject<any>(null);
  calaID = new BehaviorSubject<any>(null);
  projectID = new BehaviorSubject<any>(null);
  newVillageID = new BehaviorSubject<any>(null);

  serverUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getAllProjectDetails() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/project-details/list`
    );
  }

  getVillageList(pid, cid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village-list/${pid}?cala_id=${cid}`
    );
  }

  getIndividualVillageDetail(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/${id}`
    );
  }

  getRequiredVillageDetails(id) {
    this.getIndividualVillageDetail(id).subscribe((res) => {
      this.individualVillageDetail = res;
      console.log(this.individualVillageDetail);
    });
  }

  postProjectDetails(data) {
    return this.http
      .post<any>(`http://${this.serverUrl}/api/project/project-details`, data)
      .pipe(
        catchError((resData) => {
          let errorMessage = "An unknown error occured";
          console.log(resData);
          return throwError(resData);
        })
      );
  }

  retrieveVillageData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/${id}`
    );
  }

  updateProjectProgress(data, id) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/village/retrieve/${id}`,
      data
    );
  }

  getProjectStats(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/progress/${id}`
    );
  }

  getCalaStats() {
    return this.http.get<any>(`http://${this.serverUrl}/api/project/cala`);
  }

  ///////////////////////// Drop Down service

  getProjectName() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/project-name/list`
    );
  }

  getStates() {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/state/list`);
  }

  getDistricts() {
    return this.http.get<any>(`http://${this.serverUrl}/api/project/district`);
  }

  getTehsils() {
    return this.http.get<any>(`http://${this.serverUrl}/api/project/tehsil`);
  }

  getVillages() {
    return this.http.get<any>(`http://${this.serverUrl}/api/project/village`);
  }

  getAgencies() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/agency/list`
    );
  }

  getPrincipal() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/principal/list`
    );
  }

  getComissioners() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/comissioner/list`
    );
  }

  getDistrictCollectors() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/district-collector/list`
    );
  }
  getCala() {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/cala/list`);
  }

  getExecutiveEngineers() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/executive-engineer/list`
    );
  }

  getRegionalOfficers() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/regional-officer/list`
    );
  }

  getTilr() {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/tilr/list`);
  }

  ////////////////////// Filter Project Service //////////////////////

  getAllProjectList() {
    return this.http.get<any>(`http://${this.serverUrl}/api/project/list`);
  }

  getProjectWiseStates(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/state/list/${id}`
    );
  }

  getStateWiseDistricts(pid, sid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/district/list/${pid}?state=${sid}`
    );
  }

  getDistrictWiseTehsil(pid, did) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/tehsil/list/${pid}?district=${did}`
    );
  }

  getTehsilWiseVillages(pid, tid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/list/${pid}?tehsil=${tid}`
    );
  }

  ////////////////// Table Data Service /////////////////////
  // 3C get DATA

  get3CData(id){
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/3C?village_id=${id}`
    );
  }

  get3AData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/3A?village_id=${id}`
    );
  }
  getJMSData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/jms?village_id=${id}`
    );
  }

  get3DData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/3D?village_id=${id}`
    );
  }
  get3HData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/3H?village_id=${id}`
    );
  }
  get3GData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/3G?village_id=${id}`
    );
  }
  getPossessionData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/possession?village_id=${id}`
    );
  }
  getMutationData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/mutation?village_id=${id}`
    );
  }
  getFundForCalaData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/placing-fund?village_id=${id}`
    );
  }

  getGovtLandPossessionData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/govt/possession?village_id=${id}`
    );
  }

  getGovtLandMutationData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/govt/mutation?village_id=${id}`
    );
  }

  //////////// Post Data Service ////////////////////

  post3CData(data){
    return this.http.post<any>(`http://${this.serverUrl}/api/data/3C`, data);
  }

  post3AData(data) {
    return this.http.post<any>(`http://${this.serverUrl}/api/data/3A`, data);
  }
  postJMSData(data) {
    return this.http.post<any>(`http://${this.serverUrl}/api/data/jms`, data);
  }
  post3DData(data) {
    return this.http.post<any>(`http://${this.serverUrl}/api/data/3D`, data);
  }
  post3GData(data) {
    return this.http.post<any>(`http://${this.serverUrl}/api/data/3G`, data);
  }
  post3HData(data) {
    return this.http.post<any>(`http://${this.serverUrl}/api/data/3H`, data);
  }
  postPossessionata(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/possession`,
      data
    );
  }
  postMutationData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/mutation`,
      data
    );
  }
  postGovtLandPossessionData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/govt/possession`,
      data
    );
  }
  postGovtLandMutationData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/govt/mutation`,
      data
    );
  }

  postFundToCala(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/placing-fund`,
      data
    );
  }

  /////////////////// Put Request /////////////////////////

  updateCompletionStatus(id, data) {
    console.log(data,"data")
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/village/retrieve/${id}`,
      data
    );
  }

  ///////////////// Create Project Dropdown APIs /////////////////////

  getStateList() {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/state/list`);
  }

  getDistrictList(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/district/list/${id}`
    );
  }

  getTehsilList(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/tehsil/list/${id}`
    );
  }

  getVillageListById(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/village/list/${id}`
    );
  }

  //////////////////////// NEW APIs ////////////////////////////////////
  createProject(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/project/project-details`,
      data
    );
  }

  allProjectList() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/project-details/list`
    );
  }

  createStateData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/state/detail`,
      data
    );
  }
  createDistrictData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/district/detail`,
      data
    );
  }
  // Update Districk
  updateDistrictData(data,id) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/project/districtdata/update/${id}`,
      data
    );
  }
  createTehsilData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/tehsil/detail`,
      data
    );
  }
  //update teh
  updateTehsilData(data,id) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/project/tehsildata/update/${id}`,
      data
    );
  }
  createVillageData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/village/detail`,
      data
    );
  }

  getDistrictForState(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/district/list/${id}`
    );
  }

  getTehsilsForDistrict(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/tehsil/list/${id}`
    );
  }

  getVillagesForTehsil(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/village/list/${id}`
    );
  }


  //  Private negotaition Api integration 

  // Actual area acquired

  postPNactualarea(data) {
    console.log(data,"bodydata")
    return this.http.post<any>(`http://${this.serverUrl}/api/data/pn/area-aquired`, data);
  }

  getPNactualarea(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/pn/area-aquired?village_id=${id}`
    );
  }

  retrivePNactualarea(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/pn/area-aquired/retrieve-update/${id}`
    );
  }

  updatePNactualarea(id,data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/pn/area-aquired/retrieve-update/${id}`, data
    );
  }

  deletePNactualarea(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/pn/area-aquired/retrieve-update/${id}`
    );
  }

  // end of Actual area acquired

  // start of fund deposition

  postFundDeposition(data) {
    console.log(data,"data")
    return this.http.post<any>(`http://${this.serverUrl}/api/data/pn/fund-deposition`, data);
  }

  getFundDeposition(id){
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/pn/fund-deposition?village_id=${id}`
    );
  }

  retrivefundDeposite(id){
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/pn/fund-deposition/retrieve-update/${id}`
    );
  }

  updatefundDeposite(id,data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/pn/fund-deposition/retrieve-update/${id}`, data
    );
  }

  deletefundDeposite(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/pn/fund-deposition/retrieve-update/${id}`
    );
  }

  //  end of fund deposition

   // start of fund distribution

   postFundpaid(data) {
    console.log(data,"data")
    return this.http.post<any>(`http://${this.serverUrl}/api/data/pn/fund-distribution`, data);
  }

  getFundpaid(id){
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/pn/fund-distribution?village_id=${id}`
    );
  }

  retrivefundpaid(id){
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/pn/fund-distribution/retrieve-update/${id}`
    );
  }

  updatefundpaid(id,data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/pn/fund-distribution/retrieve-update/${id}`, data
    );
  }

  deletefundpaid(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/pn/fund-distribution/retrieve-update/${id}`
    );
  }


  // end of fund distribution


  // start of total area 

  retriveVilldetails(id){
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/village/retrieve/${id}`
    );
  }




  


}
