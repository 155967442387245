import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdminService } from "../services/admin.service";
import { NotificationService } from "../services/notification.service";

@Component({
  selector: "app-change-deadlines",
  templateUrl: "./change-deadlines.component.html",
  styleUrls: ["./change-deadlines.component.scss"],
})
export class ChangeDeadlinesComponent implements OnInit {
  deadlineForm: FormGroup;
  constructor(
    private _fb: FormBuilder,
    private adminService: AdminService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.deadlineForm = this._fb.group({
      small_3a: [null, Validators.required],
      jms_request: [null, Validators.required],
      jms: [null, Validators.required],
      a_3A: [null, Validators.required],
      d_3D: [null, Validators.required],
      g_3G: [null, Validators.required],
      h_3H: [null, Validators.required],
      possession: [null, Validators.required],
      mutation: [null, Validators.required],
    });
  }

  fetchCurrentDeadlines() {
    this.adminService.getCurrentProjectDeadlines().subscribe((res) => {
      this.deadlineForm.patchValue({
        small_3a: res.small_3a,
        jms_request: res.jms_request,
        jms: res.jms,
        a_3A: res.a_3A,
        d_3D: res.d_3D,
        g_3G: res.g_3G,
        h_3H: res.h_3H,
        possession: res.possession,
        mutation: res.mutation,
      });
    });
  }

  onSubmitDeadlineForm() {
    const data = this.deadlineForm.value;
    this.adminService.updateNewDeadlines(data).subscribe(
      (res) => {
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Deadlines has been updated!"
        );
        this.deadlineForm.reset();
      },
      (err) => {
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.message
        );
      }
    );
  }
}
