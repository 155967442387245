import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Chart } from "chart.js";
import jsPDF from "jspdf";
import { ProjectService } from "../../services/project.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { CalaDetailsData } from "../../shared/data.model";
import { ReportsService } from "../../services/reports.service";

@Component({
  selector: "app-cala-details",
  templateUrl: "./cala-details.component.html",
  styleUrls: ["./cala-details.component.css"],
})
export class CalaDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = [
    "PROJECT NAME",
    "VILLAGES",
    "ON TIME",
    "DELAYED",
    "COMPLETED",
    "EFFICIENCY",
  ];
  public dataSource = new MatTableDataSource<any>();

  name: string;
  cala_details: any[] = [];
  totalProjects: any;

  calaName: string = "";

  calaDetails_id: string;

  calaIDSubs: Subscription;
  calaProjectSubs: Subscription;

  delayCount = 0;
  completedCount = 0;
  ontimeCountt = 0;

  delayedProjectsList = [];
  ontimeProjectsList = [];
  completedProjectsList = [];

  requiredChart: boolean = false;

  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  //////////////// Chart Data ///////////////////////////
  chartLabel: string[] = [];
  chartCompleteData: [] = [];
  chartOntimeData: [] = [];
  chartDelayedData: [] = [];

  /// Chart Options //////
  barOptions_stacked = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
    },
    hover: {
      animationDuration: 0,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            // ticks: { min: 0, max: 5 },
            fontFamily: "'Open Sans Bold', sans-serif",
            fontSize: 11,
          },
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
            color: "#fff",
            zeroLineColor: "#fff",
            zeroLineWidth: 0,
          },
          ticks: {
            // ticks: { min: 0, max: 5 },
            fontFamily: "'Open Sans Bold', sans-serif",
            fontSize: 11,
          },
          stacked: true,
        },
      ],
    },
    legend: {
      display: false,
    },
    pointLabelFontFamily: "Quadon Extra Bold",
    scaleFontFamily: "Quadon Extra Bold",
  };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.calaDetails_id = localStorage.getItem("calaDetails_id");
    this.calaProjectSubs = this.projectService
      .getProjectStats(this.calaDetails_id)
      .subscribe((res) => {
        console.log("OnInit", res);
        this.dataSource.data = res as CalaDetailsData[];
        this.cala_details.push(...res);
        this.sortListAccordingToStatus(this.cala_details);
        this.totalProjects = this.cala_details.length;
        //////////// Chart Data calculations /////////////////
        this.chartDataCalculations(this.cala_details);
        //////////// Total project status calculations /////////////////
        this.countsCalculation(this.cala_details);

        ////////////////// chart logic ///////////////////////
        new Chart("projectStatusChart", {
          type: "horizontalBar",
          data: {
            labels: [...this.chartLabel],
            datasets: [
              {
                data: [...this.chartCompleteData], // completed
                backgroundColor: "rgb(113,211,59,0.4)",
                hoverBackgroundColor: "rgba(113,211,59,0.9)",
              },
              {
                data: [...this.chartOntimeData], // ontime
                backgroundColor: "rgb(53,169,255, 0.4)",
                hoverBackgroundColor: "rgba(53,169,255,0.9)",
              },
              {
                data: [...this.chartDelayedData], // delayed
                backgroundColor: "rgb(255,68,66, 0.4)",
                hoverBackgroundColor: "rgba(255,68,66,0.9)",
              },
            ],
          },

          options: this.barOptions_stacked,
        });
      });
  }

  renderRequiredChart() {
    // this.calaIDSubs = this.projectService.calaID.subscribe((res) => {
    new Chart("requiredProjectStatusChart", {
      type: "horizontalBar",
      data: {
        labels: [...this.chartLabel],
        datasets: [
          {
            data: [...this.chartCompleteData], // completed
            backgroundColor: "rgb(113,211,59,0.4)",
            hoverBackgroundColor: "rgba(113,211,59,0.9)",
          },
          {
            data: [...this.chartOntimeData], // ontime
            backgroundColor: "rgb(53,169,255, 0.4)",
            hoverBackgroundColor: "rgba(53,169,255,0.9)",
          },
          {
            data: [...this.chartDelayedData], // delayed
            backgroundColor: "rgb(255,68,66, 0.4)",
            hoverBackgroundColor: "rgba(255,68,66,0.9)",
          },
        ],
      },
      options: this.barOptions_stacked,
    });
    // });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngAfterContentInit() {
    this.calaName = localStorage.getItem("cala_name");
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  chartDataCalculations(arr) {
    this.chartLabel = [];
    this.chartLabel = arr.map((val) => {
      return val.project_name;
    });
    this.chartCompleteData = [];
    this.chartCompleteData = arr.map((val) => {
      return val.completed;
    });
    this.chartOntimeData = [];
    this.chartOntimeData = arr.map((val) => {
      return val.ontime;
    });
    this.chartDelayedData = [];
    this.chartDelayedData = arr.map((val) => {
      return val.delayed;
    });
    // console.log("Completed", this.chartCompleteData);
    // console.log("OnTime", this.chartOntimeData);
    // console.log("Delayed", this.chartDelayedData);
  }

  countsCalculation(arr) {
    arr.forEach((val) => {
      let el = val;
      if (el.delayed > 0) {
        this.delayCount++;
      } else if (el.delayed == 0 && el.ontime == 0) {
        this.completedCount++;
      } else {
        this.ontimeCountt++;
      }
    });
  }

  sortListAccordingToStatus(arr) {
    arr.forEach((el) => {
      if (el.delayed > 0) {
        this.delayedProjectsList.push(el);
      } else if (el.delayed === 0 && el.ontime === 0 && el.completed > 0) {
        this.completedProjectsList.push(el);
      } else {
        this.ontimeProjectsList.push(el);
      }
    });
    // console.log(this.ontimeProjectsList);
    // console.log(this.delayedProjectsList);
    // console.log(this.completedProjectsList);
  }

  totalList() {
    // console.log(this.cala_details);
    this.dataSource.data = [];
    this.dataSource.data = this.cala_details;
    this.requiredChart = true;
    this.chartDataCalculations(this.cala_details);
    this.renderRequiredChart();
  }

  ontimeList() {
    // console.log(this.ontimeProjectsList);
    this.dataSource.data = [];
    this.dataSource.data = this.ontimeProjectsList;
    this.requiredChart = true;
    this.chartDataCalculations(this.ontimeProjectsList);
    this.renderRequiredChart();
  }

  delayedList() {
    // console.log(this.delayedProjectsList);
    this.dataSource.data = [];
    this.dataSource.data = this.delayedProjectsList;
    this.requiredChart = true;
    this.chartDataCalculations(this.delayedProjectsList);
    this.renderRequiredChart();
  }

  completedList() {
    // console.log(this.completedProjectsList);
    this.dataSource.data = [];
    this.dataSource.data = this.completedProjectsList;
    this.requiredChart = true;
    this.chartDataCalculations(this.completedProjectsList);
    this.renderRequiredChart();
  }

  projectDetails(id, projectName) {
    localStorage.setItem("projectDetails_id", id);
    localStorage.setItem("project_name", projectName);
    this.projectService.projectID.next(id);
    this.router.navigate(["/project-details"]);
  }

  downloadReport() {
    this.reportsService
      .getCalaWiseProjectsReport(this.calaDetails_id)
      .subscribe((res) => {
        this.isDownloading = true;
        console.log(res);
        this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
          this.isDownloading = false;
          this.isDownloaded = res;
          console.log(res);
        });
      });
  }

  ngOnDestroy() {
    // this.calaIDSubs.unsubscribe();
    this.calaProjectSubs.unsubscribe();
  }
}
