import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { NotificationService } from "../../services/notification.service";
import { AdminService } from "../../services/admin.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { emailValidator, mobileNoValidator, officeNoValidator, telephoneNoValidator } from "../../shared/custom-validator/email.validator";
import { ConfirmedValidator } from "../../shared/custom-validator/password.validator";

export interface UsersData {
  first_name: string;
  middle_name: string;
  last_name: string;
  age: number;
  gender: string;
  address: string;
  role: string;
  designation: string;
  email: string;
  telephone: string;
  office_no: string;
  mobile: string;
  password: string;
}

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.css"],
})
export class CreateUserComponent implements OnInit, AfterViewInit, OnDestroy {
  usersList = [];
  emailList = [];
  telePhoneNo = [];
  officeNo = [];
  mobileNo = [];
  showorhideModal=false;
  // dataSource = [];
  displayedColumns: string[] = [
    "name",
    // "role",
    "designation",
    "mobile",
    "edit",
    "update"
    // "delete",
  ];

  public dataSource = new MatTableDataSource<any>();
  userProfile: any;

  editEnable: boolean = false;
  onEdit = true;
  userId: any;
  changePassword: boolean = false

  oldDesignation: string;

  userListSubs: Subscription;
  userProfileSubs: Subscription;
  updateUserSubs: Subscription;
  deleteUserSubs: Subscription;

  refreshUsers$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  userForm: FormGroup;
  passUpdateForm: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private notification: NotificationService
  ) { }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.toLocaleLowerCase();
  };

  ngOnInit(): void {
    this.userListSubs = this.adminService.getAllUsers().subscribe((res) => {
      console.log(res);
      // For email validation
      for (let index = 0; index < res.length; index++) {
        this.emailList.push(res[index].email);


      }
      // console.log(this.emailList,"email list")

      // For Office Number validation
      //  for (let index = 0; index < res.length; index++) {
      //   this.officeNo.push(res[index].office_no);
      //   console.log(this.officeNo,"Office Number list")

      // }
      // For mobile Number validation
      //  for (let index = 0; index < res.length; index++) {
      //   this.mobileNo.push(res[index].mobile);
      //   console.log(this.mobileNo,"mobile Number list")

      // }
      // For mobile Number validation
      // for (let index = 0; index < res.length; index++) {
      //   this.telePhoneNo.push(res[index].mobile);
      //   console.log(this.telePhoneNo,"telephone Number list")

      // }
      this.dataSource.data = res as UsersData[];
      this.usersList.push(...res);
    });

    this.passUpdateForm = this.fb.group({
      newpassword: [null, [Validators.required, Validators.minLength(8)]],
      cnfnewPassword: [null,]
    },
      {
        validator: ConfirmedValidator('newpassword', 'cnfnewPassword')
      }
    )

    this.userForm = this.fb.group({
      first_name: [null, [Validators.required]],
      middle_name: [null],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required, emailValidator(this.emailList)]],
      age: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      office_no: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(12),
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
          // officeNoValidator(this.officeNo)
        ]),
      ],
      mobile: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(12),
          Validators.minLength(10),
          Validators.pattern("^[0-9]*$"),
          // mobileNoValidator(this.mobileNo)
        ]),
      ],
      // telephone: [null,[Validators.required,telephoneNoValidator(this.telePhoneNo)]],
      telephone: [null],
      address: [null, [Validators.required]],
      role: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      password: [null],
    });

    // this.userForm.statusChanges.subscribe((status) => console.log(status));
  }

  get f() {
    return this.passUpdateForm.controls;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // getUsers() {
  //   this.adminService.getAllUsers().then((res) => {
  //     this.usersList = res;
  //   });
  // }



  editUser(id) {
    this.emailList = [];
    this.telePhoneNo = [];
    this.officeNo = [];
    this.mobileNo = [];
    this.userId = id;
    this.userProfileSubs = this.adminService
      .getUserProfile(id)
      .subscribe((res) => {
        this.editEnable = true;
        this.onEdit = false;
        this.userProfile = res;
        this.userForm.reset();
        this.userForm.patchValue({
          first_name: res.first_name,
          middle_name: res.middle_name,
          last_name: res.last_name,
          email: res.email,
          age: res.age,
          gender: res.gender,
          office_no: res.office_no,
          mobile: res.mobile,
          telephone: res.telephone,
          address: res.address,
          role: res.role,
          designation: res.designation,
          // password: res.password,
        });
        this.oldDesignation = res.designation;
        console.log(this.oldDesignation);
      });
    window.scroll(0, 0);
  }

  onUpdateUser() {
    const data = this.userForm.value;
    this.updateUserSubs = this.adminService
      .updateUserProfile(this.userId, data)
      .subscribe(
        (res) => {
          this.userForm.reset();
          console.log(res);
          const newDesiigantion: string = res.designation;
          console.log(newDesiigantion);
          this.onEdit = true;
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Detail posted successfully!!!"
          );
          this.editEnable = !this.editEnable;
          if (this.oldDesignation !== newDesiigantion) {
            console.log("Designation changed");
            const updateUserList = { id: this.userId };
            this.adminService
              .updateUserDesignation(updateUserList)
              .subscribe((res) => {
                console.log(res);
              });
          } else {
            console.log("Designation remains same!");
          }
        },
        (err) => {
          console.error(err.error.message);
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.message
          );
        }
      );
  }

  deleteUser() {
    this.deleteUserSubs = this.adminService.deleteUser(this.userId).subscribe((res) => {
      console.log("User Deleted!!!");
      console.log(res,"User Deleted!!!");
      this.userForm.reset();
      this.editEnable = false;
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "User deleted successfully!!!"
      );
      this.showorhideModal=false
      this.userListSubs = this.adminService.getAllUsers().subscribe((res) => {
        console.log(res,"userlist")
        this.usersList = []; 
        this.dataSource.data = res as UsersData[];
        this.usersList.push(...res);

        this.usersList.push(...res);
      });
    });
  }

  showpopup() {
    this.showorhideModal = true
  }

  hidepopup() {
    this.showorhideModal = false
  }

  onResetForm() {
    this.userForm.reset();
    this.editEnable = false;
  }

  postUserDataToSuitableTable(userData) {
    const designation = userData.designation;
    if (designation === "Principal secretary") {
      const userInfo = {
        principal: userData.userId,
      };
      this.adminService.createPrincipalSec(userInfo).subscribe((res) => {
        console.log(res);
      });
    } else if (designation === "Comissioner") {
      const comm = {
        comissioner: userData.userId,
      };
      this.adminService.createComissioner(comm).subscribe((res) => {
        console.log(res);
      });
    } else if (designation === "District collector") {
      const userInfo = {
        district_collector: userData.userId,
      };
      this.adminService.createDistrictCollector(userInfo).subscribe((res) => {
        console.log(res);
      });
    } else if (designation === "Cala") {
      const userInfo = {
        cala: userData.userId,
      };
      this.adminService.createCALA(userInfo).subscribe((res) => {
        console.log(res);
      });
    } else if (designation === "Executive engineer") {
      const userInfo = {
        executive_engineer: userData.userId,
      };
      this.adminService.createExecutiveEngg(userInfo).subscribe((res) => {
        console.log(res);
      });
    } else if (designation === "Regional officer") {
      const userInfo = {
        regional_officer: userData.userId,
      };
      this.adminService.createRegionalOfficer(userInfo).subscribe((res) => {
        console.log(res);
      });
    } else if (designation === "TILR") {
      const userInfo = {
        tilr: userData.userId,
      };
      this.adminService.createTILR(userInfo).subscribe((res) => {
        console.log(res);
      });
    }
  }

  onSubmitUserForm() {
    const data = this.userForm.value;
    console.log(data,"user form data");
    
    this.adminService.createUser(data).subscribe(
      (res) => {
        console.log(res);
        let userData = {
          designation: res.designation,
          userId: res.id,
        };
        console.log(userData);
        this.postUserDataToSuitableTable(userData);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
      },
      (err) => {
        console.error(err);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          "User with this email address already exists!"
        );
      }
    );
  }

  // on pass update

  editpass(id) {
    this.userId = id;
    this.changePassword = true;
    window.scroll(0, 0);
  }


  onResetpassForm() {
    this.passUpdateForm.reset();
    this.changePassword = false
  }

  onSubmitpassupdate() {

    console.log(this.passUpdateForm.value, "pass update value");
    let data = {
      id: this.userId,
      password: this.passUpdateForm.value.newpassword
    }
    this.adminService.updatepassword(data).subscribe((res) => {
      // console.log(res,'password update res');
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "Password updated successfully!!!"
      );
      this.passUpdateForm.reset();
      this.changePassword = false
    },
      (err) => {
        console.error(err);
      })
  }

  ngOnDestroy() {
    this.userListSubs.unsubscribe();
    // this.userProfileSubs.unsubscribe();
    // this.updateUserSubs.unsubscribe();
    // this.deleteUserSubs.unsubscribe();
  }
}
