import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SecretaryDetailModel } from "../../shared/dashboradData.model";
import { DashboardService } from "../../services/dashboard.service";

declare var require: any;
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-secretary-dashboard',
    templateUrl: 'secretary-dashboard.component.html',
    styleUrls: ['secretary-dashboard.component.css']
})
export class SecretaryComponent implements OnInit, OnDestroy {

    dashboardDataSubs: Subscription;
    dashboardData: any;
    id;
    secretaryName;
    commissionerList;
    date: Date;
    twelveCommissionerList = [];
    arrayOftwelveCommissionerList = [];
    public dataSource = [];
    displayedColumns: string[] = [
        "Commissioner List",
        "Total land to be aquired",
        "Start Date",
        "3a status",
        "JMS request status",
        "JMS",
        "3A",
        "3C",
        "3D",
        "3G",
        "3H",
        "3H funds distributed",
        "Possession",
        "Mutation",
        "Govt. Land To Be Aquired",
        "Govt. Land Possession",
        "Govt. Land Mutation",
        "Private Land To Be acquired",
        "Total Private Land acquired",
        "Total Private fund disbursed",
        "Funds Diposition Under PN",
        "Funds Distribution Under PN",
    ];



    constructor(private dashboardService: DashboardService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {

        this.date = new Date

        this.route.paramMap.subscribe((params) => {
            this.id = params.get("id");
            // this.projectName = params.get("name");
        });

        this.dashboardService.getSecreataryDashboardDataReport(this.id).subscribe(res => {
            console.log(res, "res");
            this.secretaryName = res.name
            this.commissionerList = res.details;

            //  ******* Dynamic generation of table for providing page split********  //
            this.commissionerList = res.details
            while (this.commissionerList.length > 0) {

                this.twelveCommissionerList = this.commissionerList.slice(0, 13);
                this.arrayOftwelveCommissionerList.push(this.twelveCommissionerList);

                this.commissionerList = this.commissionerList.slice(13);

            }


            // this.dataSource.data = res.details as SecretaryDetailModel[];

            //  logic For multiple data in mat table //

            for (let index = 0; index < this.arrayOftwelveCommissionerList.length; index++) {
                this.dataSource.push(new MatTableDataSource<any>());
                this.dataSource[index].data = this.arrayOftwelveCommissionerList[index] as SecretaryDetailModel[];

            }

            // End Of logic For multiple data in mat table //
        })


        this.dashboardDataSubs = this.dashboardService
            .getDashboardData(1)
            .subscribe((res) => {
                this.dashboardData = res;
                console.log(res, "response for dashboard");
            });



        setTimeout(() => {
            this.downloadPageAsPDF();
        }, 5000);
    }


    downloadPageAsPDF() {


        let data = document.getElementById("pagePDF");

        html2canvas(data).then((canvas) => {

            var imgWidth = 295;
            var pageHeight = 208;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            const contentDataURL = canvas.toDataURL("image/png", .01);

            var doc = new jsPDF('l', 'mm', '', true);
            var position = 0;

            doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save("exportedPdf.pdf");

            //   let docWidth = 208;
            //   let docHeight = (canvas.height * docWidth) / canvas.width;
            //   const contentDataURL = canvas.toDataURL("image/png");
            //   let doc = new jsPDF("p", "mm", "a4");
            //   let position = 0;
            //   doc.addImage(contentDataURL, "PNG", 0, position, docWidth, docHeight);
            //   doc.save("exportedPdf.pdf");
        });
    }

    ngOnDestroy(): void {

    }
}