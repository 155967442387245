import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ProjectService } from "../../../services/project.service";
import { NotificationService } from "../../../services/notification.service";
// import { group } from "console";

interface DialogData {
  email: string;
}

@Component({
  selector: "app-modal-govt-land-possession",
  templateUrl: "./govtLandPossession.modal.components.html",
  styleUrls: ["./modal.component.css"],
})
export class ModalGovtLandPossessionComponent implements OnInit {
  govt_land_possession_completion_form: FormGroup;
  id: any;

  villageData: any;
  showWarning: boolean = false;
  remainingLand: any;
  constructor(
    public dialogRef: MatDialogRef<ModalGovtLandPossessionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private notification: NotificationService
  ) {
    // this.id = data["id"];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.projectService.villageID.subscribe((res) => {
      this.id = res;
    });
    this.projectService.retrieveVillageData(this.id).subscribe((res) => {
      this.villageData = res;
      this.remainingLand =
        this.villageData.govt_land_aquire -
        this.villageData.govt_possession_notification;

      console.log(this.remainingLand);

      if (this.remainingLand > 0) {
        this.showWarning = true;
      }
    });
    this.govt_land_possession_completion_form = this.fb.group({
      govt_possession: new FormControl(null, Validators.required),
      govt_possession_remark: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    const data = this.govt_land_possession_completion_form.value;
    this.projectService.updateCompletionStatus(this.id, data).subscribe(
      (res) => {
        console.log(res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );

    this.dialogRef.close();
  }
}
