import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  OnInit,
  DoCheck,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { NotificationService } from "../../services/notification.service";
import { ProjectService } from "../../services/project.service";
import { ProjectNotificationService } from "../../services/project.notification.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ModalComponent } from "./modal/modal.component";
import { Modal3DComponent } from "./modal/modal3D.component";
import { Modal3GComponent } from "./modal/modal3G.component";
import { Modal3HComponent } from "./modal/modal3H.component";
import { ModalCalaFundComponent } from "./modal/CalaFund.modal.component";
import { ModalPossessionComponent } from "./modal/possession.modal.component";
import { ModalMutationComponent } from "./modal/mutation.modal.component";
import { ModalJMSComponent } from "./modal/modal.jms.component";
import { Subscription } from "rxjs";
import { ModalGovtLandPossessionComponent } from "./modal/govtLandPossession.modal.component";
import { ModalGovtLandMutation } from "./modal/govtLandMutation.modal.component";
import { AdminService } from "../../services/admin.service";
import { Modal3CComponent } from "./modal/modal3C.component";
import { LEADING_TRIVIA_CHARS } from "@angular/compiler/src/render3/view/template";
import { encode } from 'base64-arraybuffer';

@Component({
  selector: "app-project-progress",
  templateUrl: "./project-progress.component.html",
  styleUrls: ["./project-progress.component.css"],
})
export class ProjectProgressComponent
  implements OnInit, AfterViewInit, DoCheck
{
  show3C: boolean = true;
  update3C: boolean = false;
  completionMessage3C = false;
  show3A: boolean = true;
  update3A: boolean = false;
  completionMessage3A = false;
  show3D: boolean = true;
  update3D: boolean = false;
  completionMessage3D = false;
  show3H: boolean = true;
  update3H: boolean = false;
  completionMessage3H = false;
  show3G: boolean = true;
  update3G: boolean = false;
  completionMessage3G = false;
  showJMS: boolean = true;
  updateJMS: boolean = false;
  completionMessageJMS = false;
  showPossession: boolean = true;
  updatePossession: boolean = false;
  completionMessagePossession = false;
  showMutation: boolean = true;
  updateMutation: boolean = false;
  completionMessageMutation = false;
  showCalaFund: boolean = true;
  updateCalaFund: boolean = false;
  completionMessageCalaFund = false;
  show3a: boolean = true;
  showJMSRequest: boolean = true;
  showStart: boolean = true;
  showStartForm: boolean = false;
  show3aForm: boolean = false;
  showJMSRequestForm: boolean = false;

  // form hide on the basis of data length////

  ////////// Govt land Specific /////////
  showGovtlandAquisition: boolean = true;
  updateGovtLandAquisition: boolean = false;
  completionMessageGovtLandAquisition: boolean = false;

  showGovtLandPossession: boolean = true;
  updateGovtLandPossession: boolean = false;
  completionMessageGovtLandPossession: boolean = false;

  showGovtLandMutation: boolean = true;
  updateGovtLandMutation: boolean = false;
  completionMessageGovtLandMutation: boolean = false;

  ////// Land acquired by private negotiation ///////

  showPrivatenegotiation: boolean = true;
  updatePrivatenegotiation: boolean = false;
  showPrivatenegotiationForm: boolean = true;

  // CALA Appoinment date /////

  cala_appoinment_date =!null;

  ////// Land acquired by private negotiation ///////
  projectId: any;
  villageid: any;
  oldVillageDetail: any;
  putBody: object[] = [];
  projectData: any;

  ////////////////// Data ID ////////////////////////
  C3DataId: any;
  jmsDataId: any;
  A3DataId: any;
  D3DataId: any;
  G3DataId: any;
  H3DataId: any;
  fundDataId: any;
  possessionDataId: any;
  mutationDataId: any;

  //// Govt Land ////////
  govtPossessionID: any;
  govtMutationID: any;

  ////////////////// Form Groups ////////////////////////
  cala_appoinment: FormGroup;
  start_form: FormGroup;
  form_3a: FormGroup;
  jms_request_form: FormGroup;
  jms: FormGroup;
  form_3C: FormGroup;
  form_3A: FormGroup;
  form_3D: FormGroup;
  form_3H: FormGroup;
  form_3G: FormGroup;
  fund_for_cala_form: FormGroup;
  possession_form: FormGroup;
  mutation_form: FormGroup;

  govt_land_aquisition_form: FormGroup;
  govt_land_possession_form: FormGroup;
  govt_land_mutation_form: FormGroup;
  private_negotiation_form: FormGroup;

  data3A = [];
  data3D = [];
  data3G = [];
  data3H = [];
  jmsData = [];
  fundForCalaData = [];
  possessionData = [];
  mutationData = [];
  data3C = [];

  ////////// Govt Land ////////////
  govtLandPossessionData = [];
  govtLandMutationData = [];

  ////////// Subscription //////////////////
  villageDataSub: Subscription;

  ////////// Project Information ////////////
  projectInfo: any;

  // File Upload

  fileToUpload: File | null = null;
  filecontent;

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private renderer: Renderer2,
    private projectService: ProjectService,
    private notification: NotificationService,
    private projectNotificationService: ProjectNotificationService,
    public dialog: MatDialog,
    private adminservice: AdminService
  ) {}

  ngOnInit(): void {
    ////////// Subscribing to villageID ///////////////
    // this.villageDataSub = this.projectService.villageID.subscribe((res) => {
    //   this.villageid = res;
    //   console.log(this.villageid);
    //   this.updateVillageData(this.villageid);
    // });
    this.projectId = localStorage.getItem("projectID");
    this.villageid = localStorage.getItem("village_id");
    console.log(this.villageid);
    this.updateVillageData(this.villageid);

    ///////////////////// Getting Table Data ////////////////////////
    this.retriveProjectData();
    this.retreiveTableData();

    //////////////////// Form Fields //////////////////////
    this.cala_appoinment = this.fb.group({
      cala_appoinment_date: new FormControl(null, Validators.required),
    });
    this.start_form = this.fb.group({
      start_date: new FormControl(null, Validators.required),
      land_aquire: new FormControl(null, Validators.required),
      start_date_remark: new FormControl(null),
    });

    this.form_3C = this.fb.group({
      c_3C_completed: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required)
    });

    this.form_3a = this.fb.group({
      date_of_3a: new FormControl(null, Validators.required),
      date_of_3a_remark: new FormControl(null),
    });

    this.jms_request_form = this.fb.group({
      date_of_jms_request: new FormControl(null, Validators.required),
      date_of_jms_request_remark: new FormControl(null),
    });

    this.jms = this.fb.group({
      jms_completed: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });

    this.form_3A = this.fb.group({
      date: new FormControl(null, Validators.required),
      a_3A_completed: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });

    this.form_3D = this.fb.group({
      date: new FormControl(null, Validators.required),
      d_3D_completed: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });

    this.form_3G = this.fb.group({
      date: new FormControl(null, Validators.required),
      g_3G_completed: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });

    this.form_3H = this.fb.group({
      date: new FormControl(null, Validators.required),
      h_3H_fund_distributed_for_area: new FormControl(
        null,
        Validators.required
      ),
      h_3H_fund_distributed_in_crore: new FormControl(
        null,
        Validators.required
      ),
      document: new FormControl(null, Validators.required),
    });

    this.fund_for_cala_form = this.fb.group({
      date: new FormControl(null, Validators.required),
      fund_deposition_in_crore: new FormControl(null, Validators.required),
      fund_deposition_for_area: new FormControl(null, Validators.required),
    });

    this.possession_form = this.fb.group({
      date: new FormControl(null, Validators.required),
      possession_for_area: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });

    this.mutation_form = this.fb.group({
      date: new FormControl(null, Validators.required),
      mutation_completed_for_area: new FormControl(null, Validators.required),
      document: new FormControl(null, Validators.required),
    });

    this.private_negotiation_form = this.fb.group({
      private_negotiation: new FormControl(null, Validators.required),
    });

    ///////// Govt Land Aquisition Forms ////////////////////

    this.govt_land_aquisition_form = this.fb.group({
      govt_land_aquire: [null, Validators.required],
    });

    this.govt_land_possession_form = this.fb.group({
      possession_area: [null, Validators.required],
      date: [null, Validators.required],
      document: new FormControl(null, Validators.required),
    });

    this.govt_land_mutation_form = this.fb.group({
      mutation_area: [null, Validators.required],
      date: [null, Validators.required],
      document: new FormControl(null, Validators.required),
    });
  }

  // FileUpload logic

  handleFileInput(files: FileList) {
    this.fileToUpload = files[0];
    console.log(this.fileToUpload,"what is this")
  }

  retreiveTableData() {
    // 3C step addition
    this.projectService.get3CData(this.villageid).subscribe((res) => {
      this.data3C.push(...res);
    });
    this.projectService.get3AData(this.villageid).subscribe((res) => {
      this.data3A.push(...res);
    });
    this.projectService.getJMSData(this.villageid).subscribe((res) => {
      this.jmsData.push(...res);
    });
    this.projectService.get3DData(this.villageid).subscribe((res) => {
      this.data3D.push(...res);
    });
    this.projectService.get3HData(this.villageid).subscribe((res) => {
      this.data3H.push(...res);
    });
    this.projectService.get3GData(this.villageid).subscribe((res) => {
      this.data3G.push(...res);
    });
    this.projectService.getFundForCalaData(this.villageid).subscribe((res) => {
      this.fundForCalaData.push(...res);
    });
    this.projectService.getMutationData(this.villageid).subscribe((res) => {
      this.mutationData.push(...res);
    });
    this.projectService.getPossessionData(this.villageid).subscribe((res) => {
      this.possessionData.push(...res);
    });
    this.projectService
      .getGovtLandPossessionData(this.villageid)
      .subscribe((res) => {
        this.govtLandPossessionData.push(...res);
      });
    this.projectService
      .getGovtLandMutationData(this.villageid)
      .subscribe((res) => {
        this.govtLandMutationData.push(...res);
      });
  }

  updateVillageData(id) {
    this.projectService.retrieveVillageData(id).subscribe((res) => {
      this.oldVillageDetail = res;
      console.log(this.oldVillageDetail, "vill data");
      this.hideForm();
    });
  }

  ngDoCheck() {}

  hideForm() {
    // 3C Part addition
    if (this.oldVillageDetail.dates_of_3C_notification) {
      this.show3C = false;
      this.completionMessage3C = true;
    }
    if (this.oldVillageDetail.date_of_3A_notification) {
      this.show3A = false;
      this.completionMessage3A = true;
    }
    if (this.oldVillageDetail.dates_of_3D_notification) {
      this.show3D = false;
      this.completionMessage3D = true;
    }
    if (this.oldVillageDetail.dates_ofJms_done) {
      this.showJMS = false;
      this.completionMessageJMS = true;
    }
    if (this.oldVillageDetail.dates_of_3G_notification) {
      this.show3G = false;
      this.completionMessage3G = true;
    }
    if (this.oldVillageDetail.h_3H) {
      this.show3H = false;
      this.completionMessage3H = true;
    }
    if (this.oldVillageDetail.dates_possesion_of_land_handling) {
      this.showPossession = false;
      this.completionMessagePossession = true;
    }
    if (this.oldVillageDetail.dates_of_mutation_of_land_record) {
      this.showMutation = false;
      this.completionMessageMutation = true;
    }
    if (this.oldVillageDetail.fund_deposition_complete) {
      this.showCalaFund = false;
      this.completionMessageCalaFund = true;
    }
    // if (this.oldVillageDetail.start_date) {
    //   this.showStart = false;
    // }
    // if (this.oldVillageDetail.date_of_3a) {
    //   this.show3a = false;
    // }
    // if (this.oldVillageDetail.date_of_jms_request) {
    //   this.showJMSRequest = false;
    // }
    if (this.oldVillageDetail.govt_possession) {
      this.showGovtLandPossession = false;
    }
    if (this.oldVillageDetail.govt_mutation) {
      this.showGovtLandMutation = false;
    }
    if (this.oldVillageDetail.private_negotiation == null) {
      this.showPrivatenegotiation = false;
    }
    if (this.oldVillageDetail.private_negotiation != null) {
      this.showPrivatenegotiationForm = false;
      this.showPrivatenegotiation = true;
    }
  }

  ngAfterViewInit() {
    // this.updateVillageData(this.villageid);
  }

  ngAfterContentInit() {
    const projectInformation = localStorage.getItem("project_info");
    this.projectInfo = JSON.parse(projectInformation);
    console.log(this.projectInfo);
  }

  // ///////////////////////CALA appoinment Date////////////////////////////

  retriveProjectData() {
    this.adminservice.getpnDate(this.projectId).subscribe((res) => {
      console.log(res, "project details");
      this.projectData = res;
      this.cala_appoinment_date = res.cala_appointment_date;
    });
  }

  addCalaAppoinment() {
    const formData = {
      ...this.projectData,
      cala_appointment_date: this.cala_appoinment.value.cala_appoinment_date,
    };
    console.log(formData, "form data");

    this.adminservice.updateProjectDetails(this.projectId, formData).subscribe(
      (res) => {
        console.log(res);
        this.cala_appoinment_date = res.cala_appointment_date;
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "CALA appoinment date updated successfully"
        );
      },
      (err) => {
        console.log(err.status);
      }
    );
  }

  ///////////////////////// UPDATE TABLE DATA ///////////////////////////////

  onUpdateJMS() {
    this.showJMS = !this.showJMS;
    this.completionMessageJMS = !this.completionMessageJMS;
  }
  onUpdate3A() {
    this.show3A = !this.show3A;
    this.completionMessage3A = !this.completionMessage3A;
  }
  onUpdate3D() {
    this.show3D = !this.show3D;
    this.completionMessage3D = !this.completionMessage3D;
  }
  onUpdate3G() {
    this.show3G = !this.show3G;
    this.completionMessage3G = !this.completionMessage3G;
  }
  onUpdateFund() {
    this.showCalaFund = !this.showCalaFund;
    this.completionMessageCalaFund = !this.completionMessageCalaFund;
  }
  onUpdate3H() {
    this.show3H = !this.show3H;
    this.completionMessage3H = !this.completionMessage3H;
  }
  onUpdatePossession() {
    this.showPossession = !this.showPossession;
    this.completionMessagePossession = !this.completionMessagePossession;
  }
  onUpdateMutation() {
    this.showMutation = !this.showMutation;
    this.completionMessageMutation = !this.completionMessageMutation;
  }
  onUpdateGovtLandPossession() {
    this.showGovtLandPossession = !this.showGovtLandPossession;
    this.completionMessageGovtLandPossession =
      !this.completionMessageGovtLandPossession;
  }
  onUpdateGovtLandMutation() {
    this.showGovtLandMutation = !this.showGovtLandMutation;
    this.completionMessageGovtLandMutation =
      !this.completionMessageGovtLandMutation;
  }

  /////////////////////////// EDIT LOGIC STARTS ///////////////////////////////
  putDataTransform(formData) {
    const data = formData;
    data.village = this.villageid;
    return data;
  }

  // Editprivatenegotiation

  editPrivatenegotiation() {
    this.showPrivatenegotiationForm = true;
    this.private_negotiation_form.setValue({
      private_negotiation: this.oldVillageDetail.private_negotiation,
    });
  }

  onEdit3C() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("c_3C_completed",this.form_3C.value.c_3C_completed)
    transformedData.append("date",this.form_3C.value.date)
    transformedData.append("document",this.fileToUpload)
    // const body = this.putDataTransform(this.form_3C.value);
    this.projectNotificationService.edit3C(this.C3DataId, transformedData).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService.get3CData(this.villageid).subscribe((res) => {
          this.data3C = [];
          console.log(res);
          this.data3C.push(...res);
        });
        this.update3C = !this.update3C;
        this.form_3C.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }

  onEditJMS() {
    // const body = this.putDataTransform(this.jms.value);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("jms_completed",this.jms.value.jms_completed)
    transformedData.append("date",this.jms.value.date)
    transformedData.append("document",this.fileToUpload)
    this.projectNotificationService.editJMS(this.jmsDataId, transformedData).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService.getJMSData(this.villageid).subscribe((res) => {
          this.jmsData = [];
          console.log(res);
          this.jmsData.push(...res);
        });
        this.updateJMS = !this.updateJMS;
        this.jms.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }
  onEdit3A() {
    // const body = this.putDataTransform(this.form_3A.value);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("a_3A_completed",this.form_3A.value.a_3A_completed)
    transformedData.append("date",this.form_3A.value.date)
    transformedData.append("document",this.fileToUpload)
    this.projectNotificationService.edit3A(this.A3DataId, transformedData).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService.get3AData(this.villageid).subscribe((res) => {
          this.data3A = [];
          this.data3A.push(...res);
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
        this.update3A = !this.update3A;
        this.form_3A.reset();
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }
  onEdit3D() {
    // const body = this.putDataTransform(this.form_3D.value);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("d_3D_completed",this.form_3D.value.d_3D_completed)
    transformedData.append("date",this.form_3D.value.date)
    transformedData.append("document",this.fileToUpload)
    this.projectNotificationService.edit3D(this.D3DataId, transformedData).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService.get3DData(this.villageid).subscribe((res) => {
          this.data3D = [];
          this.data3D.push(...res);
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
        this.update3D = !this.update3D;
        this.form_3D.reset();
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }
  onEdit3G() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("g_3G_completed",this.form_3G.value.g_3G_completed)
    transformedData.append("date",this.form_3G.value.date)
    transformedData.append("document",this.fileToUpload)
    // const body = this.putDataTransform(this.form_3G.value);
    this.projectNotificationService.edit3G(this.G3DataId, transformedData).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService.get3GData(this.villageid).subscribe((res) => {
          this.data3G = [];
          this.data3G.push(...res);
          this.form_3G.reset();
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
        this.update3G = !this.update3G;
        this.form_3G.reset();
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }
  onEdit3H() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("h_3H_fund_distributed_in_crore",this.form_3H.value.h_3H_fund_distributed_in_crore)
    transformedData.append("h_3H_fund_distributed_for_area",this.form_3H.value.h_3H_fund_distributed_for_area)
    transformedData.append("date",this.form_3H.value.date)
    transformedData.append("document",this.fileToUpload)
    // const body = this.putDataTransform(this.form_3H.value);
    this.projectNotificationService.edit3H(this.H3DataId, transformedData).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService.get3HData(this.villageid).subscribe((res) => {
          this.data3H = [];
          this.data3H.push(...res);
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
        this.update3H = !this.update3H;
        this.form_3H.reset();
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }
  onEditFundDeposition() {
    const body = this.putDataTransform(this.fund_for_cala_form.value);
    this.projectNotificationService.editFund(this.fundDataId, body).subscribe(
      (res) => {
        this.updateVillageData(this.villageid);
        this.projectService
          .getFundForCalaData(this.villageid)
          .subscribe((res) => {
            this.fundForCalaData = [];
            this.fundForCalaData.push(...res);
          });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data updated successfully"
        );
        this.updateCalaFund = !this.updateCalaFund;
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }
  onEditPossession() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("possession_for_area",this.possession_form.value.possession_for_area)
    transformedData.append("date",this.possession_form.value.date)
    transformedData.append("document",this.fileToUpload)
    // const body = this.putDataTransform(this.possession_form.value);
    this.projectNotificationService
      .editPossession(this.possessionDataId, transformedData)
      .subscribe(
        (res) => {
          this.updateVillageData(this.villageid);
          this.projectService
            .getPossessionData(this.villageid)
            .subscribe((res) => {
              this.possessionData = [];
              this.possessionData.push(...res);
            });
          console.log(res);
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data updated successfully"
          );
          this.updatePossession = !this.updatePossession;
          this.possession_form.reset();
        },
        (err) => {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.status
          );
        }
      );
  }
  onEditMutation() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("mutation_completed_for_area",this.mutation_form.value.mutation_completed_for_area)
    transformedData.append("date",this.mutation_form.value.date)
    transformedData.append("document",this.fileToUpload)
    // const body = this.putDataTransform(this.mutation_form.value);
    this.projectNotificationService
      .editMutation(this.mutationDataId, transformedData)
      .subscribe(
        (res) => {
          this.updateVillageData(this.villageid);
          this.projectService
            .getMutationData(this.villageid)
            .subscribe((res) => {
              this.mutationData = [];
              this.mutationData.push(...res);
            });
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data updated successfully"
          );
          this.updateMutation = !this.updateMutation;
          this.mutation_form.reset();
        },
        (err) => {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.status
          );
        }
      );
  }
  onEditGovtLandPossession() {
    // const body = this.putDataTransform(this.govt_land_possession_form.value);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("possession_area",this.govt_land_possession_form.value.possession_area)
    transformedData.append("date",this.govt_land_possession_form.value.date)
    transformedData.append("document",this.fileToUpload)
    this.projectNotificationService
      .editGovtLandPossession(this.govtPossessionID, transformedData)
      .subscribe(
        (res) => {
          this.updateVillageData(this.villageid);
          this.projectService
            .getGovtLandPossessionData(this.villageid)
            .subscribe((res) => {
              this.govtLandPossessionData.splice(
                0,
                this.govtLandPossessionData.length
              );
              this.govtLandPossessionData.push(...res);
            });
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data updated successfully"
          );
          this.updateGovtLandPossession = !this.updateGovtLandPossession;
          this.govt_land_possession_form.reset();
        },
        (err) => {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.status
          );
        }
      );
  }
  onEditGovtLandMutation() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("mutation_area",this.govt_land_mutation_form.value.mutation_area)
    transformedData.append("date",this.govt_land_mutation_form.value.date)
    transformedData.append("document",this.fileToUpload)
    // const body = this.putDataTransform(this.govt_land_mutation_form.value);
    this.projectNotificationService
      .editGovtLandMutation(this.govtMutationID, transformedData)
      .subscribe(
        (res) => {
          this.updateVillageData(this.villageid);
          this.projectService
            .getGovtLandMutationData(this.villageid)
            .subscribe((res) => {
              this.govtLandMutationData.splice(
                0,
                this.govtLandMutationData.length
              );
              this.govtLandMutationData.push(...res);
            });
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data updated successfully"
          );
          this.updateGovtLandMutation = !this.updateGovtLandMutation;
          this.govt_land_mutation_form.reset();
        },
        (err) => {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.status
          );
        }
      );
  }
  /////////////////////////// EDIT LOGIC ENDS ///////////////////////////////
  /////////////////////////// EDIT DATA STARTS ///////////////////////////////

  editStartDate() {
    this.showStartForm = true;
    this.start_form.patchValue({
      // console.log(this.oldVillageDetail);
      start_date: this.oldVillageDetail.start_date,
      land_aquire: this.oldVillageDetail.land_aquire,
    });
  }

  edit3aDate() {
    this.show3aForm = true;
    this.jms_request_form.patchValue({
      // console.log(this.oldVillageDetail);
      date_of_3a: this.oldVillageDetail.date_of_3a,
      // land_aquire:this.oldVillageDetail.land_aquire
    });
  }

  editJMSDate() {
    this.showJMSRequestForm = true;
    this.jms_request_form.patchValue({
      // console.log(this.oldVillageDetail);
      date_of_jms_request: this.oldVillageDetail.date_of_jms_request,
      // land_aquire:this.oldVillageDetail.land_aquire
    });
  }

  edit3CData(id) {
    this.C3DataId = id;
    this.update3C = true;
    this.projectNotificationService.retrieve3CData(id).subscribe((res) => {
      console.log(res, "res kya hai");
      this.update3C = true;
      this.form_3C.reset();
      this.form_3C.patchValue({
        c_3C_completed: res.c_3C_completed,
        date: res.date,
      });
    });
  }

  editJMSData(id) {
    this.jmsDataId = id;
    this.updateJMS = true;
    this.projectNotificationService.retrieveJMSData(id).subscribe((res) => {
      console.log(res, "res kya hai");
      this.updateJMS = true;
      this.jms.reset();
      this.jms.patchValue({
        jms_completed: res.jms_completed,
        date: res.date,
      });
    });
  }
  edit3AData(id) {
    this.A3DataId = id;
    this.projectNotificationService.retrieve3AData(id).subscribe((res) => {
      console.log(res);
      this.update3A = true;
      this.form_3A.reset();
      this.form_3A.patchValue({
        a_3A_completed: res.a_3A_completed,
        date: res.date,
      });
    });
  }
  edit3DData(id) {
    this.D3DataId = id;
    this.projectNotificationService.retrieve3DData(id).subscribe((res) => {
      console.log(res);
      this.update3D = true;
      this.update3D = true;
      this.form_3D.reset();
      this.form_3D.patchValue({
        d_3D_completed: res.d_3D_completed,
        date: res.date,
      });
    });
  }
  edit3GData(id) {
    this.G3DataId = id;
    this.projectNotificationService.retrieve3GData(id).subscribe((res) => {
      console.log(res);
      this.update3D = true;
      this.update3G = true;
      this.form_3G.reset();
      this.form_3G.patchValue({
        g_3G_completed: res.g_3G_completed,
        date: res.date,
      });
    });
  }
  editFundData(id) {
    this.fundDataId = id;
    this.projectNotificationService.retrieveFundData(id).subscribe((res) => {
      console.log(res);
      this.updateCalaFund = true;
      this.updateCalaFund = true;
      this.fund_for_cala_form.reset();
      this.fund_for_cala_form.patchValue({
        date: res.date,
        fund_deposition_in_crore: res.fund_deposition_in_crore,
        fund_deposition_for_area: res.fund_deposition_for_area,
      });
    });
  }
  edit3HData(id) {
    this.H3DataId = id;
    this.projectNotificationService.retrieve3HData(id).subscribe((res) => {
      console.log(res);
      this.update3H = true;
      this.update3H = true;
      this.form_3H.reset();
      this.form_3H.patchValue({
        date: res.date,
        h_3H_fund_distributed_for_area: res.h_3H_fund_distributed_for_area,
        h_3H_fund_distributed_in_crore: res.h_3H_fund_distributed_in_crore,
      });
    });
  }
  editPossessionData(id) {
    this.possessionDataId = id;
    this.projectNotificationService
      .retrievePossessionData(id)
      .subscribe((res) => {
        console.log(res);
        this.updatePossession = true;
        this.updatePossession = true;
        this.possession_form.reset();
        this.possession_form.patchValue({
          date: res.date,
          possession_for_area: res.possession_for_area,
        });
      });
  }
  editMutationData(id) {
    this.mutationDataId = id;
    this.projectNotificationService
      .retrieveMutationData(id)
      .subscribe((res) => {
        console.log(res);
        this.updateMutation = true;
        this.updateMutation = true;
        this.mutation_form.reset();
        this.mutation_form.patchValue({
          date: res.date,
          mutation_completed_for_area: res.mutation_completed_for_area,
        });
      });
  }
  editGovtLandPossessionData(id) {
    this.govtPossessionID = id;
    this.projectNotificationService
      .retrieveGovtLandPossessionData(id)
      .subscribe((res) => {
        console.log(res);
        this.updateGovtLandPossession = true;
        this.govt_land_possession_form.reset();
        this.govt_land_possession_form.patchValue({
          date: res.date,
          possession_area: res.possession_area,
        });
      });
  }
  editGovtLandMutationData(id) {
    this.govtMutationID = id;
    console.log(id);
    this.projectNotificationService
      .retrieveGovtLandMutationData(id)
      .subscribe((res) => {
        console.log(res);
        this.updateGovtLandMutation = true;
        this.govt_land_mutation_form.reset();
        this.govt_land_mutation_form.patchValue({
          date: res.date,
          mutation_area: res.mutation_area,
        });
      });
  }
  /////////////////////////// EDIT DATA ENDS ///////////////////////////////
  /////////////////////////// DELETE DATA STARTS ///////////////////////////////
  deleteJMS(id) {
    this.projectNotificationService.deleteJMSData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
      this.projectService.getJMSData(this.villageid).subscribe((res) => {
        this.jmsData = [];
        this.jmsData.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data deleted successfully"
        );
      });
    });
   
  }
  delete3C(id) {
    this.projectNotificationService.delete3CData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
      this.projectService.get3CData(this.villageid).subscribe((res) => {
        this.data3C = [];
        this.data3C.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data deleted successfully"
        );
      });
    });
   
  }
  delete3A(id) {
    this.projectNotificationService.delete3AData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
      this.projectService.get3AData(this.villageid).subscribe((res) => {
        this.data3A = [];
        this.data3A.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data deleted successfully"
        );
      });
    });
   
  }
  delete3D(id) {
    this.projectNotificationService.delete3DData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
    this.projectService.get3DData(this.villageid).subscribe((res) => {
      this.data3D = [];
      this.data3D.push(...res);
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "Data deleted successfully"
      );
    });
    });
    
  }
  delete3G(id) {
    this.projectNotificationService.delete3GData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
      this.projectService.get3GData(this.villageid).subscribe((res) => {
        this.data3G = [];
        this.data3G.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data deleted successfully"
        );
      });
    });
   
  }
  deleteFund(id) {
    this.projectNotificationService.deleteFundData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
    this.projectService.getFundForCalaData(this.villageid).subscribe((res) => {
      this.fundForCalaData = [];
      this.fundForCalaData.push(...res);
      this.notification.showNotification(
        "top",
        "right",
        "success",
        "Data deleted successfully"
      );
    });
    });
    
  }
  delete3H(id) {
    this.projectNotificationService.delete3HData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
      this.projectService.get3HData(this.villageid).subscribe((res) => {
        this.data3H = [];
        this.data3H.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data deleted successfully"
        );
      });
    });
   
  }
  deletePossession(id) {
    this.projectNotificationService
      .deletePossessionData(id)
      .subscribe((res) => {
        console.log("deleted");
        this.updateVillageData(this.villageid);
        this.projectService.getPossessionData(this.villageid).subscribe((res) => {
          this.possessionData = [];
          this.possessionData.push(...res);
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Data deleted successfully"
          );
        });
      });
   
  }
  deleteMutation(id) {
    this.projectNotificationService.deleteMutationData(id).subscribe((res) => {
      console.log("deleted");
      this.updateVillageData(this.villageid);
      this.projectService.getMutationData(this.villageid).subscribe((res) => {
        this.mutationData = [];
        this.mutationData.push(...res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data deleted successfully"
        );
      });
    });
   
  }
  deleteGovtLandPossession(id) {
    this.projectNotificationService
      .deleteGovtLandPossessionData(id)
      .subscribe((res) => {
        console.log("deleted");
        this.updateVillageData(this.villageid);
        this.projectService
          .getGovtLandPossessionData(this.villageid)
          .subscribe((res) => {
            this.govtLandPossessionData = [];
            this.govtLandPossessionData.push(...res);
            this.notification.showNotification(
              "top",
              "right",
              "success",
              "Data deleted successfully"
            );
          });
      });
   
  }
  deleteGovtLandMutation(id) {
    this.projectNotificationService
      .deleteGovtLandMutationData(id)
      .subscribe((res) => {
        console.log("deleted");
        this.updateVillageData(this.villageid);
        this.projectService
          .getGovtLandMutationData(this.villageid)
          .subscribe((res) => {
            this.govtLandMutationData = [];
            this.govtLandMutationData.push(...res);
            this.notification.showNotification(
              "top",
              "right",
              "success",
              "Data deleted successfully"
            );
          });
      });
   
  }
  /////////////////////////// DELETE DATA ENDS ///////////////////////////////

  onSave3A() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("a_3A_completed",this.form_3A.value.a_3A_completed)
    transformedData.append("date",this.form_3A.value.date)
    transformedData.append("document",this.fileToUpload)
    // const transformedData = [];
    // const rawData = this.form_3A.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);

    console.log(transformedData, "3A data");

    this.projectService.post3AData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.get3AData(this.villageid).subscribe((res) => {
          this.data3A = [];
          this.data3A.push(...res);
        });
        this.form_3A.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.form_3A.reset();
  }

  onSave3C() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("c_3C_completed",this.form_3C.value.c_3C_completed)
    transformedData.append("date",this.form_3C.value.date)
    transformedData.append("document",this.fileToUpload)
    // const transformedData = [];
    // const rawData = this.form_3C.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);

    this.projectService.post3CData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.get3CData(this.villageid).subscribe((res) => {
          this.data3C = [];
          console.log(res);
          this.data3C.push(...res);
        });
        this.form_3C.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );
  }

  onSaveJMS() {

    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("jms_completed",this.jms.value.jms_completed)
    transformedData.append("date",this.jms.value.date)
    transformedData.append("document",this.fileToUpload)
    // const rawData = this.jms.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);
    // transformedData[0].document = this.filecontent;

    console.log(transformedData, "data");

    this.projectService.postJMSData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.getJMSData(this.villageid).subscribe((res) => {
          this.jmsData = [];
          console.log(res);
          this.jmsData.push(...res);
        });
        this.jms.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.jms.reset();
  }
  onSave3G() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("g_3G_completed",this.form_3G.value.g_3G_completed)
    transformedData.append("date",this.form_3G.value.date)
    transformedData.append("document",this.fileToUpload)
    // const transformedData = [];
    // const rawData = this.form_3G.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);

    this.projectService.post3GData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.get3GData(this.villageid).subscribe((res) => {
          this.data3G = [];
          this.data3G.push(...res);
        });
        this.form_3G.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.form_3G.reset();
  }
  onSave3D() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("d_3D_completed",this.form_3D.value.d_3D_completed)
    transformedData.append("date",this.form_3D.value.date)
    transformedData.append("document",this.fileToUpload)
    // const transformedData = [];
    // const rawData = this.form_3D.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);

    this.projectService.post3DData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.get3DData(this.villageid).subscribe((res) => {
          this.data3D = [];
          this.data3D.push(...res);
        });
        this.form_3D.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.form_3D.reset();
  }
  onSave3H() {
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("h_3H_fund_distributed_in_crore",this.form_3H.value.h_3H_fund_distributed_in_crore)
    transformedData.append("h_3H_fund_distributed_for_area",this.form_3H.value.h_3H_fund_distributed_for_area)
    transformedData.append("date",this.form_3H.value.date)
    transformedData.append("document",this.fileToUpload)
    // const transformedData = [];
    // const rawData = this.form_3H.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);

    this.projectService.post3HData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.get3HData(this.villageid).subscribe((res) => {
          this.data3H = [];
          this.data3H.push(...res);
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.form_3H.reset();
  }
  onSaveFundForCala() {
    const transformedData = [];
    const rawData = this.fund_for_cala_form.value;
    rawData.village = this.villageid;
    transformedData.push(rawData);

    this.projectService.postFundToCala(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService
          .getFundForCalaData(this.villageid)
          .subscribe((res) => {
            this.fundForCalaData = [];
            this.fundForCalaData.push(...res);
          });
        this.fund_for_cala_form.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.fund_for_cala_form.reset();
  }
  onSavePossession() {
    // const transformedData = [];
    // const rawData = this.possession_form.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("possession_for_area",this.possession_form.value.possession_for_area)
    transformedData.append("date",this.possession_form.value.date)
    transformedData.append("document",this.fileToUpload)

    this.projectService.postPossessionata(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService
          .getPossessionData(this.villageid)
          .subscribe((res) => {
            this.possessionData = [];
            this.possessionData.push(...res);
          });
        this.possession_form.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );

    // this.possession_form.reset();
  }
  onSaveMutation() {
    // const transformedData = [];
    // const rawData = this.mutation_form.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("mutation_completed_for_area",this.mutation_form.value.mutation_completed_for_area)
    transformedData.append("date",this.mutation_form.value.date)
    transformedData.append("document",this.fileToUpload)

    this.projectService.postMutationData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService.getMutationData(this.villageid).subscribe((res) => {
          this.mutationData = [];
          this.mutationData.push(...res);
        });
        this.mutation_form.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );
  }
  onSaveGovtLandPossession() {
    // const transformedData = [];
    // const rawData = this.govt_land_possession_form.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("possession_area",this.govt_land_possession_form.value.possession_area)
    transformedData.append("date",this.govt_land_possession_form.value.date)
    transformedData.append("document",this.fileToUpload)

    this.projectService.postGovtLandPossessionData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService
          .getGovtLandPossessionData(this.villageid)
          .subscribe((res) => {
            this.govtLandPossessionData = [];
            this.govtLandPossessionData.push(...res);
          });
        this.govt_land_possession_form.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );
  }
  onSaveGovtLandMutation() {
    // const transformedData = [];
    // const rawData = this.govt_land_mutation_form.value;
    // rawData.village = this.villageid;
    // transformedData.push(rawData);
    const transformedData = new FormData();
    transformedData.append("village",this.villageid)
    transformedData.append("mutation_area",this.govt_land_mutation_form.value.mutation_area)
    transformedData.append("date",this.govt_land_mutation_form.value.date)
    transformedData.append("document",this.fileToUpload)

    this.projectService.postGovtLandMutationData(transformedData).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.projectService
          .getGovtLandMutationData(this.villageid)
          .subscribe((res) => {
            this.govtLandMutationData = [];
            this.govtLandMutationData.push(...res);
          });
        this.govt_land_mutation_form.reset();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        if (err.status === 406) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.Error
          );
        }
      }
    );
  }

  // Private negotiotation

  onSavePrivateNegotiation() {
    this.onUpdateCompletionStatus(
      this.villageid,
      this.private_negotiation_form.value
    );
  }

  onUpdateCompletionStatus(id, data) {
    this.projectService.updateCompletionStatus(id, data).subscribe(
      (res) => {
        console.log(res);
        this.updateVillageData(this.villageid);
        this.hideForm();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );
  }

  onUpdateStartForm() {
    const data = this.start_form.value;
    console.log(data, "data dtart date");
    this.onUpdateCompletionStatus(this.villageid, data);
    this.start_form.reset();
    this.showStartForm = false;
  }
  onUpdate3aForm() {
    this.show3aForm = false;
    const data = this.form_3a.value;
    this.onUpdateCompletionStatus(this.villageid, data);
    this.form_3a.reset();
  }
  onUpdateJMSForm() {
    this.showJMSRequestForm = false;
    const data = this.jms_request_form.value;
    this.onUpdateCompletionStatus(this.villageid, data);
    this.jms_request_form.reset();
  }
  onUpdateGovtLandAquisitionForm() {
    const data = this.govt_land_aquisition_form.value;
    this.onUpdateCompletionStatus(this.villageid, data);
    this.govt_land_aquisition_form.reset();
  }

  ///////////////// Dialog Boxes /////////////////////////
  open3ADialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  // open 3C dialog boxes
  open3CDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(Modal3CComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  openJMSDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(ModalJMSComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  open3DDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(Modal3DComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  open3GDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(Modal3GComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  open3HDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(Modal3HComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  openCalaFundDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(ModalCalaFundComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  openPossessionDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(ModalPossessionComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  openMutationDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(ModalMutationComponent, {
      width: "500px",
      height: "400px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.email = result;
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  openGovtLandPossessionDialog(): void {
    const dialogRef = this.dialog.open(ModalGovtLandPossessionComponent, {
      width: "500px",
      height: "400px",
      data: { id: this.villageid },
    });

    this.projectService.villageID.next(this.villageid);

    dialogRef.afterClosed().subscribe((result) => {
      // this.email = result;
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }
  openGovtLandMutationDialog(): void {
    this.projectService.villageID.next(this.villageid);
    const dialogRef = this.dialog.open(ModalGovtLandMutation, {
      width: "500px",
      height: "400px",
      data: { id: this.villageid },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // this.email = result;
      console.log(result);
      setTimeout(() => {
        this.updateVillageData(this.villageid);
      }, 1000);
      this.hideForm();
    });
  }

  ngOnDestroy() {
    // this.villageDataSub.unsubscribe();
    localStorage.removeItem("projectID");
    localStorage.removeItem("village_id");
  }
}
