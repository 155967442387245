import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../../../../services/dashboard.service";

@Component({
  selector: "app-project-list-by-ro",
  templateUrl: "./project-list-by-ro.component.html",
  styleUrls: ["./project-list-by-ro.component.scss"],
})
export class ProjectListByRoComponent implements OnInit {
  roID: any;
  projectListbyRo = [];
  projectSelection = [];
  projectIDs = "";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.roID = params.get("id");
    });
    this.dashboardService.getAllProjectListbyRo(this.roID).subscribe((res) => {
      this.projectListbyRo = res;
      this.projectListbyRo = this.projectListbyRo.sort(function (a, b) {
        if (a.project_name.toLowerCase() < b.project_name.toLowerCase()) { return -1; }
        if (a.project_name.toLowerCase() > b.project_name.toLowerCase()) { return 1; }
        return 0;
      })
      
    });
  }

  checked(project) {
    if (this.projectSelection.indexOf(project) != -1) {
      return true;
    }
  }

  onChangeSelection(checked, project) {
    if (checked) {
      this.projectSelection.push(project);
    } else {
      this.projectSelection.splice(this.projectSelection.indexOf(project), 1);
    }
  }
  onProjectSelection() {
    // console.log(this.projectSelection);
    this.projectIDs = JSON.stringify(this.projectSelection)
      .replace("[", "")
      .replace("]", "");
    this.router.navigate(
      ["critical-analysis-report", this.roID],
      { queryParams: { id: this.projectIDs } }
    );
  }
}
