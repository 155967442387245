import { Injectable, OnInit } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class NavbarService implements OnInit {

    
    showOrHide=false;

    constructor(){}

    ngOnInit(): void {
      
    }
}