import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../services/dashboard.service";

@Component({
    selector: 'app-activity',
    templateUrl: 'activity.component.html',
    styleUrls: ['activity.component.css']
})
export class ActivityComponent implements OnInit {

    activities = [];
    pageSize: number = 5;
    pageIndex = 0;
    pageSlice = [];
    p: number = 1;
    url;
    totalcount;

    constructor(private dashboardService: DashboardService,) { }

    ngOnInit(): void {
        this.getActivitylog()
    }


    getActivitylog() {
        this.dashboardService.activityLog(this.p).subscribe((res) => {
            console.log(res, "activity");
            this.totalcount=res.count
            this.url=res.next;
            this.activities = res.results;
            // this.activities.reverse();
            this.pageSlice=this.activities
            for (var index = 0; index < this.activities.length; index++) {
                var str = this.activities[index].activity.split(":")[0];
                var str2 = this.activities[index].activity.split(":")[1];
                this.activities[index]['str'] = str
                this.activities[index]['str2'] = str2

            }
        })
    }

    // getPaginatorData(event) {

    //     console.log(event);
    //     this.pageIndex = event.pageIndex;
    //     this.pageSize = event.pageSize

    //     const startIndex = event.pageIndex * event.pageSize;
    //     let endIndex = startIndex + event.pageSize;
    //     if (endIndex > this.activities.length) {
    //         endIndex = this.activities.length
    //     }
    //     this.pageSlice = this.activities.slice(startIndex, endIndex);
    // }

    onpagechange(event) {
        console.log(event, "page details");
        this.p = event;
        this.dashboardService.activityLog(this.p).subscribe((res) => {
            // console.log(res, "activity");
            this.activities = res.results;
            this.url=res.next;
            // this.activities.reverse();
            this.pageSlice=this.activities
            for (var index = 0; index < this.activities.length; index++) {
                var str = this.activities[index].activity.split(":")[0];
                var str2 = this.activities[index].activity.split(":")[1];
                this.activities[index]['str'] = str
                this.activities[index]['str2'] = str2

            }
        })
       
    }
}