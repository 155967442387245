import { OverlayRef } from "@angular/cdk/overlay";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SpinnerService } from "../../services/spinner.sevice";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnInit {
  loading: boolean;

  constructor(
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef
  ) {
    this.spinnerService.isLoading.subscribe((v) => {
      // console.log(v);
      this.loading = v;
    });
  }

  ngOnInit() {}
}
