import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ProjectService } from "../../../services/project.service";
import { NotificationService } from "../../../services/notification.service";
// import { group } from "console";

interface DialogData {
  email: string;
}

@Component({
  selector: "app-total-land",
  templateUrl: "totalLandacquistion.component.html",
  styleUrls: ["totalLandacquistion.component.css"],
})
export class TotallandAcquistionModelComponent implements OnInit {
  completiontotalLand: FormGroup;
  id: any;

  villageData: any;
  showWarning: boolean = false;
  remainingLand: any;
  remainingfund: any;
  showfundWarning: any;
  constructor(
    public dialogRef: MatDialogRef<TotallandAcquistionModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private notification: NotificationService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this.projectService.villageID.subscribe((res) => {
    //   this.id = res;
    //   console.log(this.id,res,"ye sab kya")
    // });
    this.id=localStorage.getItem("village_id")
    this.projectService.getIndividualVillageDetail(this.id).subscribe((res) => {
      this.villageData = res;
      console.log(this.villageData,"vill data")
      this.remainingLand =
        this.villageData.total_area_aquired_pn - this.villageData.pn_land_aquired;
        this.remainingfund =
        this.villageData.total_compensation_pn - this.villageData.pn_fund_deposition;

      if (this.remainingLand > 0) {
        this.showWarning = true;
      }
      if (this.remainingfund > 0) {
        this.showfundWarning = true;
      }
    });
    this.completiontotalLand = this.fb.group({
      pn_completion_date: new FormControl(null, Validators.required),
      pn_remark: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    const data = this.completiontotalLand.value;
    this.projectService.updateCompletionStatus(this.id, data).subscribe(
      (res) => {
        console.log(res);
        location.reload();
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Data Posted successfully"
        );
      },
      (err) => {
        this.notification.showNotification("top", "right", "error", err.status);
      }
    );

    this.dialogRef.close();
  }
}
