import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../../services/dashboard.service";

@Component({
    selector: 'app-cala-info',
    templateUrl: 'cala-list.component.html',
    styleUrls: ['cala-list.component.css']
})
export class CalainfoListComponent implements OnInit {

    calainfoId;
    url;
    addbtndisable = false
    alldatafetched = false;
    calalists = [];
    showorhide;
    districtListdata = []
    villagelist = [];
    pageSize: number = 20;
    pageIndex = 0;
    pageSlice = [];
    districtSlice;
    villageSlice
    totalcount;
    p: number = 1;
    q: number = 1;
    m: number = 1;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    sortingValue = 'Alphabetical Order'

    constructor(private router: Router, private route: ActivatedRoute, private dashboardservice: DashboardService) { }

    ngOnInit(): void {
        this.showorhide = localStorage.getItem("showOrhide");
        // console.log(this.showorhide)
        this.route.paramMap.subscribe((params) => {
            this.calainfoId = params.get("id");
            // this.projectName = params.get("name");
        });
        if (this.showorhide == "village") {
            this.villageListcount()
        }
        if (this.showorhide == "cala") {
            this.calaList(this.sortingValue)
        }
        if (this.showorhide == "dis") {
            this.districtList()
        }

    }


    calaList(value) {
        this.dashboardservice.calacount(this.calainfoId).subscribe(res => {

        
            if (res.next == null) {
                this.addbtndisable = true;
                this.alldatafetched = true;
            }
            // this.calalists = res.results;
            // this.calalists = res.results;
            // this.totalcount = res.count;
            this.url = res.next
            for (let index = 0; index < res.results.length; index++) {
                this.calalists.push(res.results[index]['details'])

            }
            // console.log("cala list", this.calalists);
            for (let index = 0; index < this.calalists.length; index++) {
                this.calalists[index]['overall_progress'] = parseFloat(this.calalists[index]['overall_progress'])

            }
            // if (value == 'Alphabetical Order') {
            //     this.calalists = this.calalists.sort(function (a, b) {
            //         if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            //         if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            //         return 0;
            //     })
            // }
            // if (value == 'asending Order') {
            //     this.calalists = this.calalists.sort(function (a, b) {
            //         if (a.overall_progress < b.overall_progress) { return -1; }
            //         if (a.overall_progress > b.overall_progress) { return 1; }
            //         return 0;
            //     })
            // }
            // if (value == 'desending Order') {
            //     this.calalists = this.calalists.sort(function (a, b) {
            //         if (a.overall_progress < b.overall_progress) { return 1; }
            //         if (a.overall_progress > b.overall_progress) { return -1; }
            //         return 0;
            //     })
            // }
            // this.pageSlice = this.calalists.slice(0, 20)
            // this.dataSourcecala.data = res as ProjectInfo[];
        })
    }
    addnextdata() {
        this.dashboardservice.calamorecount(this.url).subscribe((res) => {
            this.url = res.next;
            console.log(res,"res")
            if (res.next == null) {
                this.addbtndisable = true;
                this.alldatafetched = true;
            }
            // console.log(res, "response for next 10 data");
            for (let index = 0; index < res.results.length; index++) {
                this.calalists.push(res.results[index]['details'])

            }
            // console.log("cala list", this.calalists);
            for (let index = 0; index < this.calalists.length; index++) {
                this.calalists[index]['overall_progress'] = parseFloat(this.calalists[index]['overall_progress'])

            }


            this.calalists = this.calalists.concat();
            // console.log(this.calalists, "added 10 more");

        })
    }

    // get all project list
    getallCalaList() {


        this.dashboardservice.calamorecount(this.url).subscribe(response => {
            // console.log(response, "response")
            this.url = response.next;
            for (let index = 0; index < response.results.length; index++) {
                this.pageSlice.push(response.results[index]['details'])

            }
            // console.log("cala list", this.calalists);
            for (let index = 0; index < this.pageSlice.length; index++) {
                this.pageSlice[index]['overall_progress'] = parseFloat(this.pageSlice[index]['overall_progress'])

            }
            // console.log("pageslivce list", this.pageSlice);

           
            // console.log("ele data", this.calalists)


            //   this.itemsarray = this.calalists;
            //   console.log("this data for display", this.itemsarray)
            if (response.next != null) {
                this.getallCalaList()
            } else {
                this.calalists = this.calalists.concat(this.pageSlice);
                // console.log("data of middle", this.calalists);
                this.alldatafetched = true
            }



        })
    }


    // onpagechangecala(event){
    //     this.m=event;
    //     this.dashboardservice.calacount(this.calainfoId).subscribe(res => {

    //         // this.calalists = res.results;
    //         // this.calalists = res.results;
    //         for (let index = 0; index < res.results.length; index++) {
    //         this.calalists.push(res.results[index]['details'])

    //         }
    //         console.log("cala list",  this.calalists);
    //         for (let index = 0; index < this.calalists.length; index++) {
    //             this.calalists[index]['overall_progress'] = parseFloat(this.calalists[index]['overall_progress'])

    //         }
    //        if (this.sortingValue=='Alphabetical Order') {
    //         this.calalists = this.calalists.sort(function (a, b) {
    //             if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
    //             if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
    //             return 0;
    //         })
    //        }
    //        if (this.sortingValue=='asending Order') {
    //         this.calalists = this.calalists.sort(function (a, b) {
    //             if (a.overall_progress < b.overall_progress) { return -1; }
    //             if (a.overall_progress > b.overall_progress) { return 1; }
    //             return 0;
    //         })
    //        }
    //        if (this.sortingValue=='desending Order') {
    //         this.calalists = this.calalists.sort(function (a, b) {
    //             if (a.overall_progress < b.overall_progress) { return 1; }
    //             if (a.overall_progress > b.overall_progress) { return -1; }
    //             return 0;
    //         })
    //        }
    //         this.pageSlice = this.calalists.slice(0, 20)
    //         // this.dataSourcecala.data = res as ProjectInfo[];
    //     })

    // }

    districtList() {
        this.dashboardservice.districtCount(this.calainfoId, this.p).subscribe(res => {
            // console.log("dist list", res);
            this.totalcount = res.count
            this.districtListdata = res.results;
            this.districtSlice = this.districtListdata.slice(0, 20)
            // this.dataSourcecala.data = res as ProjectInfo[];
        })
    }
    onpagechange(event) {
        // console.log(event, "page details");
        this.p = event;
        this.dashboardservice.districtCount(this.calainfoId, this.p).subscribe(res => {
            // console.log("dist list", res);
            this.totalcount = res.count
            this.districtListdata = res.results;
            // this.districtSlice = this.districtListdata.slice(0, 20)
            // this.dataSourcecala.data = res as ProjectInfo[];
        })
    }

    villageListcount() {
        this.dashboardservice.villageCount(this.calainfoId, this.q).subscribe(res => {
            // console.log("vill list", res);
            this.totalcount = res.count
            this.villagelist = res.results
            this.villagelist = this.villagelist.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            })

            // this.dataSourcecala.data = res as ProjectInfo[];
            // this.villageSlice = this.villagelist.slice(0, 20)
        })
    }
    onpagechangevill(event) {
        this.q = event;
        this.dashboardservice.villageCount(this.calainfoId, this.q).subscribe(res => {
            // console.log("vill list", res);
            this.totalcount = res.count
            this.villagelist = res.results
            this.villagelist = this.villagelist.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            })

            // this.dataSourcecala.data = res as ProjectInfo[];
            // this.villageSlice = this.villagelist.slice(0, 20)
        })
    }


    getPaginatorData(event) {

        // console.log(event);
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize

        const startIndex = event.pageIndex * event.pageSize;
        let endIndex = startIndex + event.pageSize;
        if (endIndex > this.calalists.length) {
            endIndex = this.calalists.length
        }
        this.pageSlice = this.calalists.slice(startIndex, endIndex);
    }

    getPaginatorDis(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize
        const startIndex = event.pageIndex * event.pageSize;
        let endIndex = startIndex + event.pageSize;
        if (endIndex > this.districtListdata.length) {
            endIndex = this.districtListdata.length
        }
        this.districtSlice = this.districtListdata.slice(startIndex, endIndex);
    }

    getPaginatorvillage(event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize

        const startIndex = event.pageIndex * event.pageSize;
        let endIndex = startIndex + event.pageSize;
        if (endIndex > this.villagelist.length) {
            endIndex = this.villagelist.length
        }
        this.villageSlice = this.villagelist.slice(startIndex, endIndex);
        // console.log(this.villageSlice, "viilage slice")
    }

    caladashboard(id) {
        this.router.navigate(["cala", id]);
    }

    ngAfterViewInit() {

        // this.calalists.paginator = this.paginator;
        // this.dataSourcecala.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
    }

    getAgencyId(value) {
        // console.log(value, "abcd");
        // this.calaList(value);
        if (value == 'Alphabetical Order') {
            this.calalists = this.calalists.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            })
        }
        if (value == 'asending Order') {
            this.calalists = this.calalists.sort(function (a, b) {
                if (a.overall_progress < b.overall_progress) { return -1; }
                if (a.overall_progress > b.overall_progress) { return 1; }
                return 0;
            })
        }
        if (value == 'desending Order') {
            this.calalists = this.calalists.sort(function (a, b) {
                if (a.overall_progress < b.overall_progress) { return 1; }
                if (a.overall_progress > b.overall_progress) { return -1; }
                return 0;
            })
        }

    }
}