import {
  ChangeDetectorRef,
  Component,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import { defer } from "rxjs";

import { NotificationService } from "../../services/notification.service";
import { ProjectService } from "../../services/project.service";
import { SortingService } from "../../services/sorting.service";

@Component({
  selector: "app-create-project",
  templateUrl: "./create-project.component.html",
  styleUrls: ["./create-project.component.css"],
})
export class CreateProjectComponent implements OnInit {
  tehsilObjectArray: object[] = [];
  district_id: string;
  state_id: string;
  tehsil_id: string;
  villageCounter = 0;

  districtIndex: number = 0;
  tehsilIndex: number = 0;
  tehsilData: any;
  villageData: any;

  districtResponse: any;

  districtClicked: boolean = false;
  form: FormGroup;

  projectName: any;
  states: object[] = [];
  districtArray: object[] = [];
  villageArray: object[][][] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];
  tilrs = [];
  TehsilArray: object[] = [];
  agencyArray: any;
  calaArray: any;
  principalArray: any;
  regionalOfficersArray: any;
  comissionersArray: any;
  executiveEngineersArray: any;
  collectorsArray: any;

  formData: object[] = [];
  formTehsilArray: any;
  formDistrictArray: any;
  formVillageArray: any;
  formState: any;
  modifiedFormData: any;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private sortingService: SortingService,
    private notification: NotificationService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      state: new FormControl(null, Validators.required),
      project_name: new FormControl(null, Validators.required),
      agency: new FormControl(null, Validators.required),
      regional_officer: new FormControl(null, Validators.required),
      executive_engineer: new FormControl(null, Validators.required),
      project_ch_from_in_km: new FormControl(null, Validators.required),
      project_ch_to_in_km: new FormControl(null, Validators.required),
      principal: new FormControl(null, Validators.required),
      ////// FormArray Controls //////////////////////
      districtControls: this.fb.array([this.districts()]),
    });

    this.form.get("state").valueChanges.subscribe((res) => {
      this.state_id = res;
      // District list
      this.projectService.getDistrictList(this.state_id).subscribe((res) => {
        this.districtArray.push(...res);
        // this.sortingService.sortDistrict(this.districtArray);
      });
    });

    this.form.get("districtControls").valueChanges.subscribe((res) => {
      this.districtResponse = res;
    });

    this.detectDistrictChange();
    this.detectTehsilChange(this.districtIndex);

    ////////////////// DropDown details //////////////////////
    //1. Project Name

    //2. States
    this.projectService.getStates().subscribe((res) => {
      this.states.push(res);
    });

    //3. Agencies
    this.projectService.getAgencies().subscribe((res) => {
      this.agencyArray = res;
    });

    //5. collectors
    this.projectService.getDistrictCollectors().subscribe((res) => {
      this.collectorsArray = res;
      this.sortingService.sortName(this.collectorsArray);
    });

    //8. Principal Secratories
    this.projectService.getPrincipal().subscribe((res) => {
      this.principalArray = res;
      this.sortingService.sortName(this.principalArray);
    });

    //9. Executive Engineers
    this.projectService.getExecutiveEngineers().subscribe((res) => {
      this.executiveEngineersArray = res;
      this.sortingService.sortName(this.executiveEngineersArray);
    });

    //10. Regional Officers
    this.projectService.getRegionalOfficers().subscribe((res) => {
      this.regionalOfficersArray = res;
      this.sortingService.sortName(this.regionalOfficersArray);
    });

    //11. CALA
    this.projectService.getCala().subscribe((res) => {
      this.calaArray = res;
      this.sortingService.sortName(this.calaArray);
    });

    //12. TILR
    this.projectService.getTilr().subscribe((res) => {
      this.tilrs = res;
      this.sortingService.sortName(this.tilrs);
    });

    //13. Comissoners
    this.projectService.getComissioners().subscribe((res) => {
      this.comissionersArray = res;
      this.sortingService.sortName(this.comissionersArray);
    });

    this.ref.detectChanges();
  }

  detectDistrictChange() {
    this.getDistrictControl(this.districtIndex).valueChanges.subscribe(
      (res) => {
        // console.log("District Index", this.districtIndex);
        // console.log("district ID", res);
        // this.TehsilArray.splice(0, this.TehsilArray.length);
        this.district_id = res;
        this.projectService.getTehsilList(this.district_id).subscribe((res) => {
          this.TehsilArray.push(res);
          // this.sortingService.sortTehsil(this.TehsilArray, this.districtIndex);
        });
      }
    );
  }

  detectTehsilChange(index) {
    this.getTehsilControl(
      this.districtIndex,
      this.tehsilIndex
    ).valueChanges.subscribe((res) => {
      console.log("tehsil ID", res);
      this.projectService.getVillageListById(res).subscribe((resp) => {
        this.villageArray[index].push(resp);
        // this.sortingService.sortVillage(
        //   this.villageArray,
        //   this.districtIndex,
        //   this.tehsilIndex
        // );
      });
    });
  }

  ngDoCheck() {}

  ///////////////////////////////////////////////////

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  getDistrictControl(index) {
    return (
      (this.form.get("districtControls") as FormArray).controls[
        index
      ] as FormGroup
    ).controls.district;
  }

  getTehsilControl(index, tehsilIndex) {
    const control = (
      (
        (<FormArray>this.form.controls["districtControls"])
          .at(index)
          .get("tehsilControls") as FormArray
      ).controls[tehsilIndex] as FormGroup
    ).controls.tehsil;
    return control;
  }

  ///////////////////////////////////////////////////
  districts(): FormGroup {
    return this.fb.group({
      district: ["", [Validators.required]],
      district_collector: ["", [Validators.required]],
      comissioner: ["", [Validators.required]],
      cala: ["", [Validators.required]],
      tehsilControls: this.fb.array([this.tehsils()]),
    });
  }

  distArray(): FormArray {
    return this.form.get("districtControls") as FormArray;
  }

  tehsils() {
    return this.fb.group({
      tehsil: ["", [Validators.required]],
      villageControls: this.fb.array([this.villages()]),
    });
  }

  villages() {
    return this.fb.group({
      village: ["", [Validators.required]],
      tilr: ["", [Validators.required]],
    });
  }

  addDistrict(distIndex) {
    const control = <FormArray>this.form.controls["districtControls"];
    control.push(this.districts());
    this.districtIndex = distIndex + 1;
    console.log(this.districtIndex);
    this.detectDistrictChange();
    this.tehsilIndex = 0;
    this.detectTehsilChange(this.districtIndex);
  }

  removeDistrict(distIndex) {
    this.distArray().removeAt(distIndex);
    if (distIndex <= 0) return;
    this.districtIndex = distIndex - 1;
    this.detectTehsilChange(this.districtIndex);
  }

  addTehsil(distIndex, tehsilIndex) {
    const control = (<FormArray>this.form.controls["districtControls"])
      .at(distIndex)
      .get("tehsilControls") as FormArray;
    control.push(this.tehsils());
    console.log(distIndex, tehsilIndex);
    this.tehsilIndex = tehsilIndex + 1;
    this.detectTehsilChange(this.districtIndex);
    // this.detectDistrictChange();
  }

  tehsilArray(distIndex: number): FormArray {
    return this.distArray().at(distIndex).get("tehsilControls") as FormArray;
  }

  removeTehsil(distIndex, tehsilIndex) {
    this.tehsilArray(distIndex).removeAt(tehsilIndex);
  }

  addVillage(distIndex, tehsilIndex) {
    const control = (
      (<FormArray>this.form.controls["districtControls"])
        .at(distIndex)
        .get("tehsilControls") as FormArray
    )
      .at(tehsilIndex)
      .get("villageControls") as FormArray;
    control.push(this.villages());
  }

  villArray(distIndex, tehsilIndex) {
    const control = (
      (<FormArray>this.form.controls["districtControls"])
        .at(distIndex)
        .get("tehsilControls") as FormArray
    )
      .at(tehsilIndex)
      .get("villageControls") as FormArray;

    return control;
  }

  removeVillage(distIndex, tehsilIndex, villageIndex) {
    this.villArray(distIndex, tehsilIndex).removeAt(villageIndex);
  }

  /////////////////////////////////////////////////////////////////////////
  dataTransformation() {
    console.log(this.districtResponse);
    const districtIDArray = [];
    const teshsilDataArray = [];
    const villageDataArray = [];
    const requiredTeshilData = [];

    this.districtResponse.forEach((el, i) => {
      for (const key in el) {
        if (key === "district") {
          districtIDArray.push(el[key]);
        }
      }
      teshsilDataArray.push(el.tehsilControls);
    });

    ////////// village data structure //////////
    teshsilDataArray.forEach((el, i) => {
      el.forEach((value, i) => {
        value.villageControls.forEach((item) => {
          villageDataArray.push({
            village: item.village,
            tilr: item.tilr,
            tehsil: value.tehsil,
          });
        });
      });
    });
    ////////// village data structure //////////
    teshsilDataArray.forEach((el, i) => {
      el.forEach((value) => {
        requiredTeshilData.push({
          tehsil: value.tehsil,
          district: districtIDArray[i],
        });
      });
    });
    this.tehsilData = requiredTeshilData;
    this.villageData = villageDataArray;
  }
  /////////////////////////////////////////////////////////////////////////

  modifiedData() {
    // console.log(this.form.value);
    this.formData = this.form.value;
    for (const key in this.form.value) {
      if (key === "districtControls") {
        const districtArrayValues = this.form.value[key];
        const districtValues = districtArrayValues.map((val) => {
          return {
            district: val.district,
            comissioner: val.comissioner,
            district_collector: val.district_collector,
            cala: val.cala,
            state: this.state_id,
          };
        });
        this.formDistrictArray = districtValues;
      }

      const statesArray = [];
      statesArray.push({ state: this.form.value["state"] });
      this.formState = statesArray;
    }

    delete this.formData["districtControls"];
    delete this.formData["state"];

    this.formData["state"] = this.formState;
    this.formData["district"] = this.formDistrictArray;
    this.formData["tehsil"] = this.tehsilData;
    this.formData["village"] = this.villageData;
    this.modifiedFormData = this.formData;
    console.log(this.modifiedFormData);
  }

  onSubmit() {
    this.dataTransformation();
    this.modifiedData();
    this.projectService.postProjectDetails(this.modifiedFormData).subscribe(
      (res) => {
        console.log(res);
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully!!!"
        );
        this.router.navigate(["/dashboard"]);
      },
      (err) => {
        console.error(err.error.message);
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
    this.form.reset();
  }
}
