import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import * as Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import { ProjectService } from "../../services/project.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import { DistrictDataModel } from "../../shared/data.model";
import { ProjectNotificationService } from "../../services/project.notification.service";
import { ReportsService } from "../../services/reports.service";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);

@Component({
  selector: "app-project-info",
  templateUrl: "./project-info.component.html",
  styleUrls: ["./project-info.component.css"],
})
export class ProjectInfoComponent implements OnInit {
  displayedColumns: string[] = [
    "District Name",
    "Area to be Acquired",
    "Area acquired so far",
    "Remaining area",
    "Area acquired through private negotiation",
    "3A",
    "3D",
    "3G",
    "3E",
    "Estimated Timeline",
    "entry_date",
  ];
  public dataSource = new MatTableDataSource<any>();

  PROJECT_ID: string;
  totalDistricts: number = 0;
  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private projectNotificationService: ProjectNotificationService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.PROJECT_ID = localStorage.getItem("PROJECT_ID");
    this.projectNotificationService
      .districtInfoUnderEachProject(this.PROJECT_ID)
      .subscribe((res) => {
        console.log(res);
        this.dataSource.data = res as DistrictDataModel[];
        this.totalDistricts = res.length;
        //////////////////// High Charts //////////////////////////
        const options: any = {
          chart: {
            type: "bar",
          },
          title: {
            text: localStorage.getItem("PROJECT_NAME"),
          },
          xAxis: {
            categories: res.map((el) => {
              return el.district_name;
            }),
            title: {
              text: null,
            },
          },
          yAxis: {
            min: 0,
            title: {
              text: "Area (hectares)",
              align: "high",
            },
            labels: {
              overflow: "justify",
            },
          },
          tooltip: {
            valueSuffix: " hectares",
          },
          exporting: {
            enabled: true,
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
              },
            },
          },
          legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "top",
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
            shadow: true,
          },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: "3A Power to acquire",
              data: res.map((el) => {
                return el["A_3A"];
              }),
            },
            {
              name: "3D Declaration of acquisition",
              data: res.map((el) => {
                return el["D_3D"];
              }),
            },
            {
              name: "3G Award",
              data: res.map((el) => {
                return el["G_3G"];
              }),
            },
            {
              name: "3E Power to take possession",
              data: res.map((el) => {
                return el["E_3E"];
              }),
            },
          ],
        };
        HC_exporting(Highcharts);
        Highcharts.chart("container", options);
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  };

  downloadReport() {
    this.reportsService
      .getProjectWiseReport(this.PROJECT_ID)
      .subscribe((res) => {
        this.isDownloading = true;
        this.reportsService.checkDownloadStatus(res.id).subscribe((res) => {
          this.isDownloading = false;
          this.isDownloaded = res;
        });
      });
  }

  ngOnDestroy() {}
}
