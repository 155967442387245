import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ProjectDetailfilterModel } from "../../shared/dashboradData.model";
import { DashboardService } from "../../services/dashboard.service";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ProjectService } from "../../services/project.service";


@Component({
    selector: 'app-project-list',
    templateUrl: 'project-list.component.html',
    styleUrls: ['project-list.component.css']
})
export class ProjectListComponent implements OnInit, AfterViewInit {

    url;
    addbtndisable = false
    alldatafetched=false
    projectsListSubs: Subscription;
    agencyId;
    projectsList$ = [];
    dropdownList = [];
    selectedItems = [];
    districtList = [];
    agencyArray = [];
    displayedColumns: string[] = [
        "project_name",
        "districts",
        "monitoring",
        "priority",
        "stage",
        "progress",
        "jms",
        "A_3A",
        "C_3C",
        "D_3D",
        "G_3G",
        "H_3H_money",
        "H_3H_area",
        "posession",
        "mutation",
        "govt_posession",
        "govt_mutation",
        "progress_percentage"

    ];
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private dashboardService: DashboardService, private route: ActivatedRoute, private router: Router, private projectService: ProjectService,) { }

    ngOnInit(): void {

        //3. Agencies
        this.projectService.getAgencies().subscribe((res) => {
            // console.log(res, "aggenciesks")
            this.agencyArray = res;
        });



        this.route.paramMap.subscribe((params) => {
            this.agencyId = params.get("id");
            // this.projectName = params.get("name");
        });

        // District List

        this.dashboardService.getAllFilterDistrict(this.agencyId).subscribe((res) => {
            this.districtList = res;
            // console.log(res)
        })


        this.projectsListSubs = this.dashboardService
            .getAllFilterProjects(this.agencyId)
            .subscribe((res) => {
                console.log(res)
                this.url = res.next
                this.projectsList$ = res.results;
                // for (let index = 0; index < res.length; index++) {
                //     res[index]['progress_percentage']=parseFloat(res[index]['progress_percentage'])

                // }
                this.dataSource.data = this.projectsList$ as ProjectDetailfilterModel[];
                console.log(this.dataSource.data, "datasource")


            });


    }

    addnextdata() {
        if (this.url == null) {
            //   this.addbtndisable=true
        }
        else {
            this.dashboardService.getmoreFilterProject(this.url).subscribe((res) => {
                this.url = res.next;
                if (res.next == null) {
                    this.addbtndisable = true;
                    this.alldatafetched=true;
                }
                console.log(res, "response for next 10 data");

                this.projectsList$ = this.projectsList$.concat(res.results);
                console.log(this.projectsList$, "added 10 more");

                this.dataSource.data = this.projectsList$

            })
        }
    }

    // get all project list
    getallProjectList() {


        this.dashboardService.getmoreFilterProject(this.url).subscribe(response => {
            // console.log(response, "response")
            this.url = response.next;

            if (this.projectsList$ === undefined) {
                this.projectsList$ = response.results
                // console.log("this.projectsList$ array", this.projectsList$)
            }
            else {
                this.projectsList$ = this.projectsList$.concat(response.results);
                // console.log("ele data", this.projectsList$)
            }

            //   this.itemsarray = this.projectsList$;
            //   console.log("this data for display", this.itemsarray)
            if (response.next != null) {
                this.getallProjectList()
            } else {
                // console.log("data of middle", this.projectsList$);
                this.dataSource.data = this.projectsList$;
                this.alldatafetched=true
            }



        })
    }


    // Dashboard Data according to agency Id
    getAgencyId(id) {
        // console.log(id, "agecyId")
        this.projectsListSubs = this.dashboardService
            .getAllFilterProjects(id)
            .subscribe((res) => {
                console.log(res);
                this.url=res.next;
                if (res.next == null) {
                    this.addbtndisable = true;
                    this.alldatafetched=true;
                }
                else{
                    this.alldatafetched=false;
                    this.addbtndisable = false;
                }
                this.projectsList$ = res.results;
                this.dataSource.data = res.results as ProjectDetailfilterModel[];
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                // console.log(this.projectsList$)
            });

        this.dashboardService.getAllFilterDistrict(id).subscribe((res) => {
            this.districtList = res;
            // console.log(res)
        })

    }

    ngAfterViewInit() {

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public doFilter = (value: string) => {
        this.dataSource.filter = value.toLocaleLowerCase();
    };

    filterDistrict(distName: string) {
        console.log(distName, "distname");
        if (distName == 'Default') {
            location.reload()

        }
        else {

            const filteredResult = this.projectsList$.filter((item) => {
                return (item.districts.indexOf(distName) >= 0);
            });
            this.dataSource = new MatTableDataSource(filteredResult);
            this.dataSource.paginator = this.paginator;
        }

    }

    gotoProjectDashboard(elment) {
        console.log(elment.project_id, "element id")

        this.router.navigate(["project", elment.project_id]);

    }


}