import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../services/notification.service";
import { ProjectNotificationService } from "../../services/project.notification.service";

@Component({
  selector: "app-district-progress",
  templateUrl: "./district-progress.component.html",
  styleUrls: ["./district-progress.component.css"],
})
export class DistrictProgressComponent implements OnInit {
  editingEnabled: boolean = false;

  districtDataID: string;
  projectDetails: any;
  uniqueDistrictId: string;
  remainingLand: number;
  districtInfo = [];

  districtForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private projectNotificationService: ProjectNotificationService
  ) {}

  ngOnInit(): void {
    const details = localStorage.getItem("project_detail");
    this.projectDetails = JSON.parse(details);
    this.districtDataID = localStorage.getItem("districtData_id");
    this.projectNotificationService
      .getDistrictData(this.districtDataID)
      .subscribe((res) => {
        console.log(res);
        this.districtInfo.push(...res);
        this.remainingLand =
          this.districtInfo[0].total_land - this.districtInfo[0].mutation;

        if (this.districtInfo.length > 0) {
          this.editingEnabled = true;
        }
      });

    this.districtForm = this.fb.group({
      dates: ["", Validators.required],
      entry_date: [null, Validators.required],
      total_land: ["", Validators.required],
      mutation: ["", Validators.required],
      land_6a: ["", Validators.required],
      A_3A: ["", Validators.required],
      D_3D: ["", Validators.required],
      G_3G: ["", Validators.required],
      E_3E: ["", Validators.required],
    });
  }

  onSubmit() {
    const formData = this.districtForm.value;
    console.log(formData);
    formData["districtdata"] = +this.districtDataID;
    this.projectNotificationService.postDistrictData(formData).subscribe(
      (res) => {
        this.notification.showNotification(
          "top",
          "right",
          "success",
          "Detail posted successfully👍👍!!!"
        );
        this.districtInfo = [];
        this.districtInfo.push(res);
        this.remainingLand =
          this.districtInfo[0].total_land - this.districtInfo[0].mutation;
        this.editingEnabled = true;
        this.districtForm.reset();
      },
      (err) => {
        this.notification.showNotification(
          "top",
          "right",
          "error",
          "There might be some unexpected error😓"
        );
      }
    );
  }

  editForm(uid) {
    console.log(uid);
    this.uniqueDistrictId = uid;
    this.projectNotificationService
      .retrieveDistrictData(uid)
      .subscribe((res) => {
        this.districtForm.patchValue({
          dates: res.dates,
          total_land: res.total_land,
          land_6a: res.land_6a,
          mutation: res.mutation,
          A_3A: res.A_3A,
          D_3D: res.D_3D,
          G_3G: res.G_3G,
          E_3E: res.E_3E,
          entry_date: res.entry_date,
        });
      });
  }

  onUpdateForm() {
    const formData = this.districtForm.value;
    formData["districtdata"] = this.districtDataID;

    this.projectNotificationService
      .updateDistrictData(this.uniqueDistrictId, formData)
      .subscribe(
        (res) => {
          this.notification.showNotification(
            "top",
            "right",
            "success",
            "Details edited successfully👍👍!!!"
          );
          this.districtInfo = [];
          this.districtInfo.push(res);
          this.districtForm.reset();
        },
        (err) => {
          console.log(err);
          (err) => {
            this.notification.showNotification(
              "top",
              "right",
              "error",
              "There might be some unexpected error😓"
            );
          };
        }
      );
  }
}
