import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdminService } from "../services/admin.service";
import { NotificationService } from "../services/notification.service";
import { SortingService } from "../services/sorting.service";

@Component({
  selector: "app-add-data",
  templateUrl: "./add-data.component.html",
  styleUrls: ["./add-data.component.css"],
})
export class AddDataComponent implements OnInit {
  stateList = [];
  districtListForVillageForm = [];
  districtListForTehsilForm = [];
  tehsilList = [];
  villageList = [];

  stateId: any;
  districtId: any;
  tehsilID: any;

  addVillageForm: FormGroup;
  addTehsilForm: FormGroup;
  addDistrictForm: FormGroup;
  addStateForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private notification: NotificationService,
    private sortingService: SortingService
  ) {}

  // addVillageForm = new FormGroup({
  //   state: new FormControl([null, [Validators.required]]) ,
  //     district: new FormControl([null, [Validators.required]]),
  //     tehsil: new FormControl([null, [Validators.required]]),
  //     village: new FormControl([null, [Validators.required,villnameValidator(["abcd"])]]),
  // })

  ngOnInit(): void {
    this.addVillageForm = this.fb.group({
      state: [1],
      district: [null, [Validators.required]],
      tehsil: [null, [Validators.required]],
      village: [null, [Validators.required]],
    });
    this.addTehsilForm = this.fb.group({
      state: [1],
      district: [null, [Validators.required]],
      tehsil: [null, [Validators.required]],
    });
    this.addDistrictForm = this.fb.group({
      state: [1],
      district: [null, [Validators.required]],
    });
    this.addStateForm = this.fb.group({
      state: [null, [Validators.required]],
    });

    this.adminService.getStateList().subscribe((res) => {
      this.stateList.push(...res);
      this.sortingService.sortState(this.stateList);
    });

   
      this.adminService.getDistrictList(1).subscribe((res) => {
        this.districtListForVillageForm = [];
        this.districtListForVillageForm.push(...res);
        this.sortingService.sortDistrict(this.districtListForVillageForm);
      });

    this.addVillageForm.get("district").valueChanges.subscribe((res) => {
      this.districtId = res;
      console.log(res, "res for getting tehsil");
      this.adminService.getTehsilList(res).subscribe((res) => {
        this.tehsilList = [];
        this.tehsilList.push(...res);
        console.log(this.tehsilList, "ckeck");
        this.sortingService.sortTehsil(this.tehsilList);
      });
    });

    this.addVillageForm.get("tehsil").valueChanges.subscribe((res) => {
      this.districtId = res;
      console.log(res, "res for  village");
      this.adminService.getVillageList(res).subscribe((res) => {
        this.villageList = [];
        this.villageList.push(...res);
        console.log(this.villageList, "village list");
        this.sortingService.sortTehsil(this.tehsilList);
      });
    });

    
      this.adminService.getDistrictList(1).subscribe((res) => {
        this.districtListForTehsilForm = [];
        this.districtListForTehsilForm.push(...res);
        this.sortingService.sortDistrict(this.districtListForTehsilForm);
      });
    
  }

  sortDistrict(array) {
    array.sort(function (a, b) {
      var nameA = a.district.toLowerCase(),
        nameB = b.district.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  sortTehsil(array) {
    array.sort(function (a, b) {
      var nameA = a.tehsil.toLowerCase(),
        nameB = b.tehsil.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
  }

  onSubmitAddVillageForm() {
    const formData = [];
    formData.push(this.addVillageForm.value);
    const data = formData.map((val) => {
      return { village: val.village, tehsil: val.tehsil };
    });
    const [payload] = data;
    this.adminService.createVillage(payload).subscribe(
      (res) => {
        console.log(res, "response");
        this.notification.showNotification(
          "top",
          "right",
          "success",
          `${res.body.village} added to list!!!`
        );
        this.addVillageForm.controls["village"].reset();
      },
      (err) => {
        console.log(err, "error response");
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
  }
  onSubmitAddTehsilForm() {
    const formData = [];
    formData.push(this.addTehsilForm.value);
    const data = formData.map((val) => {
      return { district: val.district, tehsil: val.tehsil };
    });
    const [payload] = data;
    this.adminService.createTehsil(payload).subscribe(
      (res) => {
        this.adminService.getTehsilList(this.districtId).subscribe((res) => {
          this.tehsilList = [];
          this.adminService.getTehsilList(this.districtId).subscribe((res) => {
            this.tehsilList.push(...res);
            this.sortTehsil(this.tehsilList);
          });
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          `${res.tehsil} added to list!!!`
        );
        // this.addTehsilForm.reset();
      },
      (err) => {
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
  }
  onSubmitAddDistrictForm() {
    const data = this.addDistrictForm.value;
    console.log(data)
    this.adminService.createDistrict(data).subscribe(
      (res) => {
        // this.adminService.getDistrictList(this.stateId).subscribe((res) => {
        //   this.districtList = [];
        //   this.districtList.push(...res);
        //   this.sortDistrict(this.districtList);
        // });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          `${res.district} added to list!!!`
        );
        this.addDistrictForm.reset();
      },
      (err) => {
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
  }
  onSubmitAddStateForm() {
    const data = this.addStateForm.value;
    this.adminService.createState(data).subscribe(
      (res) => {
        this.stateList = [];
        this.adminService.getStateList().subscribe((res) => {
          this.stateList.push(...res);
          this.sortingService.sortState(this.stateList);
        });
        this.notification.showNotification(
          "top",
          "right",
          "success",
          `${res.state} added to list!!!`
        );
        this.addStateForm.reset();
      },
      (err) => {
        this.notification.showNotification(
          "top",
          "right",
          "error",
          err.error.message
        );
      }
    );
  }
}
