import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  serverUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getProjectGraphData(pid, cid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/progress/graph/${pid}?cala_id=${cid}`
    );
  }
}
