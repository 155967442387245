import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class RouteGuardGuard implements CanActivate, CanActivateChild {
  userStatus: boolean = false;
  savedStatus: string;
  constructor(private auth: AuthService, private router: Router) {}

  // TO PROTECT SPECIFIC ROUTE
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }

  // TO PROTECT CHILD ROUTES OF A PARENT COMPONENT
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.auth.isUserLoggedIn.subscribe((res) => {
      this.userStatus = res;
    });
    this.savedStatus = localStorage.getItem("access_token");
    if (this.userStatus) {
      return true;
    } else if (this.savedStatus) {
      return true;
    } else {
      this.router.navigate(["/login"]);
      window.alert("You must login first!");
      return false;
    }
  }
}
