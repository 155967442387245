import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProjectNotificationService {
  serverUrl: string = environment.baseUrl;

  calaDetailForFundsManagement = new BehaviorSubject<any>(null);
  calaName: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) {}

  get3AData(id) {
    return this.http.get(
      `http://${this.serverUrl}/api/data/3A?village_id=${id}`
    );
  }
  get3DData(id) {
    return this.http.get(
      `http:/${this.serverUrl}/api/data/3D?village_id=${id}`
    );
  }
  get3HData(id) {
    return this.http.get(
      `http://${this.serverUrl}/api/data/3H?village_id=${id}`
    );
  }
  get3GData(id) {
    return this.http.get(
      `http://${this.serverUrl}/api/data/3G?village_id=${id}`
    );
  }
  getPossessionData(id) {
    return this.http.get(
      `http:/${this.serverUrl}/api/data/possession?village_id=${id}`
    );
  }
  getMutationData(id) {
    return this.http.get(
      `http://${this.serverUrl}/api/data/mutation?village_id=${id}`
    );
  }
  getFundForCalaData(id) {
    return this.http.get(
      `http://${this.serverUrl}/api/data/placing-fund?village_id=${id}`
    );
  }

  //////////// Post Data Service ////////////////////

  post3AData(data) {
    return this.http.post(`http://${this.serverUrl}/api/data/3A`, data);
  }
  post3DData(data) {
    return this.http.post(`http://${this.serverUrl}/api/data/3D`, data);
  }
  post3GData(data) {
    return this.http.post(`http://${this.serverUrl}/api/data/3G`, data);
  }
  post3HData(data) {
    return this.http.post(`http://${this.serverUrl}/api/data/3H`, data);
  }
  postPossessionata(data) {
    return this.http.post(`http://${this.serverUrl}/api/data/possession`, data);
  }
  postMutationData(data) {
    return this.http.post(`http://${this.serverUrl}/api/data/mutation`, data);
  }

  postFundToCalaData(data) {
    return this.http.post(
      `http://${this.serverUrl}/api/data/placing-fund`,
      data
    );
  }

  //////////////// DELETE NOTIFICATION DATA SERVICE ///////////////////////
  deleteJMSData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/jms/get/${id}`
    );
  }
  delete3AData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/3A/get/${id}`
    );
  }
  // DELETE 3C DATA
  delete3CData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/3C/get/${id}`
    );
  }
  delete3DData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/3D/get/${id}`
    );
  }
  delete3GData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/3G/get/${id}`
    );
  }
  deleteFundData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/fund/get/${id}`
    );
  }
  delete3HData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/3H/get/${id}`
    );
  }
  deletePossessionData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/possession/get/${id}`
    );
  }
  deleteMutationData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/mutation/get/${id}`
    );
  }
  deleteGovtLandPossessionData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/govt/possession/update-delete/${id}`
    );
  }
  deleteGovtLandMutationData(id) {
    return this.http.delete<any>(
      `http://${this.serverUrl}/api/data/govt/mutation/update-delete/${id}`
    );
  }

  //////////////// RETRIEVE EDIT NOTIFICATION DATA SERVICE ///////////////////////
  retrieveJMSData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/jms/get/${id}`
    );
  }
  retrieve3AData(id) {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/3A/get/${id}`);
  }
  retrieve3CData(id) {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/3C/get/${id}`);
  }
  retrieve3DData(id) {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/3D/get/${id}`);
  }
  retrieve3GData(id) {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/3G/get/${id}`);
  }
  retrieveFundData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/fund/get/${id}`
    );
  }
  retrieve3HData(id) {
    return this.http.get<any>(`http://${this.serverUrl}/api/data/3H/get/${id}`);
  }
  retrievePossessionData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/possession/get/${id}`
    );
  }
  retrieveMutationData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/mutation/get/${id}`
    );
  }
  retrieveGovtLandPossessionData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/govt/possession/update-delete/${id}`
    );
  }
  retrieveGovtLandMutationData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/govt/mutation/update-delete/${id}`
    );
  }

  //////////////// EDIT NOTIFICATION DATA SERVICE ///////////////////////
  editJMS(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/jms/get/${id}`,
      data
    );
  }
  // edit 3C
  edit3C(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/3C/get/${id}`,
      data
    );
  }
  edit3A(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/3A/get/${id}`,
      data
    );
  }
  edit3D(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/3D/get/${id}`,
      data
    );
  }
  edit3G(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/3G/get/${id}`,
      data
    );
  }
  editFund(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/fund/get/${id}`,
      data
    );
  }
  edit3H(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/3H/get/${id}`,
      data
    );
  }
  editPossession(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/possession/get/${id}`,
      data
    );
  }
  editMutation(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/mutation/get/${id}`,
      data
    );
  }
  editGovtLandPossession(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/govt/possession/update-delete/${id}`,
      data
    );
  }
  editGovtLandMutation(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/govt/mutation/update-delete/${id}`,
      data
    );
  }

  //////////////// CALA FUND MANAGEMENT DETAILS SERVICE ///////////////////////
  getCalaNames() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/cala/money/list`
    );
  }

  getProjectsUnderCala(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/cala/project/list/${id}`
    );
  }

  getCalaFundDetails(cid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/cala/money/details/list/${cid}`
    );
  }

  assginFundsToCala(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/cala/money/details/create`,
      data
    );
  }

  /////////////////// Chart APIs /////////////////////////////////////

  lastTweleveYearData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/details/filter/${id}`
    );
  }

  lastThreeYearData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/progress/quarterly/${id}`
    );
  }

  lastTwelveMonthsData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/village/details/monthly/${id}`
    );
  }

  ////////////////// Project Dashboard APIs ///////////////////////
  districtListByProjectAndState(pid, sid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/new/district/list/${pid}?state=${sid}`
    );
  }

  postDistrictData(data) {
    return this.http.post<any>(
      `http://${this.serverUrl}/api/data/new-district-data/create`,
      data
    );
  }

  getDistrictData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/new-district-data/list/${id}`
    );
  }

  retrieveDistrictData(id) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/data/new-district-data/update/${id}`
    );
  }

  updateDistrictData(id, data) {
    return this.http.put<any>(
      `http://${this.serverUrl}/api/data/new-district-data/update/${id}`,
      data
    );
  }

  allProjectsList() {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/district-wise/project/progress`
    );
  }

  districtInfoUnderEachProject(pid) {
    return this.http.get<any>(
      `http://${this.serverUrl}/api/project/project-wise/district/progress?project_id=${pid}`
    );
  }
}
