import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NotificationService } from "../../services/notification.service";
import { AuthService } from "../../services/auth.service";
import { UserLoginModel } from "../../shared/userLogin.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { Location } from "@angular/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userLoginData: UserLoginModel;
  deviceId: any;

  jwtHelper = new JwtHelperService();
  public isUserLoggedIn: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private notification: NotificationService
  ) {
    this.loginForm = this.fb.group({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
     
    });
  }

  ngOnInit(): void { }

  // fromFlutter(deviceIdFromflutter) {
  //  this.deviceId=deviceIdFromflutter;
  //  localStorage.setItem("device",this.deviceId)
  // }

  onLogin() {
    this.userLoginData = this.loginForm.value;
    // localStorage.setItem("device",this.loginForm.value.deviceId)

    console.log(this.userLoginData,"login form value");
    this.auth.userLogin(this.userLoginData).subscribe(
      (res) => {
        console.log(res);
        if (res) {
          let data ={
            name: "",
            registration_id: localStorage.getItem("device"),
            device_id: "",
            type: "android"
          }
          if (window.innerWidth < 700) {
            this.auth.registerDevice(data).subscribe((res)=>{
              console.log(res,"device registration");
              
            })
          }
          
          const successMessage = "Logged In Successfullly!";
          this.notification.showNotification(
            "top",
            "right",
            "success",
            successMessage
          );
          this.router.navigate(["/dashboard"]);
        } else {
          const errorMessage = "Please enter valid email and password!!!";
          this.notification.showNotification(
            "top",
            "right",
            "error",
            errorMessage
          );
        }
      },
      (err) => {
        console.log(err);
        console.log(err,"error");
        if (err.status==0) {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            "Server Down Please try after sometime or connect with administrator"
          );
        } else {
          this.notification.showNotification(
            "top",
            "right",
            "error",
            err.error.message
          );
        }
        
      
      }
    );
  }
}
